import React from 'react';
import { FastField } from 'formik';
import PropTypes from 'prop-types';

import Input from '../input';

const FormikFastInput = ({ name, ...restProps }) => {
  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <Input
          name={name}
          {...restProps}
          {...field}
          error={meta.touched && meta.error ? meta.error : ''}
        />
      )}
    </FastField>
  );
};

FormikFastInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikFastInput;
