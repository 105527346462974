import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from '../store/sagas/alerts/actions';

const useAlert = () => {
  const dispatch = useDispatch();

  return useCallback(
    (payload = {}) => {
      dispatch(showAlert(payload));
    },
    [dispatch]
  );
};

export default useAlert;
