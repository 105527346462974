import { ASSESSMENT_TYPES } from '../../../constants';
import FormikSelect from '../../formik/FormikSelect';
import React from 'react';
import i18n from '../../../i18n/i18n';

export const IBF = {
  type: 'IBF',
  RMDQ: '',
  ODI: '',
  SF8: '',
  comorbidConditions: [],
  operatedLevels: [],
  primaryDiagnosis: '',
  surgicalApproach: '',
  vasBack: '',
  vasLeftLeg: '',
  vasRightLeg: '',
  notes: [],
  xrays: [],
  images: [],
};

export const THR = {
  type: 'THR',
  DORR: '',
  comorbidConditions: [],
  primaryDiagnosis: '',
  promis10: [],
  surgicalApproach: '',
  assessmentType: '',
  notes: [],
  xrays: [],
  images: [],
};

export const TKR = {
  type: 'TKR',
  comorbidConditions: [],
  primaryDiagnosis: '',
  surgicalApproach: '',
  varus: '',
  varusDegree: '',
  promis10: [],
  assessmentType: '',
  notes: [],
  xrays: [],
  images: [],
};

export const anyProcedure = {
  notes: [],
  xrays: [],
  images: [],
};

export const procedureLabels = {
  comorbidConditions: 'Comorbid Conditions',
  DORR: 'DORR',
  HOOSJR: 'HOOS-JR',
  preOpOHS: 'Pre op Ohs',
  primaryDiagnosis: 'Primary Diagnosis',
  surgicalApproach: 'Surgical Approach',
  KOOSJR: 'KOOS-JR',
  preOpAKSS: 'Pre Op AKSS',
  preOpOKS: 'Pre Op OKS',
  varus: 'Varus',
  varusDegree: 'Varus Degree',
  RMDQ: 'RMDQ',
  ODI: 'ODI',
  SF8: 'SF-8',
  operatedLevels: 'Operated Levels',
  vasBack: 'Vas Back',
  vasLeftLeg: 'Vas Left Leg',
  vasRightLeg: 'Vas Right Leg',
  xrays: 'Radiographs',
};

export const tkrRequiredFields = ['preOpOKS', 'preOpAKSS', 'KOOSJR', 'primaryDiagnosis'];

export const thrRequiredFields = ['preOpOHS', 'HOOSJR', 'primaryDiagnosis'];

export const ibfRequiredFields = ['primaryDiagnosis', 'vasBack', 'vasLeftLeg', 'vasRightLeg', 'ODI', 'RMDQ', 'operatedLevels'];

export const extraParams = {
  images: '',
  notes: '',
  globalPhysicalHealth: '',
  globalMentalHealth: '',
  KOOSJR: 'KOOS-JR',
  preOpAKSS: 'Pre Op AKSS',
  preOpOKS: 'Pre Op OKS',
  HOOSJR: 'HOOS-JR',
  preOpOHS: 'Pre op Ohs',
};

export const patientDataLabels = {
  DOB: 'Date of birthday',
  sex: 'Sex',
  weight: 'Weight',
  height: 'Height',
  // BMI: 'BMI',
};

export const followUpAdverseEventFields = {
  actionTaken: '',
  adverseDescription: '',
  patientHistory: '',
  severity: '',
  relationship: '',
  outcome: '',
  anticipated: '',
};

export const followUp = {
  PSI: {},
  submittedDate: null,
  date: new Date(),
  title: 'Follow Up',
  revision: '',
  adverseEvent: '',
  images: [],
  notes: [],
  xrays: [],
  submitted: false,
  ...followUpAdverseEventFields,
};

export const followUpExtraParams = {
  notes: [],
  images: [],
  promis10: '',
};

export const followUpLabels = {
  actionTaken: 'Action Taking',
  adverseDescription: 'Description',
  patientHistory: 'Patient History',
  severity: 'Severity',
  relationship: 'Relationship to Signature Device',
  xrays: 'Radiographs',
  outcome: 'Outcome',
  anticipated: 'Anticipated',
  PSI: {
    IBF: {
      ODI: 'ODI',
      RMDQ: 'RMDQ',
      VASLeftArm: 'VAS Left Arm',
      VASNeck: 'VAS Neck',
      VASRightArm: 'VAS Right Arm',
      VASLeftLeg: 'VAS Left Leg',
      VASRightLeg: 'VAS Right Leg',
      VASBack: 'VAS Back',
      boneCondition: 'Bone Condition',
      componentMigration: 'Component Migration',
      confluenceOfBridgingBone: 'Confluence Of Bridging Bone',
      movementOnLateralFilm: 'Movement On Lateral Film',
      surgeonOutcomeAssessment: 'Surgeon Outcome Assessment',
    },
    TKR: {
      assessmentType: 'Assessment Type',
      KOOSJR: 'KOOS-JR',
      preOpOKS: 'Pre Op OKS',
      preOpAKSS: 'Pre Op AKSS',
      VASScore: 'VAS Score',
      alignment: 'Varus/Valgus Alignment',
      boneCondition: 'Bone Condition',
      femoralMigration: 'Femoral Migration',
      radiolucencies: 'Radiolucencies',
      radiolucencyProgression: 'Radiolucency Progression',
      tibialMigration: 'Tibial Migration',
    },
    THR: {
      assessmentType: 'Assessment Type',
      HOOSJR: 'HOOS-JR',
      preOpOHS: 'Oxford Hip Score',
      VASScore: 'VAS Score',
      boneCondition: 'Bone Condition',
      cupAlignment: 'Cup Alignment',
      cupMigration: 'Cup Migration',
      radiolucencies: 'Radiolucencies',
      radiolucencyProgression: 'Radiolucency Progression',
      stemMigration: 'Stem Migration',
    },
  },
};

export const tkrRequiredFollowUpFields = ['preOpOKS', 'preOpAKSS', 'KOOSJR'];

export const thrRequiredFollowUpFields = ['preOpOHS', 'HOOSJR'];

export const ibfRequiredFollowUpFields = ['operatedLevels', 'ODI', 'RMDQ', 'surgeonOutcomeAssessment', 'movementOnLateralFilm', 'confluenceOfBridgingBone'];
export const ibfCervicalRequiredFollowUpFields = ['operatedLevels', 'ODI', 'RMDQ', 'surgeonOutcomeAssessment', 'movementOnLateralFilm', 'confluenceOfBridgingBone', 'VASNeck', 'VASRightArm', 'VASLeftArm',];
export const ibfThoracolumbarRequiredFollowUpFields = ['operatedLevels', 'ODI', 'RMDQ', 'surgeonOutcomeAssessment', 'movementOnLateralFilm', 'confluenceOfBridgingBone', 'VASBack', 'VASRightLeg', 'VASLeftLeg'];

export const PSI = {
  IBF: {
    ODI: '',
    RMDQ: '',
    VASLeftArm: '',
    VASNeck: '',
    VASRightArm: '',
    VASLeftLeg: '',
    VASBack: '',
    VASRightLeg: '',
    boneCondition: '',
    componentMigration: '',
    confluenceOfBridgingBone: '',
    movementOnLateralFilm: '',
    promis10: [],
    surgeonOutcomeAssessment: '',
  },
  TKR: {
    VASScore: '',
    alignment: '',
    boneCondition: '',
    femoralMigration: '',
    promis10: [],
    radiolucencies: '',
    radiolucencyProgression: '',
    tibialMigration: '',
  },
  THR: {
    VASScore: '',
    boneCondition: '',
    cupAlignment: '',
    cupMigration: '',
    promis10: [],
    radiolucencies: '',
    radiolucencyProgression: '',
    stemMigration: '',
  },
};

export const sides = {
  RIGHT: 'Right',
  LEFT: 'Left',
  'Bi-lateral': 'Bi-Lateral',
  NA: 'N/A',
};

export const calculatePromis = (points) => {
  const getPainRate = (value) => {
    const x = value;
    switch (true) {
      case x === 0:
        return 0;
      case x <= 3:
        return 1;
      case x <= 6:
        return 2;
      case x <= 9:
        return 3;
      case x <= 10:
        return 4;
      default:
        return 0;
    }
  };

  if (!points || !points.length) {
    return [];
  }
  const scores = [20, 15, 10, 5, 0];
  const mentalValues = [points[1], points[3], points[4], points[7]];
  const physicalValues = [points[2], points[5], points[9], getPainRate(points[6])];

  const calcScore = (resultArr) => {
    return resultArr.reduce((sum, value) => sum + scores[value], 20);
  };

  return [
    {
      label: i18n.t('caseInfo.opData.health.physicalHealth'),
      value: calcScore([...physicalValues]),
    },
    { label: i18n.t('caseInfo.opData.health.mentalHealth'), value: calcScore([...mentalValues]) },
  ];
};

export const getPreOpDataAssessmentType = (preOpData) => {
  if ('preOpAKSS' in preOpData) {
    return ASSESSMENT_TYPES.AKSS;
  }
  if ('preOpOHS' in preOpData) {
    return ASSESSMENT_TYPES.PREOPOHS;
  }
  if ('preOpOKS' in preOpData) {
    return ASSESSMENT_TYPES.PREOPOKS;
  }
  if ('HOOSJR' in preOpData) {
    return ASSESSMENT_TYPES.HOOSJR;
  }
  if ('KOOSJR' in preOpData) {
    return ASSESSMENT_TYPES.KOOSJR;
  }
};

export const getAssessmentTypeLabel = (assessmentType) => {
  switch (assessmentType) {
    case 'HOOS-JR':
      return ASSESSMENT_TYPES.HOOSJR;
    case 'KOOS-JR':
      return ASSESSMENT_TYPES.KOOSJR;
    case 'HOOSJR':
      return ASSESSMENT_TYPES.HOOSJR;
    case 'KOOSJR':
      return ASSESSMENT_TYPES.KOOSJR;
    case 'pre_op_ohs':
      return ASSESSMENT_TYPES.PREOPOHS;
    case 'pre_op_oks':
      return ASSESSMENT_TYPES.OKS;
    case 'pre_op_akss':
      return ASSESSMENT_TYPES.AKSS;
  }
};
