import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as OpDataSubmitted } from '../../../assets/Pre_Op_Surgeon.svg';
import { ReactComponent as CaseUsageSubmitted } from '../../../assets/Submitted_Done.svg';
import { Typography } from '@material-ui/core';
import Button from '../../button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 600,
    padding: 24,
    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
    [theme.breakpoints.down(600)]: {
      width: '100vw',
      height: '100%',
      justifyContent: 'center',
    },
  },
  title: {
    fontWeight: 700,
  },
  submitTitle: {
    textAlign: 'center',
    fontWeight: 600,
  },
}));

const DialogContent = ({ type, handleClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h3' color='primary' className={classes.title}>
        Thank you!
      </Typography>
      <Typography className={classes.submitTitle}>
        {type === 'CASE_USAGE'
          ? 'All provided information has been submitted'
          : 'All provided clinical data has been submitted'}{' '}
        <br /> to the Signature team.
      </Typography>
      <Button onClick={handleClose}>Done</Button>
      {type === 'CASE_USAGE' ? <CaseUsageSubmitted /> : <OpDataSubmitted />}
    </div>
  );
};

export default memo(DialogContent);
