import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import {
  ActionButton,
  StyledDialog,
  CloseButton,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogActions,
  CancelButton,
  StyledTitle,
  StyledCircularProgress,
} from './style';
import { useTranslation } from 'react-i18next';

const Modal = (props) => {
  const {
    open,
    onClose,
    onCancel,
    submitTitle,
    onSubmit,
    maxWidth = 'md',
    hasDialogActions = true,
    isSubmitting = false,
    dialogTitleProps = {},
    dialogContentProps = {},
    dialogActionProps = {},
    children,
    DialogActionsComponent,
    title,
    DialogContent = null,
    isLoading,
    ...restProps
  } = props;
  const { t } = useTranslation();
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={maxWidth} {...restProps}>
      {DialogContent || (
        <>
          <StyledDialogTitle disableTypography {...dialogTitleProps}>
            <StyledTitle variant='h6'>{title}</StyledTitle>
            <CloseButton size='small' onMouseDown={onClose}>
              <CloseIcon color='secondary' />
            </CloseButton>
          </StyledDialogTitle>
          <StyledDialogContent {...dialogContentProps}>{children}</StyledDialogContent>
          {hasDialogActions &&
            (DialogActionsComponent || (
              <StyledDialogActions {...dialogActionProps}>
                <CancelButton onClick={onClose}>{t('buttons.cancel')}</CancelButton>
                <ActionButton
                  startIcon={isLoading && <StyledCircularProgress size={24} />}
                  type='submit'
                  disabled={isSubmitting || isLoading}
                  onClick={onSubmit}
                >
                  {submitTitle || t('buttons.save')}
                </ActionButton>
              </StyledDialogActions>
            ))}
        </>
      )}
    </StyledDialog>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  submitTitle: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  hasDialogActions: PropTypes.bool,
  DialogActionsComponent: PropTypes.any,
  dialogTitleProps: PropTypes.element,
  dialogContentProps: PropTypes.element,
  dialogActionProps: PropTypes.element,
};

export default Modal;
