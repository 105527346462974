import React, { memo, useMemo } from 'react';

import { ButtonRow, FormColumn } from '../../style';
import FormikDatePicker from '../../../../formik/FormikDatePicker';
import FormikFastInput from '../../../../formik/FormikFastInput';
import { Button, makeStyles } from '@material-ui/core';
import { connect, FastField } from 'formik';
import FormikRadioGroup from '../../../../formik/FormikRadioGroup';

import BmiCalculate from '../BMICalculate';
import useUserCountry from '../../../../../hooks/useUserCountry';
import { useTranslation } from 'react-i18next';
import { MEASUREMENT_TYPE } from '../../../../../constants';
import FormikInput from '../../../../formik/FormikInput';

const useStyles = makeStyles((theme) => ({
  inputRow: {
    display: 'flex',
    alignItems: 'flex-end',
    '&>*': {
      marginRight: theme.spacing(1),
    },
  },
}));

const PatientDataStep = ({
  handleNextStep,
  handleBackStep,
  country = '',
  formik: {
    values: { patientData },
    setFieldValue,
  },
}) => {
  const classes = useStyles();
  const userCountry = useUserCountry();
  const caseCountry = country || userCountry;
  const { t } = useTranslation();
  const sexVariants = useMemo(
    () => [
      {
        label: t('addCase.steps.patientData.sexVariants.male'),
        value: 'MALE',
      },
      {
        label: t('addCase.steps.patientData.sexVariants.female'),
        value: 'FEMALE',
      },
    ],
    [t]
  );

  const measurementType = useMemo(
    () => [
      {
        label: 'Metric',
        value: 'METRIC',
      },
      {
        label: 'Imperial',
        value: 'IMPERIAL',
      },
    ],
    [t]
  );

  const smokingVariants = useMemo(
    () => [
      {
        label: t('addCase.steps.patientData.smokingVariants.yes'),
        value: 'yes',
      },
      {
        label: t('addCase.steps.patientData.smokingVariants.no'),
        value: 'no',
      },
    ],
    [t]
  );

  const onChangeMeasurementType = (event) => {
    setFieldValue('patientData.measurementType', event.target.value);
    setFieldValue('patientData.BMI', '');
    setFieldValue('patientData.weight', '');
    setFieldValue('patientData.height', '');
  };

  return (
    <FormColumn>
      <FormikDatePicker
        placeholder={t('addCase.steps.patientData.DOBPlaceholder')}
        clearIcon={false}
        label={t('addCase.steps.patientData.DOB')}
        name='patientData.DOB'
        type='keyboard'
        defaultDate={new Date('1990-01-01')}
        controlled
      />
      <FastField
        label={t('addCase.steps.patientData.sex')}
        component={FormikRadioGroup}
        name='patientData.sex'
        options={sexVariants}
        row
      />
      <FastField
        label={t('addCase.steps.patientData.unitOfMeasure')}
        component={({ field, ...restProps }) => {
          return (
            <FormikRadioGroup
              {...restProps}
              field={{ ...field, onChange: onChangeMeasurementType }}
            />
          );
        }}
        name='patientData.measurementType'
        options={measurementType}
        row
      />
      {patientData.measurementType === MEASUREMENT_TYPE.IMPERIAL ? (
        <>
          <FormikInput
            label={t('addCase.steps.patientData.weightUS')}
            type='number'
            placeholder={t('addCase.steps.patientData.enterWeight')}
            name='patientData.weight'
          />
          <div className={classes.inputRow}>
            <FormikInput
              label={t('addCase.steps.patientData.heightUS')}
              type='number'
              placeholder='ft'
              name='patientData.feet'
            />
            <FormikInput type='number' placeholder='in' name='patientData.inches' />
          </div>
        </>
      ) : (
        <>
          <FormikInput
            label={t('addCase.steps.patientData.weight')}
            type='number'
            placeholder={t('addCase.steps.patientData.enterWeight')}
            name='patientData.weight'
          />
          <FormikInput
            label={t('addCase.steps.patientData.height')}
            type='number'
            placeholder={t('addCase.steps.patientData.enterHeight')}
            name='patientData.height'
          />
        </>
      )}
      <div className={classes.inputRow}>
        <FormikInput
          label={t('addCase.steps.patientData.BMILabel')}
          type='number'
          placeholder={t('addCase.steps.patientData.enterBMI')}
          name='patientData.BMI'
        />
        <BmiCalculate name={'patientData.BMI'} country={caseCountry} />
      </div>
      <FastField
        label={t('addCase.steps.patientData.doesSmoke')}
        component={FormikRadioGroup}
        name='patientData.smoking'
        options={smokingVariants}
        row
      />
      {handleNextStep && (
        <ButtonRow>
          <Button fullWidth onClick={handleBackStep} variant='outlined'>
            {t('addCase.steps.patientData.back')}
          </Button>
          <Button onClick={handleNextStep} fullWidth variant='contained' color='primary'>
            {t('addCase.steps.patientData.addPreOpData')}
          </Button>
        </ButtonRow>
      )}
    </FormColumn>
  );
};

export default memo(connect(PatientDataStep));
