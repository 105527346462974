import React, { memo } from 'react';

import AddCaseStepper from './AddCaseStepper';

import { AddCaseFormContainer, CaseFormContainer, FormContainer } from '../style';

const AddCaseForm = () => {
  return (
    <AddCaseFormContainer>
      <CaseFormContainer>
        <FormContainer>
          <AddCaseStepper />
        </FormContainer>
      </CaseFormContainer>
    </AddCaseFormContainer>
  );
};

export default memo(AddCaseForm);
