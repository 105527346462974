import styled from 'styled-components';

export const AddCaseFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const CaseFormContainer = styled.div`
  display: flex;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 16px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  & > *:last-child {
    margin-left: ${({ noPadding }) => (noPadding ? 0 : 16)}px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & > *:last-child {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;
