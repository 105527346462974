import React, { memo, useState, useMemo } from 'react';

import Modal from '../../modal-wrapper/Modal';
import ProcedureShared from '../../procedure-shared/ProcedureShared';

import { makeStyles } from '@material-ui/core';
import { connect } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  description: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  button: {
    height: 50,
    marginLeft: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
  intervalScore: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  scoreLabel: {
    fontSize: 20,
    fontWeight: 700,
    color: '#01a157',
  },
  score: {
    fontSize: 40,
    fontWeight: 800,
    color: '#01a157',
  },
  textContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const KoosJRModal = ({ isFollowUp = false, open, onClose, name, formik: { setFieldValue } }) => {
  const [points, setPoints] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [score, setScore] = useState(100);
  const scores = useMemo(
    () => [
      100.0, 91.975, 84.6, 79.914, 76.332, 73.342, 70.704, 68.284, 65.994, 63.776, 61.583, 59.381,
      57.14, 54.84, 52.465, 50.012, 47.487, 44.905, 42.281, 39.625, 36.931, 34.174, 31.307, 28.251, 24.875,
      20.941, 15.939, 8.291, 0
    ],
    []
  );

  const changeScore = (index, value) => {
    const newPoints = [...points];
    newPoints[index] = value;
    const sum = newPoints.reduce((sum, value) => sum + value, 0);
    setPoints([...newPoints]);
    setScore(scores[sum]);
  };

  const onSubmit = () => {
    setFieldValue(name, score);
    isFollowUp
      ? setFieldValue('followUp.PSI.koosJRScore', points)
      : setFieldValue('preOpData.koosJRScore', points);
    onClose();
  };

  const classes = useStyles();
  return (
    <Modal
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      title={'KOOS-JR'}
      submitTitle={'Complete'}
    >
      <div className={classes.root}>
        <div className={classes.description}>
          Knee injury and Osteoarthritis Outcome Score for Joint Replacement
        </div>
        <div className={classes.title}>Stiffness</div>
        <ProcedureShared
          onChange={changeScore}
          index={0}
          title='1. How severe is your knee stiffness after first wakening in the morning?'
        />
        <div className={classes.textContainer}>
          <div className={classes.title}>Pain</div>
          <span>
            What amount of knee pain have you experienced in the last week during the following
            activities?
          </span>
        </div>
        <ProcedureShared
          onChange={changeScore}
          index={1}
          title='2. Twisting/pivoting on your knee'
        />
        <ProcedureShared onChange={changeScore} index={2} title='3. Straightening knee fully' />
        <ProcedureShared onChange={changeScore} index={3} title='4. Going up or down stairs' />
        <ProcedureShared onChange={changeScore} index={4} title='5. Standing upright' />
        <div className={classes.textContainer}>
          <div className={classes.title}>Function, daily living</div>
          <span>
            The following questions concern your physical function. By this we mean your ability to
            move around and to look after yourself. For each of the following activities please
            indicate the degree of difficulty you have experienced in the last week due to your
            knee.
          </span>
        </div>
        <ProcedureShared onChange={changeScore} index={5} title='6. Sitting' />
        <ProcedureShared onChange={changeScore} index={6} title='7. Bending to floor/pick up an object' />
        <div className={classes.intervalScore}>
          <div className={classes.score}>{score}</div>
          <div className={classes.scoreLabel}>Interval Score</div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(memo(KoosJRModal));
