import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { routerReducer as routing } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ThemeProvider } from '@material-ui/core/styles';
import createSagaMiddleware from 'redux-saga';
import AppSagas, { promiseListener } from './store/sagas';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from './store/reducers';
import i18n from './i18n/i18n';

import Firebase, { FirebaseContext } from './firebase';

import theme from './styles/theme';
import './index.scss';

const sagaMiddleware = createSagaMiddleware();
const combinedReducers = combineReducers({ ...reducers, routing });
const middlewares = [sagaMiddleware, promiseListener.middleware, thunk];
const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(...middlewares)));

sagaMiddleware.run(AppSagas);

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={Firebase}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </I18nextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
