export const hospitalsActionTypes = {
  FETCH_HOSPITAL: 'app/hospitals/FETCH_HOSPITAL',
  FETCH_HOSPITAL_SUCCESS: 'app/hospitals/FETCH_HOSPITAL_SUCCESS',
  FETCH_HOSPITAL_ERROR: 'app/hospitals/FETCH_HOSPITAL_ERROR',
  FETCH_HOSPITAL_ADD: 'app/hospitals/FETCH_HOSPITAL_ADD',
  FETCH_HOSPITAL_ADD_SUCCESS: 'app/hospitals/FETCH_HOSPITAL_ADD_SUCCESS',
  FETCH_HOSPITAL_ADD_ERROR: 'app/hospitals/FETCH_HOSPITAL_ADD_ADD_ERROR',
  FETCH_HOSPITAL_EDIT: 'app/hospitals/FETCH_HOSPITAL_EDIT',
  FETCH_HOSPITAL_EDIT_SUCCESS: 'app/hospitals/FETCH_HOSPITAL_EDIT_SUCCESS',
  FETCH_HOSPITAL_EDIT_ERROR: 'app/hospitals/FETCH_HOSPITAL_EDIT_ERROR',
  FETCH_HOSPITAL_DELETE: 'app/hospitals/FETCH_HOSPITAL_DELETE',
  FETCH_HOSPITAL_DELETE_SUCCESS: 'app/hospitals/FETCH_HOSPITAL_DELETE_SUCCESS',
  FETCH_HOSPITAL_DELETE_ERROR: 'app/hospitals/FETCH_HOSPITAL_DELETE_ERROR',
};

export const fetchHospitalAddSuccess = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_ADD_SUCCESS,
  payload,
});

export const fetchHospitalAddError = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_ADD_ERROR,
  payload,
});

export const fetchHospitalEditSuccess = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_EDIT_SUCCESS,
  payload,
});

export const fetchHospitalEditError = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_EDIT_ERROR,
  payload,
});

export const fetchHospitalDeleteSuccess = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_DELETE_SUCCESS,
  payload,
});

export const fetchHospitalDeleteError = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_DELETE_ERROR,
  payload,
});

export const fetchHospital = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL,
  payload,
});

export const fetchHospitalSuccess = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_SUCCESS,
  payload,
});

export const fetchHospitalError = (payload) => ({
  type: hospitalsActionTypes.FETCH_HOSPITAL_ERROR,
  payload,
});
