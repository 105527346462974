import React from 'react';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 135,
    zIndex: 1000,
  },
}));

const AppLogo = (props) => {
  const classes = useStyles();

  return <LogoIcon className={classes.root} {...props} />;
};

export default AppLogo;
