import { STORAGE_PREFIX } from './configuration';

export const changeVerificationDate = (newDate) => {
  localStorage.setItem(`${STORAGE_PREFIX}:verificationDate`, (+newDate).toString());

  return +newDate;
};

export const getCachedVerificationDate = () => {
  const cachedValue = localStorage.getItem(`${STORAGE_PREFIX}:verificationDate`);
  const parsedValue = parseInt(cachedValue);

  return parsedValue ? new Date(parsedValue) : null;
};
