import React, { memo, useEffect, useMemo } from 'react';

import FormikSelect from '../../../../formik/FormikSelect';
import FormikRadioGroup from '../../../../formik/FormikRadioGroup';
import { FastField } from 'formik';
import FormikFastInput from '../../../../formik/FormikFastInput';
import { connect } from 'formik';

import { ASSESSMENT_TYPES } from '../../../../../constants';
import KoosJRActions from '../../KoosJRActions';
import PromisActions from '../../PromisActions';
import { makeStyles } from '@material-ui/core';
import { getPreOpDataAssessmentType } from '../../utils';
import usePrevious from '../../../../../hooks/usePrevious';
import { useTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#7a7a7a',
  },
}));

const PreOpDataTKR = ({ formik }) => {
  const classes = useStyles();
  const {
    setFieldValue,
    values: {
      preOpData,
      preOpData: { assessmentType },
    },
  } = formik;
  const { t } = useTranslation();

  const assessmentTypes = useMemo(
    () => [
      { label: t('TKR.preOpOKS'), value: 'pre_op_oks' },
      { label: t('TKR.AKSS'), value: 'pre_op_akss' },
      { label: t('TKR.KOOSJR'), value: 'KOOSJR' },
    ],
    [t]
  );
  const previousAssessmentType = usePrevious(assessmentType);

  const radioButtonsVarus = useMemo(
    () => [
      { label: <span className={classes.label}>{t('TKR.VARUS')}</span>, value: 'VARUS' },
      { label: <span className={classes.label}>{t('TKR.VALGUS')}</span>, value: 'VALGUS' },
    ],
    [t]
  );

  const primaryDiagnosis = useMemo(
    () =>
      [
        'OSTEOARTHRITIS',
        'OSTEONECROSIS',
        'POST-TRAUMATIC_ARTHRITIS',
        'RHEUMATOID_ARTHRITIS',
        'FUNCTIONAL_DEFORMITY',
        'FAILED_KNEE_SURGERY',
        'FRACTURE',
        'AVN',
        'Infection',
      ].map((item) => ({
        label: capitalCase(item),
        value: item.replaceAll('_', ' '),
      })),
    [t]
  );

  const comorbidConditions = useMemo(
    () =>
      [
        'OSTEOARTHRITIS',
        'HEADACHES',
        'DEPRESSION',
        'INCREASED_BMI',
        'DIABETES',
        'STOMACH_DISORDERS',
        'OSTEOPOROSIS',
        'BLOOD_DISORDERS',
        'RHEUMATOID_ARTHRITIS',
        'CIRCULATORY_DISORDERS',
        'ENDOCRINE_DISORDERS',
        'HYPERTENSION',
        'CARDIAC_DISORDERS',
        'CANCER',
        'NERVOUS_SYSTEM_DISORDERS',
        'SMOKING',
        'LUNG_DISORDERS',
      ].map((item) => ({
        label: capitalCase(t(`TKR.${item}`)),
        value: item.replaceAll('_', ' '),
      })),
    [t]
  );

  const surgicalApproach = useMemo(
    () =>
      [
        'Medial parapatellar',
        'Midvastus',
        'Subvastus',
        'Lateral parapatellar',
        'MSI',
        'Extensile (complex primary or revision)',
      ].map((item, index) => ({
        label: t(`TKR.surgicalApproach.${index}`),
        value: item,
      })),
    []
  );

  const preOpAKSS = useMemo(() => {
    return Array.from({ length: 61 }, (v, k) => ({ label: k, value: k }));
  }, []);

  const OKS = useMemo(() => {
    return Array.from({ length: 61 }, (v, k) => ({ label: k, value: k }));
  }, []);

  const getAssessmentField = (type) => {
    switch (type) {
      case ASSESSMENT_TYPES.OKS:
        return <FormikSelect label={t('TKR.preOpOKS')} name='preOpData.preOpOKS' options={OKS} />;
      case ASSESSMENT_TYPES.AKSS:
        return (
          <FormikSelect label={t('TKR.preOpAKSS')} name='preOpData.preOpAKSS' options={preOpAKSS} />
        );
      case ASSESSMENT_TYPES.KOOSJR:
        return <KoosJRActions name='preOpData.KOOSJR' />;
    }
  };

  useEffect(() => {
    // if (!getPreOpDataAssessmentType(preOpData) && assessmentType) {
    //   setFieldValue(`preOpData.${assessmentType}`, '');
    // } else if (assessmentType && previousAssessmentType !== assessmentType) {
    //   const newPreOpData = { ...preOpData, assessmentType, [assessmentType]: '' };
    //
    //   delete newPreOpData[previousAssessmentType];
    //
    //   setFieldValue('preOpData', newPreOpData);
    // }
    // if (!assessmentType) {
    //   const newPreOpData = { ...preOpData };
    //
    //   delete newPreOpData[previousAssessmentType];
    //
    //   setFieldValue('preOpData', newPreOpData);
    // }
  }, [assessmentType]);

  return (
    <>
      <FormikSelect
        label={t('TKR.assessmentType')}
        name='preOpData.assessmentType'
        options={assessmentTypes}
      />
      {getAssessmentField(assessmentType)}
      <FormikSelect
        label={t('TKR.primaryDiagnosis')}
        name='preOpData.primaryDiagnosis'
        options={primaryDiagnosis}
      />
      <FormikSelect
        label={t('TKR.comorbidConditions')}
        name='preOpData.comorbidConditions'
        options={comorbidConditions}
        multiple
      />
      <FormikSelect
        label={t('TKR.surgicalAproach')}
        name='preOpData.surgicalApproach'
        options={surgicalApproach}
      />
      <FastField
        component={FormikRadioGroup}
        name='preOpData.varus'
        options={radioButtonsVarus}
        row
      />
      <FormikFastInput label={t('TKR.degree')} name='preOpData.varusDegree' />
      <PromisActions />
    </>
  );
};

export default connect(memo(PreOpDataTKR));
