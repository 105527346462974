import Firebase from '../../../firebase/Firebase';
import { EMAILS_COLLECTION } from '../../../firebase/collections';

const collection = Firebase.db.collection(EMAILS_COLLECTION);

export const requestsFetchEmails = async () => {
  return await collection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((response) => response);
};

export const requestFetchAddEmails = async ({ ...restProps }) => {
  return await collection.add({ ...restProps }).then((docRef) => docRef.id);
};

export const requestFetchDeleteEmail = async ({ id }) => {
  return await collection.doc(id).delete();
};

export const requestFetchEditEmail = async ({ id, ...restProps }) => {
  return await collection.doc(id).update({ ...restProps });
};
