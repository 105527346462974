import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect, FastField } from 'formik';

import FormikSelect from '../../../../formik/FormikSelect';
import FormikFastInput from '../../../../formik/FormikFastInput';
import { ButtonRow, FormColumn } from '../../style';
import FormikDatePicker from '../../../../formik/FormikDatePicker';
import { APP_ROLES } from '../../../../../constants';
import FormikRadioGroup from '../../../../formik/FormikRadioGroup';
import { Button } from '@material-ui/core';
import useUser from '../../../../../hooks/useUser';
import { anyProcedure, IBF, THR, TKR } from '../../utils';
import { memoizedFormikField } from '../../../../../util/functions';
import { useTranslation } from 'react-i18next';

const CaseDataStep = ({ formik, handleNextStep }) => {
  const {
    values: {
      caseData: { procedureId, surgeonId, productId },
    },
    setFieldValue,
    isValid,
  } = formik;
  const { list: surgeonList } = useSelector((state) => state.surgeons);
  const { list: procedureList } = useSelector((state) => state.procedures);
  const { list: productList } = useSelector((state) => state.products);
  const user = useUser();
  const { t } = useTranslation();

  const procedureSides = useMemo(
    () => [
      {
        label: t('addCase.steps.caseData.procedureSides.left'),
        value: 'LEFT',
      },
      {
        label: t('addCase.steps.caseData.procedureSides.right'),
        value: 'RIGHT',
      },
      {
        label: t('addCase.steps.caseData.procedureSides.NA'),
        value: 'N/A',
      },
      {
        label: t('addCase.steps.caseData.procedureSides.bilateral'),
        value: 'Bi-lateral',
      },
    ],
    [t]
  );

  const surgeonValueLabelList = useMemo(() => {
    if (user.role === APP_ROLES.SURGEON) {
      const { id, title, firstName, lastName } = user.assignedSurgeon;

      return [{ value: id, label: `${title} ${firstName} ${lastName}` }];
    }

    return surgeonList
      .sort(function (a, b) {
        if (a.lastName > b.lastName) {
          return 1;
        }
        if (a.lastName < b.lastName) {
          return -1;
        }
        return 0;
      })
      .map((item) => ({
        value: item.id,
        label: `${item.title} ${item.firstName} ${item.lastName}`,
      }));
  }, [surgeonList, user]);

  const hospitalValueLabelList = useMemo(() => {
    if (user.role === APP_ROLES.SURGEON) {
      return user.assignedSurgeon.hospitals
        .sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })
        .map((item) => ({
          value: item.id,
          label: item.name,
        }));
    }

    const surgeonIndex = surgeonList.findIndex((surgeon) => surgeon.id === surgeonId);

    if (surgeonIndex === -1) {
      return [];
    }

    return surgeonList[surgeonIndex]?.hospitals
      .sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      })
      .filter((hospital) => {
        if (user.role === APP_ROLES.RESEARCHER && !user.assignedSurgeons?.find((surgeon) => surgeon.id === surgeonId)) {
          return !!user.hospitals.find((h) => h.id === hospital.id);
        }
        return true;
      })
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  }, [surgeonId, surgeonList, user]);

  const procedureValueLabelList = useMemo(
    () =>
      procedureList.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [procedureList]
  );

  const productsValueLabelList = useMemo(() => {
    if (!procedureId) {
      return [];
    }

    const selectedCategory = procedureList.find((item) => item.id === procedureId).categoryId;

    return productList
      .filter((item) => item.parent === selectedCategory)
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  }, [procedureId, productList, procedureList]);

  const onChangeProcedure = (event) => {
    const label = procedureValueLabelList.find((item) => item.value === event.target.value).label;

    setFieldValue('caseData.procedureId', event.target.value);
    setFieldValue('caseData.productId', []);
    setFieldValue('type', label);

    switch (label) {
      case 'TKR': {
        setFieldValue('preOpData', TKR);
        break;
      }
      case 'THR': {
        setFieldValue('preOpData', THR);
        break;
      }
      case 'IBF': {
        setFieldValue('preOpData', IBF);
        break;
      }
      default: {
        setFieldValue('preOpData', { type: label, ...anyProcedure });
        break;
      }
    }
  };

  return (
    <FormColumn>
      <FormikDatePicker
        disableFuture={false}
        label={t('addCase.steps.caseData.date')}
        name='caseData.caseDate'
      />
      <FormikFastInput
        label={t('addCase.steps.caseData.caseNo')}
        placeholder={t('addCase.steps.caseData.caseNoPlaceHolder')}
        name='caseData.caseNumber'
      />
      <FormikSelect
        label={t('addCase.steps.caseData.surgeon')}
        disabled={user.role === APP_ROLES.SURGEON}
        placeholder='Select surgeon'
        name='caseData.surgeonId'
        options={surgeonValueLabelList}
      />
      <FormikSelect
        label={t('addCase.steps.caseData.hospital')}
        placeholder='Select hospital'
        name='caseData.hospitalId'
        options={hospitalValueLabelList}
      />
      <FormikSelect
        label={t('addCase.steps.caseData.procedure')}
        placeholder='Select procedure'
        name='caseData.procedureId'
        onChange={onChangeProcedure}
        options={procedureValueLabelList}
      />
      <FormikFastInput
        label={t('addCase.steps.caseData.patientId')}
        placeholder='Enter patient id'
        name='caseData.patientId'
      />
      <FastField
        label={t('addCase.steps.caseData.procedureSide')}
        component={FormikRadioGroup}
        name='caseData.procedureSide'
        options={procedureSides}
        row
      />
      <FormikSelect
        multiple
        label='Product'
        placeholder='Select product'
        name='caseData.productId'
        options={productsValueLabelList}
        disabled={!procedureId}
        helperText={!procedureId && 'Choose procedure to show product list'}
        renderValue={() => {
          return productId
            .reduce((acc, item) => {
              const product = productsValueLabelList.find((_item) => _item.value === item);

              if (product) {
                acc.push(product.label);
              }

              return acc;
            }, [])
            .join(', ');
        }}
      />
      {handleNextStep && (
        <ButtonRow noPadding>
          <Button
            disabled={!isValid}
            onClick={handleNextStep}
            fullWidth
            variant='contained'
            color='primary'
          >
            {t('addCase.steps.caseData.addPatientData')}
          </Button>
        </ButtonRow>
      )}
    </FormColumn>
  );
};

export default connect(
  memo(
    CaseDataStep,
    memoizedFormikField(
      ['caseData.procedureId', 'caseData.surgeonId', 'caseData.hospitalId', 'caseData.productId'],
      ['isValid']
    )
  )
);
