export const proceduresActionTypes = {
  FETCH_PROCEDURE: 'app/procedures/FETCH_PROCEDURE',
  FETCH_PROCEDURE_SUCCESS: 'app/procedures/FETCH_PROCEDURE_SUCCESS',
  FETCH_PROCEDURE_ERROR: 'app/procedures/FETCH_PROCEDURE_ERROR',
  FETCH_PROCEDURE_ADD: 'app/procedures/FETCH_PROCEDURE_ADD',
  FETCH_PROCEDURE_ADD_SUCCESS: 'app/procedures/FETCH_PROCEDURE_ADD_SUCCESS',
  FETCH_PROCEDURE_ADD_ERROR: 'app/procedures/FETCH_PROCEDURE_ADD_ERROR',
  FETCH_PROCEDURE_EDIT: 'app/procedures/FETCH_PROCEDURE_EDIT',
  FETCH_PROCEDURE_EDIT_SUCCESS: 'app/procedures/FETCH_PROCEDURE_EDIT_SUCCESS',
  FETCH_PROCEDURE_EDIT_ERROR: 'app/procedures/FETCH_PROCEDURE_EDIT_ERROR',
  FETCH_PROCEDURE_DELETE: 'app/procedures/FETCH_PROCEDURE_DELETE',
  FETCH_PROCEDURE_DELETE_SUCCESS: 'app/procedures/FETCH_PROCEDURE_DELETE_SUCCESS',
  FETCH_PROCEDURE_DELETE_ERROR: 'app/procedures/FETCH_PROCEDURE_DELETE_ERROR',
  FETCH_PROCEDURE_UPDATE_SEQUENCE: 'app/procedures/FETCH_PROCEDURE_UPDATE_SEQUENCE',
  FETCH_PROCEDURE_UPDATE_SEQUENCE_SUCCESS: 'app/procedures/FETCH_PROCEDURE_UPDATE_SEQUENCE_SUCCESS',
  FETCH_PROCEDURE_UPDATE_SEQUENCE_ERROR: 'app/procedures/FETCH_PROCEDURE_UPDATE_SEQUENCE_ERROR',
};

export const fetchProcedure = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE,
  payload,
});

export const fetchProcedureSuccess = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_SUCCESS,
  payload,
});

export const fetchProcedureError = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_ERROR,
  payload,
});

export const fetchProcedureAddSuccess = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_ADD_SUCCESS,
  payload,
});

export const fetchProcedureAddError = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_ADD_ERROR,
  payload,
});

export const fetchProcedureEditSuccess = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_EDIT_SUCCESS,
  payload,
});

export const fetchProcedureEditError = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_EDIT_ERROR,
  payload,
});
export const fetchProcedureDeleteSuccess = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_DELETE_SUCCESS,
  payload,
});

export const fetchProcedureDeleteError = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_DELETE_ERROR,
  payload,
});

export const fetchProcedureUpdateSequence = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE,
  payload,
});

export const fetchProcedureUpdateSequenceSuccess = () => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE_SUCCESS,
});

export const fetchProcedureUpdateSequenceError = (payload) => ({
  type: proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE_ERROR,
  payload,
});
