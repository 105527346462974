import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  fetchCategoryAddError,
  fetchCategoryAddSuccess,
  fetchCategoryDeleteError,
  fetchCategoryDeleteSuccess,
  categoryActionTypes,
  fetchCategoryEditSuccess,
  fetchCategoryEditError,
  fetchCategoryUpdateSequenceSuccess,
  fetchCategoryUpdateSequenceError,
  fetchCategorySuccess,
  fetchCategoryError,
} from './actions';
import {
  requestAddCategory,
  requestDeleteCategory,
  requestEditCategory,
  requestFetchCategory,
  requestUpdateCategorySequence,
} from './requests';
import { showAlert } from '../alerts/actions';
import { fetchProductDelete } from '../products/actions';

export function* watchFetchCategory() {
  yield takeEvery(categoryActionTypes.FETCH_CATEGORY, doFetchCategory);
}

export function* watchFetchCategoryAdd() {
  yield takeEvery(categoryActionTypes.FETCH_CATEGORY_ADD, doFetchCategoryAdd);
}

export function* watchFetchCategoryEdit() {
  yield takeEvery(categoryActionTypes.FETCH_CATEGORY_EDIT, doFetchCategoryEdit);
}

export function* watchFetchCategoryDelete() {
  yield takeEvery(categoryActionTypes.FETCH_CATEGORY_DELETE, doFetchCategoryDelete);
}

export function* watchFetchCategoryUpdateSequence() {
  yield takeEvery(
    categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE,
    doFetchCategoryUpdateSequence
  );
}

function* doFetchCategory() {
  try {
    const response = yield call(requestFetchCategory);
    yield put(fetchCategorySuccess(response));
  } catch (error) {
    fetchCategoryError(error);
  }
}

function* doFetchCategoryAdd(action) {
  try {
    const { payload } = action;
    const response = yield call(requestAddCategory, { ...payload });

    yield put(fetchCategoryAddSuccess({ id: response, ...payload }));
    yield put(showAlert({ message: 'Category was successfully deleted' }));
  } catch (error) {
    yield put(fetchCategoryAddError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchCategoryEdit(action) {
  try {
    const { payload } = action;
    yield call(requestEditCategory, { ...payload });

    yield put(fetchCategoryEditSuccess({ ...payload }));
    yield put(showAlert({ message: 'Category was successfully edited' }));
  } catch (error) {
    yield put(fetchCategoryEditError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchCategoryDelete(action) {
  try {
    const {
      payload: { id },
    } = action;
    const state = yield select();
    const {
      products: { list },
    } = state;
    const categoryProduct = list.filter((item) => item.parent === id);
    yield call(requestDeleteCategory, id);

    for (let i = 0; i < categoryProduct.length; i++) {
      yield put(fetchProductDelete({ product: categoryProduct[i] }));
    }

    yield put(fetchCategoryDeleteSuccess(id));
    yield put(showAlert({ message: 'Category was successfully deleted', type: 'success' }));
  } catch (error) {
    yield put(fetchCategoryDeleteError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchCategoryUpdateSequence(action) {
  try {
    const {
      categories: { list },
    } = yield select();
    yield call(requestUpdateCategorySequence, list);
    yield put(fetchCategoryUpdateSequenceSuccess());
  } catch (error) {
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
    yield put(fetchCategoryUpdateSequenceError(error));
  }
}
