import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Select from '../select';

const FormikSelect = ({ name, ...restProps }) => {
  const [field, meta] = useField(name);

  return <Select {...field} error={meta.touched && meta.error ? meta.error : ''} {...restProps} />;
};

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikSelect;
