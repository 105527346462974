import { historyActionTypes } from '../sagas/history/actions';
import { RESET_STATE } from './index';
import { deleteFromListById } from '../../util/functions';
import { compareDesc } from 'date-fns';
import { pmcfActionTypes } from '../sagas/pmcf/actions';
import { initialCasesFilter } from './cases';

const initialState = {
  list: [],
  filter: initialCasesFilter,
  PMCFFilter: initialCasesFilter,
  isLoaded: false,
  loading: false,
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case historyActionTypes.FETCH_HISTORIES: {
      return { ...state, loading: true };
    }
    case pmcfActionTypes.SET_PMCF_FILTER_SUCCESS: {
      return { ...state, PMCFFilter: { ...state.PMCFFilter, ...action.payload } };
    }
    case historyActionTypes.FETCH_HISTORIES_SUCCESS: {
      return {
        ...state,
        list: action.payload.sort((a, b) =>
          compareDesc(new Date(a.caseData.caseDate), new Date(b.caseData.caseDate))
        ),
        isLoaded: true,
        loading: false,
      };
    }
    case historyActionTypes.FETCH_HISTORIES_ERROR: {
      return { ...state, loading: false };
    }
    case historyActionTypes.SET_HISTORY_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, ...action.payload } };
    }
    case historyActionTypes.DELETE_COMPLETED_CASE: {
      return deleteFromListById(state, action.payload);
    }
    case RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default historyReducer;
