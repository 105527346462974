import React, { memo, useEffect, useMemo } from 'react';
import { FastField, connect } from 'formik';
import { capitalCase } from 'change-case';

import FormikRadioGroup from '../../../../formik/FormikRadioGroup';
import FormikSelect from '../../../../formik/FormikSelect';
import HoosJRActions from '../../HoosJRActions';

import { ASSESSMENT_TYPES } from '../../../../../constants';
import PromisActions from '../../PromisActions';
import { getPreOpDataAssessmentType } from '../../utils';
import usePrevious from '../../../../../hooks/usePrevious';
import { useTranslation } from 'react-i18next';

const PreOpDataTHR = ({ formik }) => {
  const {
    setFieldValue,
    values: {
      preOpData,
      preOpData: { assessmentType },
    },
  } = formik;

  const previousAssessmentType = usePrevious(assessmentType);
  const { t } = useTranslation();

  const primaryDiagnosis = useMemo(
    () =>
      [
        'OSTEOARTHRITIS',
        'OSTEONECROSIS',
        'POST-TRAUMATIC_ARTHRITIS',
        'HIP_DYSPLASIA',
        'FEMORAL_HEAD_FRACTURE',
        'FAILED_HIP_SURGERY',
        'AVN',
        'Infection',
      ].map((item) => ({
        label: capitalCase(item),
        value: item.replaceAll('_', ' '),
      })),
    []
  );

  const assessmentTypeOptions = useMemo(
    () => [
      { label: 'Oxford Hip Score', value: 'pre_op_ohs' },
      { label: 'HOOS-JR', value: 'HOOSJR' },
    ],
    []
  );

  const comorbidConditions = useMemo(
    () =>
      [
        'OSTEOARTHRITIS',
        'HEADACHES',
        'DEPRESSION',
        'INCREASED_BMI',
        'DIABETES',
        'STOMACH_DISORDERS',
        'OSTEOPOROSIS',
        'BLOOD_DISORDERS',
        'RHEUMATOID_ARTHRITIS',
        'CIRCULATORY_DISORDERS',
        'ENDOCRINE_DISORDERS',
        'HYPERTENSION',
        'CARDIAC_DISORDERS',
        'CANCER',
        'NERVOUS_SYSTEM_DISORDERS',
        'SMOKING',
        'LUNG_DISORDERS',
      ].map((item) => ({
        label: capitalCase(item),
        value: item.replaceAll('_', ' '),
      })),
    []
  );

  const radioButtonsDORR = useMemo(
    () => [
      { label: 'A', value: 'A' },
      { label: 'B', value: 'B' },
      { label: 'C', value: 'C' },
    ],
    []
  );

  const surgicalApproach = useMemo(
    () =>
      [
        'Posterior',
        'Direct Lateral',
        'Direct Anterior',
        'Direct Superior',
        'SuperPete',
        'SuperPath',
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );

  const preOpOHS = useMemo(() => {
    return Array.from({ length: 61 }, (v, k) => ({ label: k, value: k }));
  }, []);

  const getAssessmentField = (type) => {
    switch (type) {
      case ASSESSMENT_TYPES.HOOSJR:
        return <HoosJRActions name='preOpData.HOOSJR' />;
      case ASSESSMENT_TYPES.PREOPOHS:
        return (
          <FormikSelect label={t('THR.preOpOHS')} name='preOpData.preOpOHS' options={preOpOHS} />
        );
    }
  };

  useEffect(() => {
    // if (!getPreOpDataAssessmentType(preOpData) && assessmentType) {
    //   setFieldValue(`preOpData.${assessmentType}`, '');
    // } else if (assessmentType && previousAssessmentType !== assessmentType) {
    //   const newPreOpData = { ...preOpData, assessmentType, [assessmentType]: '' };
    //
    //   delete newPreOpData[previousAssessmentType];
    //
    //   setFieldValue('preOpData', newPreOpData);
    // }
    // if (!assessmentType) {
    //   const newPreOpData = { ...preOpData };
    //
    //   delete newPreOpData[previousAssessmentType];
    //
    //   setFieldValue('preOpData', newPreOpData);
    // }
  }, [assessmentType]);

  return (
    <>
      <FastField
        label={t('THR.DORR')}
        component={FormikRadioGroup}
        name='preOpData.DORR'
        options={radioButtonsDORR}
        row
      />
      <FormikSelect
        label={t('THR.assessmentType')}
        name='preOpData.assessmentType'
        options={assessmentTypeOptions}
      />
      {getAssessmentField(assessmentType)}
      <FormikSelect
        label={t('THR.primaryDiagnosis')}
        name='preOpData.primaryDiagnosis'
        options={primaryDiagnosis}
      />
      <FormikSelect
        label={t('THR.comorbidConditions')}
        name='preOpData.comorbidConditions'
        options={comorbidConditions}
        multiple
      />
      <FormikSelect
        label={t('THR.surgicalAproach')}
        name='preOpData.surgicalApproach'
        options={surgicalApproach}
      />
      <PromisActions />
    </>
  );
};

export default connect(memo(PreOpDataTHR));
