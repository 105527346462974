import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  fetchAssignHospitalsError,
  fetchAssignHospitalsSuccess,
  fetchEditSurgeonError,
  fetchEditSurgeonSuccess,
  fetchSurgeonAddError,
  fetchSurgeonAddSuccess,
  fetchSurgeonDeleteSuccess,
  fetchSurgeons,
  fetchSurgeonsError,
  fetchSurgeonsSuccess,
  surgeonsActionTypes,
} from './actions';
import {
  requestAddSurgeon,
  requestAssignHospitals,
  requestDeleteSurgeon,
  requestEditSurgeon,
  requestFetchSurgeons,
  requestFindUserBySurgeonId,
} from './requests';
import { showAlert } from '../alerts/actions';
import { fetchUserReset } from '../users/actions';

export function* watchFetchSurgeons() {
  yield takeEvery(surgeonsActionTypes.FETCH_SURGEONS, doFetchSurgeons);
}

export function* watchFetchAssignHospitals() {
  yield takeEvery(surgeonsActionTypes.FETCH_ASSIGN_HOSPITALS, doFetchAssignHospitals);
}

export function* watchFetchSurgeonAdd() {
  yield takeEvery(surgeonsActionTypes.FETCH_SURGEON_ADD, doFetchSurgeonAdd);
}

export function* watchFetchEditSurgeon() {
  yield takeEvery(surgeonsActionTypes.FETCH_EDIT_SURGEON, doFetchEditSurgeon);
}

export function* watchFetchSurgeonDelete() {
  yield takeEvery(surgeonsActionTypes.FETCH_DELETE_SURGEON, doFetchSurgeonDelete);
}

function* doFetchSurgeons() {
  try {
    const {
      user: { currentUser },
    } = yield select();
    const response = yield call(requestFetchSurgeons, currentUser);

    yield put(fetchSurgeonsSuccess(response));
  } catch (error) {
    yield put(fetchSurgeonsError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchAssignHospitals(action) {
  try {
    const {
      payload: { id, hospitals },
    } = action;
    yield call(requestAssignHospitals, { id, hospitals });

    yield put(fetchAssignHospitalsSuccess({ id, hospitals }));
    yield put(showAlert({ message: 'Hospital was successfully assigned' }));
  } catch (error) {
    yield put(fetchAssignHospitalsError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchEditSurgeon(action) {
  try {
    const { payload } = action;
    yield call(requestEditSurgeon, payload);

    yield put(fetchEditSurgeonSuccess(payload));
    yield put(showAlert({ message: 'Surgeon was successfully edited' }));
  } catch (error) {
    yield put(fetchEditSurgeonError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchSurgeonAdd(action) {
  try {
    const { payload } = action;
    const id = yield call(requestAddSurgeon, payload);

    yield put(fetchSurgeonAddSuccess({ id, ...payload }));
    yield put(showAlert({ message: 'Surgeon was successfully added' }));
  } catch (error) {
    yield put(fetchSurgeonAddError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchSurgeonDelete(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield call(requestDeleteSurgeon, id);
    const user = yield call(requestFindUserBySurgeonId, id);

    if (user) {
      yield put(fetchUserReset(user.uid));
    }

    yield put(fetchSurgeonDeleteSuccess(id));
    yield put(showAlert({ message: 'Surgeon was successfully deleted', type: 'success' }));
  } catch (error) {
    yield put(fetchEditSurgeonError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}
