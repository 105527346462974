import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;

  & > *:first-child {
    margin-bottom: 16px;
  }
`;
