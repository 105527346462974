import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { pmcfActionTypes, setPMCFFilterSuccess } from './actions';
import { requestSetPMCFStage } from './requests';
import { showAlert } from '../alerts/actions';

export function* watchSetPMCFFilter() {
  yield takeLatest(pmcfActionTypes.SET_PMCF_FILTER, doSetPMCFFilter);
}

export function* watchSetPMCFStages() {
  yield takeEvery(pmcfActionTypes.SET_PMCF_STAGES, doSetPMCFStage);
}

function* doSetPMCFFilter(action) {
  try {
    const { payload, hasDelay } = action;

    if (hasDelay) {
      yield delay(500);
    }

    yield put(setPMCFFilterSuccess(payload));
  } catch (error) {}
}

function* doSetPMCFStage(action) {
  try {
    yield call(requestSetPMCFStage, action.payload);
    yield put(showAlert({ message: 'PMCF payment stage checked successfully.', type: 'success' }));
  } catch (error) {
    yield put(showAlert({ message: 'Something went wrong.', type: 'error' }));
  }
}
