import { SET_CURRENT_USER, SIGN_OUT } from '../actions/actionTypes';
import { userActionTypes } from '../sagas/user/actions';

const initialState = {
  isLoading: true,
  isLoaded: true,
  currentUser: null,
};

const defaultUser = {
  uid: '',
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  emailVerified: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      if (!action.user) {
        return { ...state, isLoaded: false };
      }
      const user = action.user ? action.user : {};

      return {
        ...state,
        currentUser: { ...defaultUser, ...user },
        isLoaded: false,
      };
    case userActionTypes.FETCH_CURRENT_USER_SUCCESS: {
      return { ...state, currentUser: action.payload, isLoading: false };
    }
    case userActionTypes.FETCH_CURRENT_USER_ERROR: {
      return { ...state, isLoading: false };
    }
    case SIGN_OUT:
      return { ...state, currentUser: null, isLoaded: false };
    default:
      return state;
  }
};

export default userReducer;
