import React, { memo, useState } from 'react';
import { Paper } from '@material-ui/core';
import { differenceInHours } from 'date-fns';

import { ActionButton } from '../../modal-wrapper/style';

import { useStyles } from './style';
import useAlert from '../../../hooks/useAlert';
import { withFirebase } from '../../../firebase';
import { changeVerificationDate, getCachedVerificationDate } from '../../../storage/verification';

const VerificationAbsent = ({ firebase }) => {
  const [verificationDate, setVerificationDate] = useState(() => getCachedVerificationDate());
  const showAlert = useAlert();
  const classes = useStyles();

  const handleResendVerificationLink = () => {
    setVerificationDate(changeVerificationDate(new Date()));
    firebase.auth.currentUser
      .sendEmailVerification()
      .then(() => {
        showAlert({ message: 'Verification link was sent to your email' });
      })
      .catch((error) => {
        if (error && error?.code === 'auth/too-many-requests') {
          showAlert({
            message: 'Request blocked as email recently sent. Please try again later.',
            type: 'error',
          });
        }
      });
  };

  const isActionButtonDisabled =
    verificationDate === null ? false : differenceInHours(new Date(), verificationDate) < 2;

  return (
    <Paper className={classes.root}>
      <h5>
        <b>Please verify your email address!</b>
      </h5>
      <span>
        We've sent a link to the email address you used to create this account. Please click on the
        link in that email to complete verification.
      </span>
      <spna>If the link has expired click below to send a new one.</spna>
      <div className={classes.confirm}>
        <ActionButton disabled={isActionButtonDisabled} onClick={handleResendVerificationLink}>
          Resend Link
        </ActionButton>
      </div>
    </Paper>
  );
};

export default memo(withFirebase(VerificationAbsent));
