import React, { memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  MenuItem,
  makeStyles,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Paper,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { changeLanguage } from '../../storage/language';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    paddingLeft: 8,
    paddingRight: 2,
    color: '#ffffff',
    fontSize: 14,
    alignItems: 'center',
    border: '1px solid #ffffff',
    borderRadius: 8,
    marginRight: 8,
    cursor: 'pointer',
    maxHeight: 22,
    minWidth: 47,
    userSelect: 'none',
  },
  icon: {
    width: 18,
    transition: 'all 0.2s linear',
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
  popper: {
    '& > *': {
      top: '4px !important',
      zIndex: 1000,
    },
  },
  contrastColor: {
    color: '#a7a7a7',
    border: '1px solid #a7a7a7',
  },
  menuItem: {
    minHeight: 'auto !important',
  },
}));

const AppLanguage = () => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const classes = useStyles();
  const location = useLocation();

  const languages = useMemo(
    () => [
      {
        code: 'en',
        countryCode: 'EN',
      },
      {
        code: 'fr',
        countryCode: 'FR',
      },
    ],
    []
  );

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(changeLanguage(newLanguage));
  };

  const selectedLanguage = languages.find((item) => item.code === i18n.language);

  return (
    <>
      <div
        className={clsx(classes.root, location.pathname !== '/' && classes.contrastColor)}
        ref={ref}
        onClick={handleOpenMenu}
      >
        <span>{selectedLanguage ? selectedLanguage.countryCode : 'EN'}</span>
        <ExpandMoreIcon className={clsx(classes.icon, open && classes.iconRotate)} />
      </div>
      <div className={classes.popper}>
        <Popper open={open} anchorEl={ref.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList id='menu-list-grow'>
                    {languages.map((item) => (
                      <MenuItem
                        key={item.code}
                        className={classes.menuItem}
                        onClick={() => {
                          handleChangeLanguage(item.code);
                          handleCloseMenu();
                        }}
                      >
                        <span className={classes.countryCode}>{item.countryCode}</span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

export default memo(AppLanguage);
