import React from 'react';
import { useSelector } from 'react-redux';

const useUserCountry = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { selected } = useSelector((state) => state.countries);

  return currentUser.country || currentUser?.assignedSurgeon?.country || selected;
};

export default useUserCountry;
