import {
  LOAD_USERS,
  RECEIVE_USERS,
  UPDATE_ROLE_TO_SALES_REP,
  UPDATE_ROLE_TO_ADMIN,
  UPDATE_ROLE_TO_SURGEON,
  UPDATE_ROLE_TO_USER,
  DELETE_USER_ROLE,
  UPDATE_SALES_REP_TOKENS,
  REMOVE_SALES_REP_ROLE,
  REMOVE_SURGEON_ROLE,
  UPDATE_ASSIGN_SURGEONS,
} from '../actions/actionTypes';
import { fetchSaveUsers, usersActionTypes } from '../sagas/users/actions';
import { APP_ROLES } from '../../constants';
import { rewardsActionTypes } from '../sagas/rewards/actions';

const initialState = {
  loading: false,
  list: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case usersActionTypes.FETCH_USERS:
      return { ...state, loading: true };
    case usersActionTypes.FETCH_USERS_SUCCESS:
      return { ...state, loading: false, list: action.payload };

    case usersActionTypes.FETCH_SET_USER_ROLE_SUCCESS: {
      const { uid, role, country, surgeon } = action.payload;
      const { list: oldList } = state;
      const list = [...oldList];
      const index = list.findIndex((item) => item.uid === uid);

      if (index === -1) {
        return state;
      }

      switch (role) {
        case APP_ROLES.ADMIN: {
          list[index] = { ...list[index], role: APP_ROLES.ADMIN };
          break;
        }
        case APP_ROLES.REGULATORY: {
          list[index] = { ...list[index], role: APP_ROLES.REGULATORY };
          break;
        }
        case APP_ROLES.SALES_REP: {
          list[index] = {
            ...list[index],
            country,
            role: APP_ROLES.SALES_REP,
            beerTokens: 0,
            assignedSurgeons: [],
          };
          break;
        }
        case APP_ROLES.RESEARCHER: {
          list[index] = {
            ...list[index],
            country,
            role: APP_ROLES.RESEARCHER,
            beerTokens: 0,
            assignedSurgeons: [],
          };
          break;
        }
        case APP_ROLES.LOGISTIC: {
          list[index] = {
            ...list[index],
            country,
            role: APP_ROLES.LOGISTIC,
          };
          break;
        }
        case APP_ROLES.SURGEON: {
          list[index] = {
            ...list[index],
            role: APP_ROLES.SURGEON,
            surgeonId: surgeon.id,
          };
          if (surgeon.assignedUser.uid) {
            const oldSurgeonIndex = list.findIndex((item) => item.uid === surgeon.assignedUser.uid);

            if (oldSurgeonIndex !== -1) {
              list[oldSurgeonIndex] = { ...list[oldSurgeonIndex], role: null };
            }
          }
          break;
        }
      }

      return { ...state, list };
    }

    case usersActionTypes.FETCH_ASSIGN_SALES_REP_SURGEONS_SUCCESS: {
      const { uid, assignedSurgeons } = action.payload;
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.uid === uid);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], assignedSurgeons };

      return { ...state, list: newList };
    }

    case usersActionTypes.FETCH_ASSIGN_RESEARCHER_HOSPITALS_SUCCESS: {
      const { uid, hospitals } = action.payload;
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.uid === uid);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], hospitals };

      return { ...state, list: newList };
    }

    case UPDATE_ROLE_TO_USER: {
      const { uid } = action.value;
      const data = [...state.list];
      const index = data.findIndex((item) => item.uid === uid);

      data[index].surgeonId = null;
      data[index].role = null;

      return { ...state, list: data };
    }
    case DELETE_USER_ROLE: {
      const { id } = action.value;
      const list = [...state.list];
      const index = list.findIndex((item) => item.uid === id);

      list[index].role = null;
      list[index].surgeonId = '';
      return { ...state, loading: false, list };
    }
    case REMOVE_SALES_REP_ROLE: {
      const { id } = action.value;
      const list = [...state.list];
      const index = list.findIndex((item) => item.uid === id);

      list[index].role = null;
      list[index].country = '';
      return { ...state, loading: false, list };
    }
    case REMOVE_SURGEON_ROLE: {
      const { id } = action.value;
      const list = [...state.list];
      const index = list.findIndex((item) => item.uid === id);

      list[index].role = null;
      list[index].surgeonId = '';
      return { ...state, loading: false, list };
    }
    case UPDATE_ASSIGN_SURGEONS: {
      const { id, assignedSurgeons } = action.value;
      const list = [...state.list];
      const index = list.findIndex((item) => item.uid === id);

      list[index].assignedSurgeons = assignedSurgeons;
      return { ...state, loading: false, list };
    }
    case UPDATE_SALES_REP_TOKENS: {
      const salesReps = action.value;
      const list = [...state.list];

      salesReps.forEach((salesRep) => {
        const index = list.findIndex((item) => item.uid === salesRep.id);

        if (index !== -1) {
          list[index].beerTokens = salesRep.beerTokens;
        }
      });
      return { ...state, list };
    }
    case usersActionTypes.FETCH_USER_RESET_SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.uid === action.payload);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], role: APP_ROLES.USER };

      return { ...state, list: newList };
    }
    case rewardsActionTypes.FETCH_EDIT_SALES_REPS_TOKENS_SUCCESS: {
      const list = [...state.list];
      const { id, newTokens } = action.payload;

      const index = list.findIndex((item) => item.uid === id);

      if (index === -1) {
        return state;
      }

      list[index].beerTokens = newTokens;
      return {
        ...state,
        list,
      };
    }
    case usersActionTypes.FETCH_USER_CHANGE_COUNTRY_SUCCESS: {
      const list = [...state.list];
      const { id, country } = action.payload;
      const index = list.findIndex((item) => item.uid === id);

      if (index === -1) {
        return state;
      }

      list[index].country = country;

      return { ...state, list };
    }
    default:
      return state;
  }
};

export default usersReducer;
