import React, { memo } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppLogo from '../app-logo/AppLogo';
import AppLogoMobile from '../app-logo/AppLogoMobile';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  desktopLogo: {
    width: '100%',
    paddingTop: 40,
    paddingLeft: 65,
    [theme.breakpoints.down(600)]: {
      paddingLeft: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 20,
      paddingTop: 20,
    },
  },
  mobileLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 0px',
  },
}));

const SideBarLogo = () => {
  const isPhone = useMediaQuery('(max-width: 600px)');
  const isDesktop = useMediaQuery('(min-width: 1050px)');
  const classes = useStyles();

  if (isPhone || isDesktop) {
    return (
      <div className={classes.desktopLogo}>
        <Link to='/'>
          <AppLogo />
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.mobileLogo}>
      <Link to='/'>
        <AppLogoMobile />
      </Link>
    </div>
  );
};

export default memo(SideBarLogo);
