import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Input from '../input';

const FormikInput = ({ name, ...restProps }) => {
  const [field, meta] = useField(name);

  return (
    <Input
      name={name}
      {...restProps}
      {...field}
      error={meta.touched && meta.error ? meta.error : ''}
    />
  );
};

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikInput;
