import { call, put, takeEvery } from 'redux-saga/effects';
import {
  emailsActionTypes,
  fetchAddEmailError,
  fetchAddEmailSuccess,
  fetchDeleteEmailSuccess,
  fetchEditEmailError,
  fetchEditEmailSuccess,
  fetchEmailsSuccess,
} from './actions';
import {
  requestFetchAddEmails,
  requestFetchDeleteEmail,
  requestFetchEditEmail,
  requestsFetchEmails,
} from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchEmails() {
  yield takeEvery(emailsActionTypes.FETCH_EMAILS, doFetchEmails);
}

export function* watchFetchEditEmails() {
  yield takeEvery(emailsActionTypes.FETCH_EDIT_EMAILS, doFetchEditEmail);
}

export function* watchFetchAddEmail() {
  yield takeEvery(emailsActionTypes.FETCH_ADD_EMAIL, doFetchAddEmail);
}

export function* watchDeleteEmail() {
  yield takeEvery(emailsActionTypes.FETCH_DELETE_EMAIL, doFetchDeleteEmail);
}

function* doFetchEmails() {
  try {
    const response = yield call(requestsFetchEmails);

    yield put(fetchEmailsSuccess(response));
  } catch (error) {
    console.log('ERROR:', error);
  }
}

function* doFetchAddEmail(action) {
  try {
    const { email, type, country } = action.payload;
    const existingEmails = yield call(requestsFetchEmails);

    if (
      existingEmails.find(
        (item) => item.email === email && item.type === type && item.country === country
      )
    ) {
      yield put(fetchAddEmailError());
      yield put(
        showAlert({ message: 'This email already exist for this category.', type: 'error' })
      );
    } else {
      const response = yield call(requestFetchAddEmails, action.payload);
      yield put(fetchAddEmailSuccess({ id: response, ...action.payload }));
    }
  } catch (error) {
    yield put(fetchAddEmailError());
    console.log('ERROR:', error);
  }
}

function* doFetchEditEmail(action) {
  try {
    const { email, type, id } = action.payload;
    const existingEmails = yield call(requestsFetchEmails);

    if (existingEmails.filter((item) => item.id === id && item.email === email).length !== 0) {
      yield put(fetchEditEmailSuccess(action.payload));
      yield put(showAlert({ message: 'Email successful was change.', type: 'success' }));
      return;
    }
    if (existingEmails.filter((item) => item.email === email && item.type === type).length !== 0) {
      yield put(fetchEditEmailError());
      yield put(
        showAlert({ message: 'This email already exist for this category.', type: 'error' })
      );
      return;
    }

    yield call(requestFetchEditEmail, action.payload);
    yield put(fetchEditEmailSuccess(action.payload));
    yield put(showAlert({ message: 'Email successful was change.', type: 'success' }));
  } catch (error) {
    console.log('ERROR:', error);
  }
}

function* doFetchDeleteEmail(action) {
  try {
    yield call(requestFetchDeleteEmail, action.payload);
    yield put(fetchDeleteEmailSuccess(action.payload));
  } catch (error) {
    console.log(error);
  }
}
