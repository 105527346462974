import { takeEvery, call, put } from 'redux-saga/effects';
import { requestFetchCurrentUser } from './requests';
import { fetchCurrentUserError, fetchCurrentUserSuccess, userActionTypes } from './actions';

export function* watchFetchCurrentUser() {
  yield takeEvery(userActionTypes.FETCH_CURRENT_USER, doFetchCurrentUser);
}

function* doFetchCurrentUser(action) {
  try {
    const response = yield call(requestFetchCurrentUser);
    yield put(fetchCurrentUserSuccess(response));

    if (action.payload && typeof action.payload === 'function') {
      action.payload();
    }
  } catch (error) {
    yield put(fetchCurrentUserError());
  }
}
