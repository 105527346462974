import React, { memo } from 'react';
import AddCaseModal from '../components/modals/add-case/AddCaseModal';
import SuccessSubmitModal from '../components/modals/success-submit/SuccessSubmitModal';
import VerificationModal from '../components/modals/verification/VerificationModal';

const GlobalModals = () => (
  <>
    <AddCaseModal />
    <SuccessSubmitModal />
    <VerificationModal />
  </>
);

export default memo(GlobalModals);
