import user from './user';
import sidebar from './sidebar';
import users from './users';
import categories from './categories';
import products from './products';
import procedures from './procedures';
import countries from './countries';
import hospitals from './hospitals';
import surgeons from './surgeons';
import news from './news';
import rewards from './rewards';
import modal from './modal';
import history from './history';
import cases from './cases';
import emails from './email';

export const RESET_STATE = 'RESET_STATE';

export default {
  user,
  users,
  sidebar,
  categories,
  products,
  procedures,
  countries,
  hospitals,
  surgeons,
  news,
  rewards,
  modal,
  history,
  cases,
  emails,
};
