import { LOAD_HOSPITALS, RECEIVE_HOSPITALS, DELETE_HOSPITAL } from '../actions/actionTypes';
import { hospitalsActionTypes } from '../sagas/hospitals/actions';
import { deleteFromListById } from '../../util/functions';
import { RESET_STATE } from './index';

const initialState = {
  list: [],
  loading: false,
};

const hospitalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case hospitalsActionTypes.FETCH_HOSPITAL:
      return { ...state, loading: true };
    case hospitalsActionTypes.FETCH_HOSPITAL_SUCCESS:
      return { ...state, loading: false, list: action.payload };
    case hospitalsActionTypes.FETCH_HOSPITAL_ADD_SUCCESS: {
      return { ...state, list: [action.payload, ...state.list] };
    }
    case hospitalsActionTypes.FETCH_HOSPITAL_EDIT_SUCCESS: {
      const {
        payload: { id, name, state: hospitalState, address, code },
      } = action;
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.id === id);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], name, state: hospitalState, address, code };

      return { ...state, list: newList };
    }
    case DELETE_HOSPITAL: {
      const { id } = action.value;
      const list = [...state.list];
      const index = list.findIndex((item) => item.id === id);

      list.splice(index, 1);
      return { ...state, loading: false, list };
    }
    case hospitalsActionTypes.FETCH_HOSPITAL_DELETE_SUCCESS: {
      return deleteFromListById(state, action.payload);
    }
    case RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default hospitalsReducer;
