import Firebase from '../../../firebase';
import { HOSPITALS_COLLECTION } from '../../../firebase/collections';

const collection = Firebase.db.collection(HOSPITALS_COLLECTION);

export const requestAddHospital = async ({ ...restProps }) => {
  return await collection.add({ ...restProps }).then((docRef) => docRef.id);
};

export const requestEditHospital = async ({ id, ...restProps }) => {
  return await collection.doc(id).update({ ...restProps });
};

export const requestDeleteHospital = async (id) => {
  return await collection.doc(id).delete();
};

export const requestFetchHospital = async () => {
  return await collection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
};
