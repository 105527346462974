import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const SignUpPage = React.lazy(() => import('../pages/auth/SignUpPage'));
const LoginPage = React.lazy(() => import('../pages/auth/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('../pages/auth/ResetPasswordPage'));
import AppLogo from '../components/app-logo/AppLogo';

import { routes } from '../constants';
import { makeStyles } from '@material-ui/core/styles';
import PublicPageBackground from './style';
import Spinner from '../components/loaders/spinner';

const useStyles = makeStyles((theme) => ({
  logo: {
    position: 'fixed',
    zIndex: 1000,
    top: 40,
    left: 45,
    [theme.breakpoints.down(600)]: {
      top: 20,
      left: '30%',
    },
  },
}));

const PublicRoutes = ({ redirectTo }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.logo}>
        <AppLogo />
      </div>
      <PublicPageBackground />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={routes.HOME} render={() => <LoginPage redirectTo={redirectTo} />} />
          <Route exact path={routes.SIGN_UP} component={SignUpPage} />
          <Route exact path={routes.RESET_PASSWORD} component={ResetPasswordPage} />
          <Redirect to='/' />
        </Switch>
      </Suspense>
    </>
  );
};

export default PublicRoutes;
