import React, { memo, useCallback, useMemo, useState } from 'react';
import { makeStyles, Step, Stepper } from '@material-ui/core';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CaseDataStep from './steps/CaseDataStep';
import PatientDataStep from './steps/PatientDataStep';
import PreOpDataStep from './steps/PreOpDataStep';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: 0,
    backgroundColor: 'transparent !important',
  },
  label: {
    '& > *': {
      '& > *': {
        fontSize: '20px !important',
        fontWeight: '700 !important',
      },
    },
  },
}));

const AddCaseStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const steps = useMemo(
    () => [
      t('addCase.steps.caseData.label'),
      t('addCase.steps.patientData.label'),
      t('addCase.steps.preOpData.label'),
    ],
    [t]
  );

  const handleNextStep = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, [activeStep]);

  const handleBackStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, [activeStep]);

  const getActiveStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return <CaseDataStep handleNextStep={handleNextStep} />;
      case 1:
        return <PatientDataStep handleBackStep={handleBackStep} handleNextStep={handleNextStep} />;
      case 2:
        return <PreOpDataStep handleBackStep={handleBackStep} handleNextStep={handleNextStep} />;
      default:
        return null;
    }
  };

  return (
    <Stepper className={classes.stepper} activeStep={activeStep} orientation='vertical'>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel className={classes.label}>{label}</StepLabel>
          <StepContent>{getActiveStep(activeStep)}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default memo(AddCaseStepper);
