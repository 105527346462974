import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LoadScreen from './components/loaders/load-screen';
import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoutes from './routes/PublicRoutes';

import Toast from './components/alerts/Toast';
import useAsyncRedux from './hooks/useAsyncRedux';

import { fetchCurrentUser } from './store/sagas/user/actions';
import { AppContainer } from './styles/style';
import { useLocation } from 'react-router-dom';

const App = () => {
  const { currentUser, isLoading } = useSelector((state) => state.user);
  const location = useLocation();
  const pathname = useMemo(() => location.pathname, []);

  useAsyncRedux(fetchCurrentUser);

  if (isLoading) {
    return <LoadScreen />;
  }

  return (
    <AppContainer>
      {currentUser ? <PrivateRoutes /> : <PublicRoutes redirectTo={pathname} />}
      <Toast />
    </AppContainer>
  );
};

export default App;
