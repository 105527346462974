import Firebase from '../../../firebase';
import { SURGEONS_COLLECTION } from '../../../firebase/collections';
import { APP_ROLES } from '../../../constants';

const collection = Firebase.db.collection(SURGEONS_COLLECTION);

export const requestFetchCurrentUser = async () => {
  const user = await Firebase.doGetCurrentUser();

  if (!user) {
    throw new Error();
  }

  if (user.role === APP_ROLES.SURGEON) {
    const surgeon = await collection
      .doc(user.surgeonId)
      .get()
      .then((docRef) => ({ id: docRef.id, ...docRef.data() }));

    return { ...user, assignedSurgeon: surgeon };
  }

  return user;
};
