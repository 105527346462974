import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

export const AppContainer = styled.div`
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const PagePaper = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => (padding !== undefined ? padding : 24)}px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const useStyles = makeStyles((theme) => ({
  pageContent: {
    overflowY: 'auto',
    height: 'calc(100% - 98px)',
    [theme.breakpoints.down(600)]: {
      height: 'calc(100% - 72px)',
    },
  },
  fullPageContent: {
    overflowY: 'auto',
    height: '100%',
  },
  pageWithoutFooter: {
    height: '100%',
  },
}));
