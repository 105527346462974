import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  newsActionTypes,
  fetchNewsAddError,
  fetchNewsAddSuccess,
  fetchNewsEditError,
  fetchNewsEditSuccess,
  fetchNewsDeleteSuccess,
  fetchNewsDeleteError,
  fetchNewsUpdateSequenceSuccess,
  fetchNewsUpdateSequenceError,
  fetchNewsSuccess,
} from './actions';
import {
  requestAddNews,
  requestDeleteNews,
  requestEditNews,
  requestFetchNews,
  requestUpdateNewsSequence,
} from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchNews() {
  yield takeEvery(newsActionTypes.FETCH_NEWS, doFetchNews);
}

export function* watchFetchNewsAdd() {
  yield takeEvery(newsActionTypes.FETCH_NEWS_ADD, doFetchNewsAdd);
}

export function* watchFetchNewsEdit() {
  yield takeEvery(newsActionTypes.FETCH_NEWS_EDIT, doFetchNewsEdit);
}

export function* watchFetchNewsDelete() {
  yield takeEvery(newsActionTypes.FETCH_NEWS_DELETE, doFetchNewsDelete);
}

export function* watchFetchUpdateNewsSequence() {
  yield takeEvery(newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE, doFetchUpdateNewsSequence);
}

function* doFetchNews() {
  try {
    const response = yield call(requestFetchNews);
    yield put(fetchNewsSuccess(response));
  } catch (error) {
    console.log(error);
  }
}

function* doFetchNewsAdd(action) {
  try {
    const { payload } = action;
    const response = yield call(requestAddNews, { ...payload });

    yield put(fetchNewsAddSuccess({ id: response, ...payload }));
    yield put(showAlert({ message: 'The news has been successfully added' }));
  } catch (error) {
    yield put(fetchNewsAddError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchNewsEdit(action) {
  try {
    const { payload } = action;
    yield call(requestEditNews, { ...payload });

    yield put(fetchNewsEditSuccess({ ...payload }));
    yield put(showAlert({ message: 'The news has been successfully edited' }));
  } catch (error) {
    yield put(fetchNewsEditError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchNewsDelete(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield call(requestDeleteNews, id);

    yield put(fetchNewsDeleteSuccess(id));
    yield put(showAlert({ message: 'News was successfully deleted', type: 'success' }));
  } catch (error) {
    yield put(fetchNewsDeleteError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchUpdateNewsSequence(action) {
  try {
    yield call(requestUpdateNewsSequence, action.payload);
    yield put(fetchNewsUpdateSequenceSuccess());
  } catch (error) {
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
    yield put(fetchNewsUpdateSequenceError(error));
  }
}
