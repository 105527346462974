import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const AlertBar = ({ severity = 'warning', title = '', text, ...restProps }) => {
  return (
    <Alert {...restProps} severity={severity}>
      {title && (
        <AlertTitle>
          <strong>{title}</strong>
        </AlertTitle>
      )}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </Alert>
  );
};

export default AlertBar;
