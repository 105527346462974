import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import { ReactComponent as CasesIcon } from '../../assets/case_menu_icon.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/analytics_menu_icon.svg';
import { ReactComponent as HistoryIcon } from '../../assets/history_menu_icon.svg';
import { ReactComponent as ProductsIcon } from '../../assets/products_menu_icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings_menu_icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/home_menu_icon.svg';
import { ReactComponent as PMCFIcon } from '../../assets/contact_page_black_24dp.svg';

import SideBarLogo from './SideBarLogo';
import SidebarItem from './SidebarItem';

import { APP_ROLES, routes } from '../../constants';
import useUserRole from '../../hooks/useUserRole';

import './sidebar.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const role = useUserRole();

  const list = useMemo(() => {
    const sidebarLinks = [
      {
        label: t('sidebar.home'),
        path: routes.HOME,
        Icon: HomeIcon,
        children: [],
      },
      {
        label: t('sidebar.cases'),
        path: routes.CASES,
        Icon: CasesIcon,
        children: [],
      },
      {
        label: t('sidebar.analytics'),
        path: routes.ANALYTICS,
        Icon: AnalyticsIcon,
        children: [],
      },
      {
        label: t('sidebar.history'),
        path: routes.HISTORY,
        Icon: HistoryIcon,
        children: [],
      },
      {
        label: t('sidebar.products'),
        path: routes.PRODUCTS,
        Icon: ProductsIcon,
        children: [],
      },
      {
        label: 'PMCF',
        path: routes.PMCF,
        Icon: PMCFIcon,
        children: [],
      },
    ];
    if (role === APP_ROLES.LOGISTIC) {
      sidebarLinks.splice(sidebarLinks.length - 1, 1);
    }
    if (role === APP_ROLES.ADMIN) {
      sidebarLinks.push({
        label: t('sidebar.settings'),
        path: routes.SETTINGS_GLOBAL,
        Icon: SettingsIcon,
        children: [
          {
            label: t('sidebar.global'),
            path: routes.SETTINGS_GLOBAL,
          },
          {
            label: t('sidebar.country'),
            path: routes.SETTINGS_COUNTRY,
          },
          {
            label: t('sidebar.users'),
            path: routes.SETTINGS_USERS,
          },
          {
            label: t('sidebar.rewards'),
            path: routes.SETTINGS_REWARDS,
          },
          {
            label: t('sidebar.notifications'),
            path: routes.SETTINGS_NOTIFICATIONS,
          },
        ],
      });
    }

    return sidebarLinks;
  }, [role, t]);

  return (
    <div className='sidebar'>
      <div className='sidebar-list'>
        <SideBarLogo />
        <List className='list' disablePadding>
          {list.map((item, index, arr) => {
            return <SidebarItem key={index} index={index} item={item} list={arr} />;
          })}
        </List>
      </div>
    </div>
  );
};

export default Sidebar;
