import React from 'react';

import { StyledTextField, Container, Label, ErrorLabel } from './style';

const Input = ({
  variant = 'outlined',
  className = '',
  error = '',
  style = {},
  label = '',
  ...restProps
}) => {
  return (
    <Container className={className} style={style}>
      {label && <Label>{label}</Label>}
      <StyledTextField autoComplete='off' variant={variant} {...restProps} />
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </Container>
  );
};

export default Input;
