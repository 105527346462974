export const userActionTypes = {
  FETCH_CURRENT_USER: 'app/user/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_SUCCESS: 'app/user/FETCH_CURRENT_USER_SUCCESS',
  FETCH_CURRENT_USER_ERROR: 'app/user/FETCH_CURRENT_USER_ERROR',
};

export const fetchCurrentUser = (payload) => ({
  type: userActionTypes.FETCH_CURRENT_USER,
  payload,
});

export const fetchCurrentUserSuccess = (payload) => ({
  type: userActionTypes.FETCH_CURRENT_USER_SUCCESS,
  payload,
});

export const fetchCurrentUserError = (payload) => ({
  type: userActionTypes.FETCH_CURRENT_USER_ERROR,
  payload,
});
