import React from 'react';
import { StyledDatePicker, Container, Label, ClearButton, PlaceHolder, StyledKeyboardDatePicker } from './style';
import CloseIcon from '@material-ui/icons/Close';

const DatePicker = ({
  value,
  format = 'do MMMM yyyy',
  onChange,
  label,
  clearIcon = true,
  disableFuture = false,
  placeholder = '',
  controlled,
  onOpen,
  handleOpen,
  handleClose,
  isOpened,
  type,
  ...restProps
}) => {
  if (type === 'keyboard') {
    if (controlled) {
      return <Container>
        {!!label && <Label>{label}</Label>}
        <StyledKeyboardDatePicker
          format="dd/MM/yyyy"
          value={value}
          onChange={onChange}
          disableFuture={disableFuture}
          placeholder={placeholder || 'dd/mm/yyyy'}
          onOpen={handleOpen}
          onClose={handleClose}
          open={isOpened}
          {...restProps}
        />
      </Container>
    }

    return <Container>
      {!!label && <Label>{label}</Label>}
      <StyledKeyboardDatePicker
        onOpen={onOpen}
        format="dd/MM/yyyy"
        value={value}
        onChange={onChange}
        disableFuture={disableFuture}
        {...restProps}
      />
    </Container>
  }

  return (
    <Container>
      {placeholder && !value && <PlaceHolder>{placeholder}</PlaceHolder>}
      {label && <Label>{label}</Label>}
      {clearIcon && value && (
        <ClearButton
          hasLabel={Boolean(label)}
          onClick={(event) => {
            event.stopPropagation();
            onChange(null);
          }}
          size='small'
        >
          <CloseIcon color='primary' />
        </ClearButton>
      )}
      <StyledDatePicker
        onOpen={onOpen}
        format={format}
        value={value}
        onChange={onChange}
        disableFuture={disableFuture}
        {...restProps}
      />
    </Container>
  );
};

export default DatePicker;
