import { emailsActionTypes } from '../sagas/emails/actions';
import { deleteFromListById } from '../../util/functions';

const initialState = {
  loading: false,
  list: [],
};

const emailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case emailsActionTypes.FETCH_EMAILS_SUCCESS:
      return { ...state, list: action.payload };
    case emailsActionTypes.FETCH_ADD_EMAIL_SUCCESS: {
      return { ...state, list: [action.payload, ...state.list] };
    }
    case emailsActionTypes.FETCH_DELETE_EMAIL_SUCCESS: {
      return deleteFromListById(state, action.payload.id);
    }
    case emailsActionTypes.FETCH_EDIT_EMAILS_SUCCESS: {
      const { id } = action.payload;
      const { list: oldList } = state;
      const list = [...oldList];
      const index = list.findIndex((item) => item.id === id);

      if (index === -1) {
        return state;
      }

      list[index] = { ...action.payload };

      return { ...state, list };
    }
    case emailsActionTypes.FETCH_EDIT_EMAILS_ERROR: {
      return { ...state };
    }
    case emailsActionTypes.FETCH_ADD_EMAIL_ERROR: {
      return { ...state };
    }
  }

  return { ...state };
};

export default emailsReducer;
