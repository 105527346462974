import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Button from '../button';
import { BREAKPOINTS } from '../../constants';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    box-shadow: none;
    background-color: #f2f2f2;
    @media screen and (max-width: ${BREAKPOINTS.SM}px) {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: #ffffff !important;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 20px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  @media screen and (min-width: ${BREAKPOINTS.SM}px) {
    min-width: 500px;
  }
`;

export const CloseButton = styled(IconButton)`
  background-color: #ffffff;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
`;

export const StyledTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  opacity: 0.8;
`;

export const ActionButton = styled(Button)`
  max-width: 140px;
  width: 100%;

  .MuiButton-label {
    min-width: inherit;
  }
`;

export const CancelButton = styled.span`
  font-weight: 700;
  color: #7a7a7a;
  font-size: 16px;
  cursor: pointer;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 8px 20px !important;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 20px !important;
  justify-content: space-between !important;
`;
