import styled from 'styled-components';
import { ReactComponent as BackgroundLogo } from '../assets/signature_logo_white_bg.svg';

const PublicPageBackground = styled(BackgroundLogo)`
  width: 100%;
  height: 100%;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  left: -40%;
  top: -25%;
  z-index: 0;
  opacity: 0.5;
`;

export default PublicPageBackground;
