import { DatePicker as MuiDatePicker, KeyboardDatePicker, DateRangePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const StyledDatePicker = styled(MuiDatePicker)`
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;

  .MuiInputBase-input {
    padding: 15.5px 14px !important;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInput-underline:after {
    display: none;
  }
`;

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;

  .MuiInputBase-input {
    padding: 15.5px 14px !important;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInput-underline:after {
    display: none;
  }
  
  .MuiFormHelperText-root {
    padding-left: 14px;
    padding-bottom: 4px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.span`
  margin-bottom: 8px;
  font-weight: 600;
  opacity: 0.8;
`;

export const PlaceHolder = styled.label`
  position: absolute;
  z-index: 999;
  top: 45px;
  left: 14px;
  opacity: 0.6;
  cursor: text;
  pointer-events: none;
`;

export const ClearButton = styled(IconButton)`
  position: absolute !important;
  right: 10px;
  top: ${({ hasLabel }) => (hasLabel ? '42px' : '12px')};
  z-index: 10;
`;
