import Firebase from '../../../firebase';
import { PRODUCT_COLLECTION } from '../../../firebase/collections';

const collection = Firebase.db.collection(PRODUCT_COLLECTION);
const storageRef = Firebase.storage.ref();

export const requestAddProduct = async (data) =>
  await collection.add(data).then((docRef) => docRef.id);

export const requestEditProduct = async ({ id, ...restProps }) =>
  await collection.doc(id).update({ ...restProps });

export const requestDeleteProduct = async (id) => await collection.doc(id).delete();

export const requestUploadFile = async ({ path, file }) => await storageRef.child(path).put(file);

export const requestDeleteFile = async ({ path }) =>
  await storageRef
    .child(path)
    .delete()
    .catch(() => {});

export const requestDeleteFileGroup = async ({ id, removedFiles }) => {
  const promises = [];

  for (const prop in removedFiles) {
    removedFiles[prop].forEach((item) => {
      promises.push(
        storageRef
          .child(`Product_files/${id}_${prop}_${item}`)
          .delete()
          .catch(() => {})
      );
    });
  }

  return await Promise.all(promises);
};

export const requestUploadFileGroup = async ({ id, files }) => {
  return await Promise.all(
    files.reduce((acc, item) => {
      const uploadingFiles = item.files.map((file) =>
        storageRef
          .child(`Product_files/${id}_${item.country}_${file.name}`)
          .put(file)
          .catch(() => {})
      );

      return [...acc, ...uploadingFiles];
    }, [])
  );
};

export const requestForbiddenProduct = async ({ selected, product, id }) => {
  const forbidden = [...product.forbidden];
  const index = forbidden.findIndex((item) => item === selected);

  if (index === -1) {
    return await Firebase.db
      .collection(PRODUCT_COLLECTION)
      .doc(id)
      .update({
        forbidden: [...forbidden, selected],
      });
  } else {
    forbidden.splice(index, 1);

    return await Firebase.db.collection(PRODUCT_COLLECTION).doc(id).update({
      forbidden,
    });
  }
};

export const requestFetchProduct = async () => {
  return await collection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
};
