import { casesActionTypes } from '../sagas/cases/actions';
import { CASE_STATUSES } from '../../constants';
import { differenceInHours, compareDesc } from 'date-fns';
import { RESET_STATE } from './index';
import { deleteFromListById } from '../../util/functions';

export const initialCasesFilter = {
  search: '',
  surgeonId: '',
  hospitalId: '',
  procedureId: '',
  caseDate: null,
  procedureName: '',
  surgeonName: '',
  currentTab: 0,
  dateRange: [],
  dateRangeSubmitted: [],
  dateRangeSubmittedStart: null,
  dateRangeSubmittedEnd: null,
  dateRangeStart: null,
  dateRangeEnd: null,
  status: CASE_STATUSES.OPEN,
};

const initialState = {
  list: [],
  filter: initialCasesFilter,
  isLoaded: false,
  loading: false,
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case casesActionTypes.FETCH_CASES: {
      return { ...state, loading: true };
    }
    case casesActionTypes.FETCH_CASES_SUCCESS: {
      return {
        ...state,
        list: action.payload
          .filter((item) => item.status !== CASE_STATUSES.CANCELLED)
          .map((item) => {
            if (!item.caseData.surgeonId) {
              item.caseData.surgeonId = item.caseData.surgeon.id;
            }
            if (!item.caseData.hospitalId) {
              item.caseData.hospitalId = item.caseData.hospital.id;
            }

            if (item.status !== CASE_STATUSES.OPEN) {
              return item;
            } else if (differenceInHours(new Date(), new Date(item.caseData.caseDate)) >= 24) {
              return {
                ...item,
                status: CASE_STATUSES.OVERDUE,
              };
            }

            return item;
          })
          .sort((a, b) =>
            compareDesc(new Date(a.caseData.caseDate), new Date(b.caseData.caseDate))
          ),
        loading: false,
        isLoaded: true,
      };
    }
    case casesActionTypes.FETCH_CASES_ERROR: {
      return { ...state, loading: false };
    }
    case casesActionTypes.SET_CASES_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, ...action.payload } };
    }
    case casesActionTypes.DELETE_CASE: {
      return deleteFromListById(state, action.payload);
    }
    case RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default historyReducer;
