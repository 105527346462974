export const categoryActionTypes = {
  FETCH_CATEGORY: 'app/categories/FETCH_CATEGORY',
  FETCH_CATEGORY_SUCCESS: 'app/categories/FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_ERROR: 'app/categories/FETCH_CATEGORY_ERROR',

  FETCH_CATEGORY_ADD: 'app/categories/FETCH_CATEGORY_ADD',
  FETCH_CATEGORY_ADD_SUCCESS: 'app/categories/FETCH_CATEGORY_ADD_SUCCESS',
  FETCH_CATEGORY_ADD_ERROR: 'app/categories/FETCH_CATEGORY_ADD_ERROR',
  FETCH_CATEGORY_EDIT: 'app/categories/FETCH_CATEGORY_EDIT',
  FETCH_CATEGORY_EDIT_SUCCESS: 'app/categories/FETCH_CATEGORY_EDIT_SUCCESS',
  FETCH_CATEGORY_EDIT_ERROR: 'app/categories/FETCH_CATEGORY_EDIT_ERROR',
  FETCH_CATEGORY_DELETE: 'app/categories/FETCH_CATEGORY_DELETE',
  FETCH_CATEGORY_DELETE_SUCCESS: 'app/categories/FETCH_CATEGORY_DELETE_SUCCESS',
  FETCH_CATEGORY_DELETE_ERROR: 'app/categories/FETCH_CATEGORY_DELETE_ERROR',
  FETCH_CATEGORY_UPDATE_SEQUENCE: 'app/categories/FETCH_CATEGORY_UPDATE_SEQUENCE',
  FETCH_CATEGORY_UPDATE_SEQUENCE_SUCCESS: 'app/categories/FETCH_CATEGORY_UPDATE_SEQUENCE_SUCCESS',
  FETCH_CATEGORY_UPDATE_SEQUENCE_ERROR: 'app/categories/FETCH_CATEGORY_UPDATE_SEQUENCE_ERROR',
};

export const fetchCategory = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY,
  payload,
});

export const fetchCategorySuccess = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_SUCCESS,
  payload,
});

export const fetchCategoryError = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_ERROR,
  payload,
});

export const fetchCategoryAddSuccess = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_ADD_SUCCESS,
  payload,
});

export const fetchCategoryAddError = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_ADD_ERROR,
  payload,
});

export const fetchCategoryEditSuccess = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_EDIT_SUCCESS,
  payload,
});

export const fetchCategoryEditError = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_EDIT_ERROR,
  payload,
});

export const fetchCategoryDeleteSuccess = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_DELETE_SUCCESS,
  payload,
});

export const fetchCategoryDeleteError = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_DELETE_ERROR,
  payload,
});

export const fetchCategoryUpdateSequence = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE,
  payload,
});

export const fetchCategoryUpdateSequenceSuccess = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE_SUCCESS,
  payload,
});

export const fetchCategoryUpdateSequenceError = (payload) => ({
  type: categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE_ERROR,
  payload,
});
