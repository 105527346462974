import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AlertBar from '../alerts/AlertBar';
import { Container } from './style';
import Button from '../button';
import { routes } from '../../constants';
import Firebase from '../../firebase/Firebase';
import { ERRORS_COLLECTION } from '../../firebase/collections';

const collection = Firebase.db.collection(ERRORS_COLLECTION);

class ErrorBoundary extends Component {
  state = {
    error: '',
  };

  componentDidCatch(error, errorInfo) {
    this.sentErrorInformation(error.message, error.stack);
    this.setState({ error: error.message });
  }

  sentErrorInformation = (message = '', location = '', date = new Date()) => {
    if (process.env.NODE_ENV === 'production') {
      collection
        .add({ device: 'DESKTOP', message, location, date: date.toString() })
        .then(() => {})
        .catch(() => {});
    }
  };

  render() {
    if (this.state.error) {
      return (
        <Container>
          <AlertBar
            style={{ width: '100%' }}
            severity='error'
            title='Something went wrong'
            text={`The application was crashed.<br/>${this.state.error}. The information about error was sent to the Admin.`}
          />
          <Button
            style={{ maxWidth: 200 }}
            onClick={() => {
              this.setState({ error: '' });
              this.props.history.push(routes.HOME);
            }}
          >
            Go home
          </Button>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
