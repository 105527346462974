import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations';
import { getCachedLanguage } from '../storage/language';
import { AVAILABLE_LANGUAGES } from '../constants';

const defaultLanguage = AVAILABLE_LANGUAGES.includes(getCachedLanguage())
  ? getCachedLanguage()
  : 'en';

i18n.use(initReactI18next).init({
  resources: {
    ...Object.entries(translations).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          translation: value,
        },
      }),
      {}
    ),
  },
  lng: defaultLanguage,
  fallbackLng: 'en',
});

export default i18n;
