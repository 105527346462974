import React, { memo } from 'react';
import useModal from '../../../hooks/useModal';
import { APP_MODALS, APP_ROLES, ASSESSMENT_TYPES } from '../../../constants';
import Modal from '../../modal-wrapper/Modal';
import { Formik } from 'formik';
import { MakeAsyncFunction, promiseListener } from '../../../store/sagas';
import { casesActionTypes } from '../../../store/sagas/cases/actions';
import { useHistory } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import AddCaseForm from './components/AddCaseForm';
import useAsyncRedux from '../../../hooks/useAsyncRedux';
import { fetchProduct } from '../../../store/sagas/products/actions';
import { useTranslation } from 'react-i18next';

const AddCaseModal = ({ onSubmit }) => {
  const { isVisible, onHide } = useModal(APP_MODALS.ADD_CASE_MODAL);
  const history = useHistory();
  const user = useUser();
  const { t } = useTranslation();

  useAsyncRedux(fetchProduct);

  return (
    <Formik
      initialValues={{
        type: '',
        caseData: {
          caseDate: new Date(),
          caseNumber: '',
          patientId: '',
          surgeonId: user.role === APP_ROLES.SURGEON ? user.assignedSurgeon.id : '',
          hospitalId: '',
          procedureId: '',
          productId: [],
          procedureSide: '',
          surgeonName:
            user.role === APP_ROLES.SURGEON
              ? `${user.assignedSurgeon.title} ${user.assignedSurgeon.firstName} ${user.assignedSurgeon.lastName}`
              : '',
          hospitalName: '',
        },
        patientData: {
          DOB: null,
          sex: '',
          weight: '',
          height: '',
          smoking: '',
          BMI: '',
          measurementType: 'METRIC',
        },
        preOpData: {},
      }}
      validate={(values) => {
        const errors = {};
        const {
          caseData: { hospitalName, surgeonName, ...caseDataFields },
        } = values;

        Object.entries({ ...caseDataFields }).forEach(([key, value]) => {
          if (!value) {
            errors.caseData = { ...(errors.caseData || {}) };
            errors[key] = t('addCase.required');
          }
          if (Array.isArray(value) && value.length === 0) {
            errors.caseData = { ...(errors.caseData || {}) };
            errors[key] = t('addCase.required');
          }
        });

        switch (values.preOpData.assessmentType) {
          case ASSESSMENT_TYPES.KOOSJR: {
            if (!(values.preOpData.KOOSJR >= 0 && values.preOpData.KOOSJR <= 100)) {
              errors.preOpData = { KOOSJR: t('addCase.invalidValue') };
            }
            break;
          }
          case ASSESSMENT_TYPES.HOOSJR: {
            if (!(values.preOpData.HOOSJR >= 0 && values.preOpData.HOOSJR <= 100)) {
              errors.preOpData = { HOOSJR: t('addCase.invalidValue') };
            }
            break;
          }
        }

        return errors;
      }}
      validateOnMount={true}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit({ ...values, patientData: { ...values?.patientData, smoking: values?.smoking === 'yes' } })
          .then((response) => {
            onHide();
            history.push(`/cases/${response.id}`);
          })
          .finally(() => setSubmitting(false));
      }}
    >
      {(formik) => (
        <Modal
          open={isVisible}
          title={t('addCase.label')}
          onClose={onHide}
          isLoading={formik.isSubmitting}
          isSubmitting={!formik.isValid}
          onSubmit={formik.handleSubmit}
          onExited={formik.resetForm}
        >
          <AddCaseForm />
        </Modal>
      )}
    </Formik>
  );
};

const WrapMakeAsyncFunction = (Component) => (props) =>
  (
    <MakeAsyncFunction
      listener={promiseListener}
      start={casesActionTypes.FETCH_ADD_CASE}
      resolve={casesActionTypes.FETCH_ADD_CASE_SUCCESS}
      reject={casesActionTypes.FETCH_ADD_CASE_ERROR}
    >
      {(onSubmit) => <Component onSubmit={onSubmit} {...props} />}
    </MakeAsyncFunction>
  );

export default memo(WrapMakeAsyncFunction(AddCaseModal));
