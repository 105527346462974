import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

const StyledButton = styled(MuiButton)`
  min-width: 200px !important;
  min-height: 50px !important;
  border-radius: 25px !important;
  padding: 6px 48px !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
  &:active {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
  &:focus {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
`;

const Button = ({ children, ...restProps }) => {
  return (
    <StyledButton color='primary' variant='contained' {...restProps}>
      {children}
    </StyledButton>
  );
};

export default Button;
