import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../modal-wrapper/Modal';
import DialogContent from './DialogContent';

import { APP_MODALS } from '../../../constants';
import { hideModal } from '../../../store/actions/modalActions';
import useModal from '../../../hooks/useModal';

const SuccessSubmitModal = () => {
  const { modal } = useModal(APP_MODALS.SUCCESS_SUBMIT_MODAL);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(
      hideModal({
        modalName: APP_MODALS.SUCCESS_SUBMIT_MODAL,
      })
    );
  }, [dispatch]);

  return (
    <Modal
      open={modal.isVisible}
      onClose={handleClose}
      DialogContent={<DialogContent handleClose={handleClose} type={modal.data.value} />}
    />
  );
};

export default memo(SuccessSubmitModal);
