export const historyActionTypes = {
  FETCH_HISTORIES: 'app/history/FETCH_HISTORIES',
  FETCH_HISTORIES_SUCCESS: 'app/history/FETCH_HISTORIES_SUCCESS',
  FETCH_HISTORIES_ERROR: 'app/history/FETCH_HISTORIES_ERROR',
  SET_HISTORY_FILTER: 'app/history/SET_HISTORY_FILTER',
  SET_HISTORY_FILTER_SUCCESS: 'app/history/SET_HISTORY_FILTER_SUCCESS',
  RESET_HISTORY_FILTERS: 'app/history/RESET_HISTORY_FILTERS',
  DELETE_COMPLETED_CASE: 'app/history/DELETE_COMPLETED_CASE',
};

export const fetchHistories = () => ({
  type: historyActionTypes.FETCH_HISTORIES,
});

export const fetchHistoriesSuccess = (payload) => ({
  type: historyActionTypes.FETCH_HISTORIES_SUCCESS,
  payload,
});

export const fetchHistoriesError = (payload) => ({
  type: historyActionTypes.FETCH_HISTORIES_ERROR,
  payload,
});

export const setHistoryFilter = (payload, hasDelay = true) => ({
  type: historyActionTypes.SET_HISTORY_FILTER,
  payload,
  hasDelay,
});

export const setHistoryFilterSuccess = (payload) => ({
  type: historyActionTypes.SET_HISTORY_FILTER_SUCCESS,
  payload,
});

export const deleteCompletedCase = (payload) => ({
  type: historyActionTypes.DELETE_COMPLETED_CASE,
  payload,
});
