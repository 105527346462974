import createReduxPromiseListener from 'redux-promise-listener';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { all } from 'redux-saga/effects';
import {
  watchFetchAssignSalesRepSurgeons,
  watchFetchUserReset,
  watchFetchUserRole,
  watchFetchChangeUserCountry,
  watchFetchUsers,
  watchFetchAssignResearcherHospitals
} from './users/usersSaga';
import {
  watchFetchProcedure,
  watchFetchProcedureAdd,
  watchFetchProcedureDelete,
  watchFetchProcedureEdit,
  watchFetchProcedureUpdateSequence,
} from './procedures/proceduresSaga';
import {
  watchFetchHospital,
  watchFetchHospitalAdd,
  watchFetchHospitalDelete,
  watchFetchHospitalEdit,
} from './hospitals/hospitalsSaga';
import {
  watchFetchNews,
  watchFetchNewsAdd,
  watchFetchNewsDelete,
  watchFetchNewsEdit,
  watchFetchUpdateNewsSequence,
} from './news/newsSaga';
import {
  watchFetchAssignHospitals,
  watchFetchEditSurgeon,
  watchFetchSurgeonAdd,
  watchFetchSurgeonDelete,
  watchFetchSurgeons,
} from './surgeons/surgeonsSaga';
import {
  watchFetchProduct,
  watchFetchProductAdd,
  watchFetchProductDelete,
  watchFetchProductEdit,
  watchFetchForbiddenProduct,
} from './products/productSaga';
import {
  watchFetchCategory,
  watchFetchCategoryAdd,
  watchFetchCategoryDelete,
  watchFetchCategoryEdit,
  watchFetchCategoryUpdateSequence,
} from './categories/categorySaga';
import {
  watchFetchRewards,
  watchFetchUpdateTokens,
  watchFetchEditSurgeonTokens,
  watchFetchEditSalesRepTokens,
} from './rewards/rewardsSaga';
import { watchFetchChangeCountry, watchFetchCountries } from './countries/countriesSaga';
import { watchFetchHistories, watchSetHistoryFilter } from './history/historySaga';
import { watchFetchAddCase, watchFetchCases, watchSetCasesFilter } from './cases/casesSaga';
import { watchFetchCurrentUser } from './user/userSaga';
import { watchSetPMCFFilter, watchSetPMCFStages } from './pmcf/pmcfSaga';
import {
  watchFetchEmails,
  watchFetchAddEmail,
  watchDeleteEmail,
  watchFetchEditEmails,
} from './emails/emailsSaga';

const promiseListener = createReduxPromiseListener();

export { MakeAsyncFunction, promiseListener };

export default function* AppSagas() {
  yield all([
    watchFetchUserRole(),
    watchFetchAssignSalesRepSurgeons(),
    watchFetchAssignResearcherHospitals(),
    watchFetchProcedureAdd(),
    watchFetchProcedureEdit(),
    watchFetchHospital(),
    watchFetchHospitalAdd(),
    watchFetchHospitalEdit(),
    watchFetchNews(),
    watchFetchNewsAdd(),
    watchFetchNewsEdit(),
    watchFetchAssignHospitals(),
    watchFetchEditSurgeon(),
    watchFetchProcedureDelete(),
    watchFetchHospitalDelete(),
    watchFetchNewsDelete(),
    watchFetchUserReset(),
    watchFetchSurgeonDelete(),
    watchFetchSurgeonAdd(),
    watchFetchProcedure(),
    watchFetchProduct(),
    watchFetchProductAdd(),
    watchFetchProductEdit(),
    watchFetchProductDelete(),
    watchFetchForbiddenProduct(),
    watchFetchCategory(),
    watchFetchCategoryDelete(),
    watchFetchCategoryAdd(),
    watchFetchCategoryEdit(),
    watchFetchHistories(),
    watchSetHistoryFilter(),
    watchSetPMCFFilter(),
    watchSetPMCFStages(),
    watchFetchCases(),
    watchSetCasesFilter(),
    watchFetchRewards(),
    watchFetchUpdateTokens(),
    watchFetchCurrentUser(),
    watchFetchSurgeons(),
    watchFetchEditSurgeonTokens(),
    watchFetchEditSalesRepTokens(),
    watchFetchAddCase(),
    watchFetchChangeUserCountry(),
    watchFetchProcedureUpdateSequence(),
    watchFetchUpdateNewsSequence(),
    watchFetchCategoryUpdateSequence(),
    watchFetchUsers(),
    watchFetchCountries(),
    watchFetchChangeCountry(),
    watchFetchEmails(),
    watchFetchAddEmail(),
    watchDeleteEmail(),
    watchFetchEditEmails(),
  ]);
}
