export const emailsActionTypes = {
  FETCH_EMAILS: 'app/emails/FETCH_EMAILS',
  FETCH_EMAILS_SUCCESS: 'app/emails/FETCH_EMAILS_SUCCESS',
  FETCH_EMAILS_ERROR: 'app/emails/FETCH_EMAILS_ERROR',
  FETCH_EDIT_EMAILS: 'app/emails/FETCH_EDIT_EMAILS',
  FETCH_EDIT_EMAILS_SUCCESS: 'app/emails/FETCH_EDIT_EMAILS_SUCCESS',
  FETCH_EDIT_EMAILS_ERROR: 'app/emails/FETCH_EDIT_EMAILS_ERROR',
  FETCH_ADD_EMAIL: 'app/emails/FETCH_ADD_EMAIL',
  FETCH_ADD_EMAIL_SUCCESS: 'app/emails/FETCH_ADD_EMAIL_SUCCESS',
  FETCH_ADD_EMAIL_ERROR: 'app/emails/FETCH_ADD_EMAIL_ERROR',
  FETCH_DELETE_EMAIL: 'app/email/FETCH_DELETE_EMAIL',
  FETCH_DELETE_EMAIL_SUCCESS: 'app/email/FETCH_DELETE_EMAIL_SUCCESS',
  FETCH_DELETE_EMAIL_ERROR: 'app/email/FETCH_DELETE_EMAIL_ERROR',
};

export const fetchEmails = () => ({
  type: emailsActionTypes.FETCH_EMAILS,
});

export const fetchEmailsSuccess = (payload) => ({
  type: emailsActionTypes.FETCH_EMAILS_SUCCESS,
  payload,
});

export const fetchEmailsError = (payload) => ({
  type: emailsActionTypes.FETCH_EMAILS_ERROR,
  payload,
});
//edit
export const fetchEditEmail = (payload) => ({
  type: emailsActionTypes.FETCH_EDIT_EMAILS,
  payload,
});

export const fetchEditEmailSuccess = (payload) => ({
  type: emailsActionTypes.FETCH_EDIT_EMAILS_SUCCESS,
  payload,
});

export const fetchEditEmailError = (payload) => ({
  type: emailsActionTypes.FETCH_EDIT_EMAILS_ERROR,
  payload,
});
//add
export const fetchAddEmail = (payload) => ({
  type: emailsActionTypes.FETCH_ADD_EMAIL,
  payload,
});

export const fetchAddEmailSuccess = (payload) => ({
  type: emailsActionTypes.FETCH_ADD_EMAIL_SUCCESS,
  payload,
});

export const fetchAddEmailError = (payload) => ({
  type: emailsActionTypes.FETCH_ADD_EMAIL_ERROR,
  payload,
});
//delete
export const fetchDeleteEmail = (payload) => ({
  type: emailsActionTypes.FETCH_DELETE_EMAIL,
  payload,
});

export const fetchDeleteEmailSuccess = (payload) => ({
  type: emailsActionTypes.FETCH_DELETE_EMAIL_SUCCESS,
  payload,
});

export const fetchDeleteEmailError = (payload) => ({
  type: emailsActionTypes.FETCH_DELETE_EMAIL_ERROR,
  payload,
});
