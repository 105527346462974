import React, { useState } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import DatePicker from '../date-picker';

const FormikDatePicker = ({ name, type, label, value, onChange, controlled, defaultDate = new Date(), ...restProps }) => {
  const [field, meta, helpers] = useField(name);
  const [isOpened, setIsOpened] = useState(false);

  const handleOpen = () => {
    if (!field.value && defaultDate) {
      helpers.setValue(defaultDate);
      setTimeout(() => setIsOpened(true), 100);
    } else {
      setIsOpened(true);
    }
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <DatePicker
      name={name}
      type={type}
      {...restProps}
      {...field}
      onChange={(date) => helpers.setValue(date)}
      label={label}
      handleOpen={handleOpen}
      handleClose={handleClose}
      isOpened={isOpened}
      controlled={controlled}
    />
  );
};

FormikDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikDatePicker;
