import {
  LOAD_REWARDS,
  RECEIVE_REWARDS,
  EDIT_BEER_TOKEN_REWARDS,
  UPDATE_USERS_TOKENS,
} from '../actions/actionTypes';
import { rewardsActionTypes } from '../sagas/rewards/actions';
import { RESET_STATE } from './index';

const initialState = {
  isLoaded: false,
  list: [],
};

const rewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REWARDS:
      return {
        ...state,
        isLoaded: true,
      };
    case RECEIVE_REWARDS: {
      return {
        ...state,
        isLoaded: false,
        list: action.value,
      };
    }
    case rewardsActionTypes.FETCH_REWARDS_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case rewardsActionTypes.FETCH_UPDATE_TOKENS_SUCCESS: {
      const { data, id } = action.payload;
      const list = [...state.list];
      const index = list.findIndex((item) => item.rewardListInfo.id === id);

      if (index === -1) {
        return state;
      }

      list[index].beerTokens = {
        caseAutoCompleted: data.caseAutoCompleted,
        tokensPerDays: data.tokensPerDays,
      };
      return {
        ...state,
        list,
      };
    }
    case RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default rewardReducer;
