import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '../store/actions/modalActions';

const useModal = (name) => {
  const modal = useSelector((state) => state.modal[name]);
  const dispatch = useDispatch();

  const onShow = useCallback(
    (props = {}) => {
      dispatch(
        showModal({
          modalName: name,
          ...props,
        })
      );
    },
    [name, dispatch]
  );

  const onHide = useCallback(
    (props = {}) => {
      dispatch(
        hideModal({
          modalName: name,
          ...props,
        })
      );
    },
    [name, dispatch]
  );

  return {
    isVisible: modal.isVisible,
    onShow,
    onHide,
    modal,
  };
};

export default useModal;
