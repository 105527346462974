export const surgeonsActionTypes = {
  FETCH_SURGEONS: 'app/surgeons/FETCH_SURGEONS',
  FETCH_SURGEONS_SUCCESS: 'app/surgeons/FETCH_SURGEONS_SUCCESS',
  FETCH_SURGEONS_ERROR: 'app/surgeons/FETCH_SURGEONS_ERROR',
  FETCH_ASSIGN_SURGEON_USER: 'app/surgeons/FETCH_ASSIGN_SURGEON_USER',
  FETCH_ASSIGN_HOSPITALS: 'app/surgeons/FETCH_ASSIGN_HOSPITALS',
  FETCH_ASSIGN_HOSPITALS_SUCCESS: 'app/surgeons/FETCH_ASSIGN_HOSPITALS_SUCCESS',
  FETCH_ASSIGN_HOSPITALS_ERROR: 'app/surgeons/FETCH_ASSIGN_HOSPITALS_ERROR',
  FETCH_EDIT_SURGEON: 'app/surgeons/FETCH_EDIT_SURGEON',
  FETCH_EDIT_SURGEON_SUCCESS: 'app/surgeons/FETCH_EDIT_SURGEON_SUCCESS',
  FETCH_EDIT_SURGEON_ERROR: 'app/surgeons/FETCH_EDIT_SURGEON_ERROR',
  FETCH_DELETE_SURGEON: 'app/surgeons/FETCH_DELETE_SURGEON',
  FETCH_DELETE_SURGEON_SUCCESS: 'app/surgeons/FETCH_DELETE_SURGEON_SUCCESS',
  FETCH_DELETE_SURGEON_ERROR: 'app/surgeons/FETCH_DELETE_SURGEON_ERROR',
  FETCH_SURGEON_ADD: 'app/surgeons/FETCH_SURGEON_ADD',
  FETCH_SURGEON_ADD_SUCCESS: 'app/surgeons/FETCH_SURGEON_ADD_SUCCESS',
  FETCH_SURGEON_ADD_ERROR: 'app/surgeons/FETCH_SURGEON_ADD_ERROR',
};

export const fetchSurgeons = () => ({
  type: surgeonsActionTypes.FETCH_SURGEONS,
});

export const fetchSurgeonsSuccess = (payload) => ({
  type: surgeonsActionTypes.FETCH_SURGEONS_SUCCESS,
  payload,
});

export const fetchSurgeonsError = (payload) => ({
  type: surgeonsActionTypes.FETCH_SURGEONS_ERROR,
  payload,
});

export const fetchAssignHospitalsSuccess = (payload) => ({
  type: surgeonsActionTypes.FETCH_ASSIGN_HOSPITALS_SUCCESS,
  payload,
});

export const fetchAssignHospitalsError = (payload) => ({
  type: surgeonsActionTypes.FETCH_ASSIGN_HOSPITALS_ERROR,
  payload,
});

export const fetchEditSurgeonSuccess = (payload) => ({
  type: surgeonsActionTypes.FETCH_EDIT_SURGEON_SUCCESS,
  payload,
});

export const fetchEditSurgeonError = (payload) => ({
  type: surgeonsActionTypes.FETCH_EDIT_SURGEON_ERROR,
  payload,
});

export const fetchSurgeonDeleteSuccess = (payload) => ({
  type: surgeonsActionTypes.FETCH_DELETE_SURGEON_SUCCESS,
  payload,
});

export const fetchSurgeonDeleteError = (payload) => ({
  type: surgeonsActionTypes.FETCH_DELETE_SURGEON_ERROR,
  payload,
});

export const fetchSurgeonAddSuccess = (payload) => ({
  type: surgeonsActionTypes.FETCH_SURGEON_ADD_SUCCESS,
  payload,
});

export const fetchSurgeonAddError = (payload) => ({
  type: surgeonsActionTypes.FETCH_SURGEON_ADD_ERROR,
  payload,
});
