import { takeEvery, takeLatest, put, call, delay, select } from 'redux-saga/effects';
import {
  fetchHistoriesSuccess,
  fetchHistoriesError,
  historyActionTypes,
  setHistoryFilterSuccess,
} from './actions';
import { requestFetchHistory } from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchHistories() {
  yield takeEvery(historyActionTypes.FETCH_HISTORIES, doFetchHistories);
}

export function* watchSetHistoryFilter() {
  yield takeLatest(historyActionTypes.SET_HISTORY_FILTER, doSetHistoryFilter);
}

function* doFetchHistories() {
  try {
    const {
      user: { currentUser },
    } = yield select();
    const response = yield call(requestFetchHistory, currentUser);

    yield put(fetchHistoriesSuccess(response));
  } catch (error) {
    yield put(fetchHistoriesError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doSetHistoryFilter(action) {
  const { payload, hasDelay } = action;

  if (hasDelay) {
    yield delay(500);
  }

  yield put(setHistoryFilterSuccess(payload));
}
