import { takeEvery, takeLatest, put, call, delay, select } from 'redux-saga/effects';
import {
  fetchCasesSuccess,
  fetchCasesError,
  setCasesFilterSuccess,
  casesActionTypes,
  fetchAddCaseError,
  fetchAddCaseSuccess,
} from './actions';
import { requestFetchAddCase, requestFetchCases, sendCaseToEmails } from './requests';
import { showAlert } from '../alerts/actions';
import Firebase from '../../../firebase/Firebase';
import { HOSPITALS_COLLECTION, SURGEONS_COLLECTION } from '../../../firebase/collections';
import { APP_ROLES, EMAIL_TYPE, MEASUREMENT_TYPE } from '../../../constants';
import { getAssessmentTypeKey, getBMI, getCurrentMeasure } from '../../../util/functions';
import { requestFetchProduct } from '../products/requests';
import { requestsFetchEmails } from '../emails/requests';
import { format } from 'date-fns';
import instance from '../../../firebase/Firebase';

export function* watchFetchCases() {
  yield takeEvery(casesActionTypes.FETCH_CASES, doFetchCases);
}

export function* watchSetCasesFilter() {
  yield takeLatest(casesActionTypes.SET_CASES_FILTER, doSetCasesFilter);
}

export function* watchFetchAddCase() {
  yield takeEvery(casesActionTypes.FETCH_ADD_CASE, doFetchAddCase);
}

function* doFetchCases() {
  try {
    const {
      user: { currentUser },
    } = yield select();
    const response = yield call(requestFetchCases, currentUser);

    yield put(fetchCasesSuccess(response));
  } catch (error) {
    yield put(fetchCasesError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doSetCasesFilter(action) {
  const { payload, hasDelay } = action;

  if (hasDelay) {
    yield delay(500);
  }

  yield put(setCasesFilterSuccess(payload));
}

function* doFetchAddCase(action) {
  try {
    const {
      user: { currentUser },
    } = yield select();
    const {
      caseData,
      preOpData,
      preOpData: { assessmentType, selectAssessmentType, ...restPreOpData },
      patientData,
    } = action.payload;
    const {
      countries: { selected },
      user: {
        currentUser: { firstName, lastName, uid, role },
      },
      hospitals: { list: hospitalList },
      surgeons: { list: surgeonList },
    } = yield select();
    const hospitalNameIndex = hospitalList.findIndex((item) => item.id === caseData.hospitalId);
    const surgeonNameIndex = surgeonList.findIndex((item) => item.id === caseData.surgeonId);
    const hospitalName = hospitalNameIndex !== -1 ? hospitalList[hospitalNameIndex].name : '';
    let { height, weight, inches, feet, measurementType, ...restPatientData } = patientData;

    const surgeonName =
      role !== APP_ROLES.SURGEON
        ? surgeonNameIndex !== -1
          ? `${surgeonList[surgeonNameIndex].title} ${surgeonList[surgeonNameIndex].firstName} ${surgeonList[surgeonNameIndex].lastName}`
          : ''
        : caseData.surgeonName;

    if (feet && !inches) {
      inches = 0;
    }

    if (inches !== '' && !feet) {
      feet = 0;
    }

    if (measurementType === MEASUREMENT_TYPE.IMPERIAL) {
      height = feet !== '' && inches !== '' ? `${feet}.${inches}` : '';
    }

    const country = currentUser.country || currentUser?.assignedSurgeon?.country || selected;

    const requestData = {
      caseData: {
        ...caseData,
        surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${caseData.surgeonId}`),
        hospital: Firebase.db.doc(`${HOSPITALS_COLLECTION}/${caseData.hospitalId}`),
        caseDate: caseData.caseDate.toString(),
        hospitalName,
        surgeonName,
      },
      patientData: {
        ...restPatientData,
        DOB: patientData.DOB ? patientData.DOB.toString() : null,
        BMI: patientData.BMI
          ? patientData.BMI
          : getBMI(measurementType, {
              height,
              weight,
              inches,
              feet,
            }),
        ...getCurrentMeasure(measurementType, height, weight, feet, inches),
      },
      preOpData: { ...restPreOpData, selectAssessmentType: getAssessmentTypeKey(assessmentType) },
      caseOwnerName: `${firstName} ${lastName}`,
      caseOwner: uid,
      surgeonId: caseData.surgeonId,
      scans: [],
      images: [],
      xRays: [],
      notes: [],
      pmcf: true,
      usageApproved: 'No',
      status: 'OPEN',
      attachedImage: false,
      PO_Number: '',
      completingDate: '',
      pendingDate: '',
      country,
      pmcfStages: {
        stageA: false,
        stageB: false,
        stageC: false,
        stageADate: null,
        stageBDate: null,
        stageCDate: null,
      },
    };

    const formatCaseDate = format(new Date(caseData.caseDate), 'd/MM/yyyy');

    const productList = yield call(requestFetchProduct);
    const products = productList
      .filter(({ id }) => caseData.productId.includes(id))
      .map((item) => item.name)
      .join(', ');

    const emailList = yield call(requestsFetchEmails);
    const emails = emailList
      .filter((item) => item.type === EMAIL_TYPE.NEW_CASE && item.country === country)
      .map(({ email }) => email);

    const currentCasesCount = yield call(instance.getNextCaseId);
    yield call(instance.updateNextCaseId, currentCasesCount);
    const response = yield call(requestFetchAddCase, {
      data: requestData,
      id: instance.generateNextCaseId(currentCasesCount),
    });
    yield put(fetchAddCaseSuccess({ ...requestData, id: response }));
    yield put(showAlert({ message: 'Case was successfully created', type: 'success' }));
    yield call(
      sendCaseToEmails,
      response,
      preOpData.type,
      formatCaseDate,
      hospitalName,
      surgeonName,
      caseData.procedureSide,
      caseData.patientId,
      emails,
      products
    );
  } catch (error) {
    yield put(fetchAddCaseError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}
