const routes = {
  HOME: '/',
  SIGN_IN: '/',
  SIGN_UP: '/sign-up',
  CASES: '/cases',
  CASE_INFO: '/cases/:id',
  ADD_CASE: '/add-case',
  ANALYTICS: '/analytics',
  HISTORY: '/history',
  PRODUCTS: '/products',
  SETTINGS: '/settings',
  PMCF: '/pmcf',
  SETTINGS_GLOBAL: '/settings/global',
  SETTINGS_COUNTRY: '/settings/country',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_REWARDS: '/settings/rewards',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  RESET_PASSWORD: '/reset-password',
  ADMIN: '/admin',
  REWARDS: '/rewards',
  ADMIN_GLOBAL: '/admin/global',
  ADMIN_COUNTRY: '/admin/country',
  ADMIN_USER: '/admin/user',
};

export default routes;
