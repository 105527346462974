import React from 'react';
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StyledSelect, Container, Label, IconContainer, ErrorLabel, HelperText } from './style';

const Select = ({
  label,
  emptyValue = '',
  error = '',
  options = [],
  value,
  Icon,
  helperText,
  multiple = false,
  ...restProps
}) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <StyledSelect
        multiple={multiple}
        value={value}
        variant='outlined'
        IconComponent={ExpandMoreIcon}
        renderValue={
          multiple
            ? (option) => {
                return option.join(', ');
              }
            : undefined
        }
        {...restProps}
      >
        <MenuItem disabled={multiple} key='empty-value' value={emptyValue}>
          <em>{label ? `Choose ${label.toLowerCase()}` : 'Choose item'}</em>
        </MenuItem>
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {multiple && <Checkbox color='primary' checked={value.indexOf(item.value) > -1} />}
            {item.label}
          </MenuItem>
        ))}
        {options.length === 0 && (
          <MenuItem disabled key='no-items' value='no-item'>
            No items
          </MenuItem>
        )}
      </StyledSelect>
      {helperText && <HelperText>{helperText}</HelperText>}
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </Container>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }))
    .isRequired,
};

export default Select;
