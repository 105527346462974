import React, { memo } from 'react';

import styled, { keyframes } from 'styled-components';
import AppLogo from '../../app-logo/AppLogo';
import { zoomIn, bounceIn, pulse } from 'react-animations';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const zoomInAnimation = keyframes`${pulse}`;

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(227, 230, 233, 0.52);
  z-index: 10000;
`;

const StyledLogo = styled(AppLogo)`
  width: 200px;
  margin-bottom: 12px;
  animation: 1s ${zoomInAnimation};
  animation-iteration-count: infinite;
`;

const LoadScreen = () => {
  return (
    <Container>
      <StyledLogo />
      <LinearProgress style={{ width: 200 }} color='primary' />
    </Container>
  );
};

export default memo(LoadScreen);
