import { TOGGLE_SIDEBAR, CLOSE_SIDEBAR } from '../actions/actionTypes';

const initialState = {
  mobileOpen: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, mobileOpen: !state.mobileOpen };
    case CLOSE_SIDEBAR: {
      return { ...state, mobileOpen: false };
    }
    default:
      return state;
  }
};

export default sidebarReducer;
