import { takeEvery, call, put, select } from 'redux-saga/effects';
import { APP_ROLES } from '../../../constants';
import {
  updateUserRole,
  sendUserEmail,
  assignSalesRepSurgeons,
  requestResetUserData,
  requestChangeUserCountry,
  requestFetchUsers, assignResearcherHospitals,
} from './requests';
import {
  fetchAssignResearcherHospitalsError,
  fetchAssignResearcherHospitalsSuccess,
  fetchAssignSalesRepSurgeonsError,
  fetchAssignSalesRepSurgeonsSuccess,
  fetchSaveUsers,
  fetchSetUserRoleError,
  fetchSetUserRoleSuccess,
  fetchUserChangeCountrySuccess,
  fetchUserResetError,
  fetchUserResetSuccess,
  fetchUsersError,
  fetchUsersSuccess,
  usersActionTypes,
} from './actions';
import { showAlert } from '../alerts/actions';
import { mergeArrayObjectsByField } from '../../../util/utils';

export function* watchFetchUserRole() {
  yield takeEvery(usersActionTypes.FETCH_SET_USER_ROLE, doFetchUserRole);
}

export function* watchFetchAssignSalesRepSurgeons() {
  yield takeEvery(usersActionTypes.FETCH_ASSIGN_SALES_REP_SURGEONS, doFetchAssignSalesRepSurgeons);
}

export function* watchFetchAssignResearcherHospitals() {
  yield takeEvery(usersActionTypes.FETCH_ASSIGN_RESEARCHER_HOSPITALS, doFetchAssignResearcherHospitals);
}

export function* watchFetchUserReset() {
  yield takeEvery(usersActionTypes.FETCH_USER_RESET, doFetchUserReset);
}

export function* watchFetchChangeUserCountry() {
  yield takeEvery(usersActionTypes.FETCH_USER_CHANGE_COUNTRY, doFetchChangeUserCountry);
}

export function* watchFetchUsers() {
  yield takeEvery(usersActionTypes.FETCH_USERS, doFetchUsers);
}

export function* doFetchUserRole(action) {
  try {
    const { payload } = action;
    const { uid, email, surgeon, role } = payload;
    const {
      countries: { selected: country },
    } = yield select();

    switch (role) {
      case APP_ROLES.ADMIN: {
        yield call(updateUserRole, { uid, role: APP_ROLES.ADMIN });
        break;
      }
      case APP_ROLES.REGULATORY: {
        yield call(updateUserRole, { uid, role: APP_ROLES.REGULATORY });
        break;
      }
      case APP_ROLES.SURGEON: {
        if (surgeon.assignedUser.uid) {
          yield call(requestResetUserData, surgeon.assignedUser.uid);
        }

        yield call(updateUserRole, { uid, role: APP_ROLES.SURGEON, surgeonId: surgeon.id });
        break;
      }
      case APP_ROLES.SALES_REP: {
        yield call(updateUserRole, {
          uid,
          country,
          role: APP_ROLES.SALES_REP,
          beerTokens: 0,
          assignedSurgeons: [],
        });
        break;
      }
      case APP_ROLES.RESEARCHER: {
        yield call(updateUserRole, {
          uid,
          country,
          role: APP_ROLES.RESEARCHER,
          beerTokens: 0,
          assignedSurgeons: [],
        });
        break;
      }
      case APP_ROLES.LOGISTIC: {
        yield call(updateUserRole, {
          uid,
          country,
          role: APP_ROLES.LOGISTIC,
        });
        break;
      }
    }
    yield call(sendUserEmail, email);
    yield put(fetchSetUserRoleSuccess({ ...payload, country }));
    yield put(showAlert({ message: "User's role was successfully updated" }));
  } catch (error) {
    yield put(fetchSetUserRoleError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

export function* doFetchAssignSalesRepSurgeons(action) {
  try {
    yield call(assignSalesRepSurgeons, action.payload);
    yield put(fetchAssignSalesRepSurgeonsSuccess(action.payload));
    yield put(showAlert({ message: "Sales rep's assigned surgeons were successfully updated" }));
  } catch (error) {
    yield put(fetchAssignSalesRepSurgeonsError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

export function* doFetchAssignResearcherHospitals(action) {
  try {
    yield call(assignResearcherHospitals, action.payload);
    yield put(fetchAssignResearcherHospitalsSuccess(action.payload));
    yield put(showAlert({ message: "Researcher's hospitals were successfully updated" }));
  } catch (error) {
    yield put(fetchAssignResearcherHospitalsError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

export function* doFetchUserReset(action) {
  try {
    const id = action.payload.id || action.payload;

    yield call(requestResetUserData, id);
    yield put(fetchUserResetSuccess(id));
  } catch (error) {
    yield put(fetchUserResetError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

export function* doFetchChangeUserCountry(action) {
  try {
    const { id, country } = action.payload;

    yield call(requestChangeUserCountry, { id, country });
    yield put(fetchUserChangeCountrySuccess({ id, country }));
    yield put(showAlert({ message: "User's country was successfully changed" }));
  } catch (error) {
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

export function* doFetchUsers() {
  try {
    const [role, data] = yield call(requestFetchUsers);

    yield put(fetchUsersSuccess(mergeArrayObjectsByField('uid', [role, data])));
  } catch (error) {
    yield put(fetchUsersError(error));
  }
}
