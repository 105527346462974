import styled from 'styled-components';
import { Select } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 8px !important;

  .MuiSelect-icon {
    width: 32px !important;
    height: 32px !important;
    color: #00a157 !important;
    top: calc(50% - 16px);
  }

  .MuiSelect-select:focus {
    background-color: transparent !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
`;

export const Label = styled.span`
  margin-bottom: 8px;
  font-weight: 600;
  opacity: 0.8;
`;

export const IconContainer = styled.div`
  pointer-events: none;
  z-index: 10;
  position: absolute;
  left: 12px;
  top: 12px;
  width: 20px;
`;

export const ErrorLabel = styled.span`
  color: #f44336;
`;

export const HelperText = styled.span`
  color: rgba(0, 0, 0, 0.23);
`;
