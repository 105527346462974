import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 4,
    right: 32,
    fontSize: 14,
    color: '#7a7a7a',
    opacity: 0.8,
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
}));

const BuildNumber = () => {
  const classes = useStyles();

  return <span className={classes.root}>scout Web V{process.env.REACT_APP_VERSION}</span>;
};

export default BuildNumber;
