import React, { memo, useMemo } from 'react';

import PreOpDataIBF from '../procedures/PreOpDataIBF';
import PreOpDataTHR from '../procedures/PreOpDataTHR';
import PreOpDataTKR from '../procedures/PreOpDataTKR';

import { PRE_OP_DATA_TYPES } from '../../../../../constants';
import { ButtonRow, FormColumn } from '../../style';
import { Button } from '@material-ui/core';
import PreOpDataAny from '../procedures/PreOpDataAny';
import { connect } from 'formik';
import { memoizedFormikField } from '../../../../../util/functions';
import { useTranslation } from 'react-i18next';

const PreOpDataStep = ({
  formik: {
    values: { type },
  },
  handleBackStep,
}) => {
  const { t } = useTranslation();

  const ActivePreOpData = useMemo(() => {
    switch (type) {
      case PRE_OP_DATA_TYPES.THR:
        return <PreOpDataTHR />;
      case PRE_OP_DATA_TYPES.TKR:
        return <PreOpDataTKR />;
      case PRE_OP_DATA_TYPES.IBF:
        return <PreOpDataIBF />;
      default:
        return <PreOpDataAny />;
    }
  }, [type]);

  return (
    <FormColumn>
      {ActivePreOpData}
      <ButtonRow noPadding>
        <Button fullWidth onClick={handleBackStep} variant='outlined'>
          {t('buttons.back')}
        </Button>
      </ButtonRow>
    </FormColumn>
  );
};

export default connect(memo(PreOpDataStep, memoizedFormikField(['type'])));
