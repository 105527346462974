import React, { useMemo } from 'react';

import FormikSelect from '../../../../formik/FormikSelect';
import FormikFastInput from '../../../../formik/FormikFastInput';
import { useTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';

const letterWithInteger = (letter, count) => {
  const arr = [];

  for (let i = 1; i <= count; i++) arr.push(`${letter}${i}`);
  return arr;
};

const PreOpDataIBF = () => {
  const { t } = useTranslation();
  const primaryDiagnosis = useMemo(
    () =>
      [
        'SPONDYLOLISTHESIS',
        'SCOLIOSIS',
        'DISC_PATHOLOGY',
        'INSTABILTY',
        'STENOSIS',
        'POSTDISCECTOMY_REVISION',
        'AVN',
        'Infection',
      ].map((item) => ({
        label: capitalCase(t(`IBF.${item}`)),
        value: item.replaceAll('_', ' '),
      })),
    [t]
  );

  const comorbidConditions = useMemo(
    () =>
      [
        'OSTEOARTHRITIS',
        'HEADACHES',
        'DEPRESSION',
        'INCREASED_BMI',
        'DIABETES',
        'STOMACH_DISORDERS',
        'OSTEOPOROSIS',
        'BLOOD_DISORDERS',
        'RHEUMATOID_ARTHRITIS',
        'CIRCULATORY_DISORDERS',
        'ENDOCRINE_DISORDERS',
        'HYPERTENSION',
        'CARDIAC_DISORDERS',
        'CANCER',
        'NERVOUS_SYSTEM_DISORDERS',
        'SMOKING',
        'LUNG_DISORDERS',
      ].map((item) => ({
        label: capitalCase(t(`IBF.${item}`)),
        value: item.replaceAll('_', ' '),
      })),
    [t]
  );

  const surgicalApproach = useMemo(
    () =>
      ['ALIF', 'OLIF', 'DLIF', 'TLIF', 'PLIF', 'ACDF', 'Posterior Fixation'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );

  const operatedLevels = useMemo(
    () =>
      [
        ...letterWithInteger('C', 8),
        ...letterWithInteger('T', 12),
        ...letterWithInteger('L', 5),
        ...letterWithInteger('S', 1),
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );

  return (
    <>
      <FormikSelect
        label={t('IBF.primaryDiagnosis')}
        placeholder='Select Primary Diagnosis'
        name='preOpData.primaryDiagnosis'
        options={primaryDiagnosis}
      />
      <FormikSelect
        label={t('IBF.comorbidConditions')}
        placeholder='Select Comorbid Conditions'
        name='preOpData.comorbidConditions'
        options={comorbidConditions}
        multiple
      />
      <FormikFastInput label={t('IBF.vasBack')} name='preOpData.vasBack' />

      <FormikFastInput label={t('IBF.vasLeftLeg')} name='preOpData.vasLeftLeg' />
      <FormikFastInput label={t('IBF.vasRightLeg')} name='preOpData.vasRightLeg' />

      <FormikFastInput label={t('IBF.ODI')} name='preOpData.ODI' />
      <FormikFastInput label={t('IBF.SF8')} name='preOpData.SF8' />
      <FormikFastInput label={t('IBF.RMDQ')} name='preOpData.RMDQ' />
      <FormikSelect
        label={t('IBF.surgicalAproach')}
        placeholder='Select Surgical Approach'
        name='preOpData.surgicalApproach'
        options={surgicalApproach}
      />
      <FormikSelect
        label={t('IBF.operatedLevels')}
        placeholder='Select Operated Levels'
        name='preOpData.operatedLevels'
        options={operatedLevels}
        multiple
      />
    </>
  );
};

export default PreOpDataIBF;
