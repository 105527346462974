import React, { useState, memo, useMemo } from 'react';

import Button from '../../button';
import PromisModal from './PromisModal';
import { connect } from 'formik';

import { makeStyles } from '@material-ui/core';
import { calculatePromis } from './utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'rgba(0, 0, 0, 0.68)',
    fontWeight: '600',
    marginBottom: 19,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 8,
  },
  button: {
    height: 50,
  },
  healthInfo: {
    fontSize: 18,
    fontWeight: 600,
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  healthValue: {
    color: '#00a157',
    marginLeft: theme.spacing(1),
  },
}));

const PromisActions = ({
  isFollowUp,
  formik: {
    values,
    values: {
      followUp,
      preOpData: { promis10 },
    },
  },
}) => {
  const classes = useStyles();
  const [activeModal, setActiveModal] = useState(false);
  const { t } = useTranslation();

  const health = useMemo(() => {
    if (isFollowUp) {
      return calculatePromis(followUp?.PSI.promis10);
    } else {
      return calculatePromis(promis10);
    }
  }, [promis10, followUp?.PSI.promis10]);

  const physicalScore = health.length > 0 ? health[0].value : 0;
  const mentalScore = health.length > 0 ? health[1].value : 0;

  const toggleModal = () => {
    setActiveModal((prevValue) => !prevValue);
  };

  return (
    <>
      <div>
        <span className={classes.label}>{t('promis.label')}</span>
        <div className={classes.wrapper}>
          <div>
            <div className={classes.healthInfo}>
              <span>{t('promis.physHealth')}</span>
              <label className={classes.healthValue}>{physicalScore ? physicalScore : 'N/A'}</label>
            </div>
            <div className={classes.healthInfo}>
              <span>{t('promis.mentalHealth')}</span>
              <label className={classes.healthValue}>{mentalScore ? mentalScore : 'N/A'}</label>
            </div>
          </div>
          <Button onClick={toggleModal} className={classes.button}>
            {t('buttons.survey')}
          </Button>
        </div>
        <PromisModal isFollowUp={isFollowUp} open={activeModal} onClose={toggleModal} />
      </div>
    </>
  );
};

export default connect(memo(PromisActions));
