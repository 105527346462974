import Firebase from '../../../firebase';
import { CASES_COLLECTION } from '../../../firebase/collections';
import { APP_ROLES } from '../../../constants';
import { cloudFunctionConfig } from '../../../config';

const collection = Firebase.db.collection(CASES_COLLECTION);

export const sendUpdateCaseToEmails = (values) => {
  fetch(`${cloudFunctionConfig}/sendEmail/send-email-update-case`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
    .then(() => console.log('sent'))
    .catch((e) => console.log(e));
};

export const sendCaseToEmails = (
  caseId,
  type,
  caseDate,
  hospitalName,
  surgeonName,
  procedureSide,
  patientId,
  emails,
  products
) => {
  fetch(`${cloudFunctionConfig}/sendEmail/send-email-create-case`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      caseId,
      type,
      caseDate,
      hospitalName,
      surgeonName,
      procedureSide,
      patientId,
      emails,
      products,
    }),
  })
    .then(() => console.log('sent'))
    .catch((e) => console.log(e));
};

export const requestFetchCases = async (user) => {
  const { role } = user;
  const activeCases = collection.where('status', '!=', 'COMPLETED');

  switch (role) {
    case APP_ROLES.REGULATORY:
    case APP_ROLES.ADMIN: {
      return await receiveCases(activeCases);
    }
    case APP_ROLES.SURGEON: {
      return await receiveCases(activeCases.where('surgeonId', '==', user.assignedSurgeon.id));
    }
    case APP_ROLES.RESEARCHER:
      const result = [];
      const surgeonCases = await Promise.all(
        user.assignedSurgeons?.map((item) => {
          return receiveCases(activeCases.where('surgeonId', '==', item.id));
        })
      );
      const hospitalCases = await Promise.all(
        user.hospitals?.map((item) => {
          return receiveCases(activeCases.where('caseData.hospitalId', '==', item.id));
        })
      );

      surgeonCases?.forEach((arr) => {
        arr?.forEach((item) => {
          result.push(item);
        })
      })
      hospitalCases?.forEach((arr) => {
        arr?.forEach((item) => {
          if (result.findIndex((i) => i.id === item.id) === -1) {
            result.push(item)
          }
        })
      })

      return result;
    case APP_ROLES.SALES_REP: {
      const surgeonCases = await Promise.all(
        user.assignedSurgeons.map((item) => {
          return receiveCases(activeCases.where('surgeonId', '==', item.id));
        })
      );

      return surgeonCases.reduce((acc, item) => [...acc, ...item], []);
    }
    case APP_ROLES.LOGISTIC: {
      return await receiveCases(activeCases.where('country', '==', user.country));
    }
    default:
      return [];
  }
};

export const receiveCases = async (promise) =>
  await promise
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((response) => response);

export const requestFetchAddCase = async ({ data, id }) => {
  return collection
    .doc(id)
    .set(data)
    .then(() => id);
};
