import React from 'react';
import { connect } from 'formik';
import Input from '../../../input';
import { getBMI } from '../../../../util/functions';
import { useTranslation } from 'react-i18next';

const BmiCalculate = ({ formik }) => {
  const {
    values: {
      patientData: { height, weight, inches, feet, measurementType },
    },
  } = formik;

  const { t } = useTranslation();

  return (
    <Input
      label={t('addCase.steps.patientData.calculatedBMILabel')}
      type='number'
      value={
        getBMI(measurementType, {
          height,
          weight,
          inches,
          feet,
        }) || ''
      }
      disabled
    />
  );
};

export default connect(BmiCalculate);
