import React, { memo, useState, useMemo, useEffect } from 'react';

import Modal from '../../modal-wrapper/Modal';
import ProcedureShared from '../../procedure-shared/ProcedureShared';

import { makeStyles, Slider } from '@material-ui/core';
import { connect } from 'formik';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  description: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  button: {
    height: 50,
    marginLeft: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
  intervalScore: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  scoreItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  scoreLabel: {
    fontSize: 20,
    fontWeight: 700,
    color: '#01a157',
  },
  score: {
    fontSize: 40,
    fontWeight: 800,
    color: '#01a157',
  },
  textContainer: {
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: 600,
  },
}));

const PromisModal = ({
  isFollowUp,
  open,
  onClose,
  formik: {
    setFieldValue,
    values: {
      followUp,
      preOpData: { promis10 },
    },
  },
}) => {
  const classes = useStyles();
  const getInitialPoints = () => {
    if (isFollowUp) {
      const promis10 = followUp.PSI.promis10;

      return followUp.PSI.promis10 && followUp.PSI.promis10.length !== 0 ? [...promis10] : [];
    } else {
      return promis10 && promis10.length !== 0 ? [...promis10] : [];
    }
  };
  const initialPoints = getInitialPoints();

  const [sliderValue, setSliderValue] = useState(0);
  const [points, setPoints] = useState(initialPoints);
  const [mentalScore, setMentalScore] = useState(0);
  const [physicalScore, setPhysicalScore] = useState(0);
  const { t } = useTranslation();

  const scores = useMemo(() => [20, 15, 10, 5, 0], []);

  const getPainRate = (value) => {
    const x = value;
    switch (true) {
      case x === 0:
        return 0;
      case x <= 3:
        return 1;
      case x <= 6:
        return 2;
      case x <= 9:
        return 3;
      case x <= 10:
        return 4;
      default:
        return 0;
    }
  };

  const changeScore = (index, value) => {
    const newPoints = points.length === 0 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [...points];
    newPoints[index] = value;

    const mentalValues = [newPoints[1], newPoints[3], newPoints[4], newPoints[7]];
    const physicalValues = [newPoints[2], newPoints[5], newPoints[9], getPainRate(newPoints[6])];

    setPoints([...newPoints]);
    setMentalScore(calcScore([...mentalValues]));
    setPhysicalScore(calcScore([...physicalValues]));
  };

  const calcScore = (resultArr) => {
    return resultArr.reduce((sum, value) => sum + scores[value], 20);
  };

  const onChangeSliderValue = (event, newValue) => {
    setSliderValue(newValue);
    changeScore(6, newValue);
  };

  const resetForm = () => {
    setPoints([]);
  };

  useEffect(() => {
    if (open) {
      const newPoints = [...initialPoints];
      const mentalValues = [newPoints[1], newPoints[3], newPoints[4], newPoints[7]];
      const physicalValues = [newPoints[2], newPoints[5], newPoints[9], getPainRate(newPoints[6])];

      setMentalScore(calcScore([...mentalValues]));
      setPhysicalScore(calcScore([...physicalValues]));
    }
  }, [open, promis10]);

  const marks = useMemo(() => Array.from({ length: 11 }, (i, k) => ({ value: k, label: k })), []);

  const onSubmit = () => {
    if (isFollowUp) {
      setFieldValue('followUp.PSI.promis10', points);
    } else {
      setFieldValue('preOpData.promis10', points);
    }
    onClose();
  };

  return (
    <Modal
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      title={t('modals.promis.title')}
      submitTitle={t('buttons.complete')}
      onExited={resetForm}
    >
      <div className={classes.root}>
        <div className={classes.description}>
          Please respond to each question or statement by marking one box per row.
        </div>
        <ProcedureShared
          defaultValue={initialPoints[0]}
          promis
          onChange={changeScore}
          index={0}
          title={t('modals.promis.1')}
        />
        <ProcedureShared
          defaultValue={initialPoints[1]}
          promis
          onChange={changeScore}
          index={1}
          title={t('modals.promis.2')}
        />
        <ProcedureShared
          defaultValue={initialPoints[2]}
          promis
          onChange={changeScore}
          index={2}
          title={t('modals.promis.3')}
        />
        <ProcedureShared
          defaultValue={initialPoints[3]}
          promis
          onChange={changeScore}
          index={3}
          title={t('modals.promis.4')}
        />
        <ProcedureShared
          defaultValue={initialPoints[4]}
          promis
          onChange={changeScore}
          index={4}
          title={t('modals.promis.5')}
        />
        <ProcedureShared
          defaultValue={initialPoints[5]}
          promis
          onChange={changeScore}
          index={5}
          title={t('modals.promis.6')}
        />
        <label className={classes.label}>{t('modals.promis.7')}</label>
        <Slider
          defaultValue={initialPoints[6]}
          max={10}
          aria-labelledby='range-slider'
          step={1}
          valueLabelDisplay='auto'
          marks={marks}
          onChange={onChangeSliderValue}
        />
        <ProcedureShared
          defaultValue={initialPoints[7]}
          promis
          onChange={changeScore}
          index={7}
          title={t('modals.promis.8')}
        />
        <ProcedureShared
          defaultValue={initialPoints[8]}
          promis
          onChange={changeScore}
          index={8}
          title={t('modals.promis.9')}
        />
        <ProcedureShared
          defaultValue={initialPoints[9]}
          promis
          onChange={changeScore}
          index={9}
          title={t('modals.promis.10')}
        />
        <div className={classes.intervalScore}>
          <div className={classes.scoreItem}>
            <div className={classes.score}>
              {Boolean(physicalScore) ? physicalScore : t('modals.promis.NA')}
            </div>
            <div className={classes.scoreLabel}>{t('modals.promis.globalPH')}</div>
          </div>
          <div className={classes.scoreItem}>
            <div className={classes.score}>
              {Boolean(mentalScore) ? mentalScore : t('modals.promis.NA')}
            </div>
            <div className={classes.scoreLabel}>{t('modals.promis.globalMH')}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(memo(PromisModal));
