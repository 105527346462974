import { takeEvery, takeLatest, put, call, delay } from 'redux-saga/effects';

import {
  rewardsActionTypes,
  fetchRewardsSuccess,
  fetchRewardsError,
  fetchUpdateTokensSuccess,
  fetchEditSurgeonTokenSuccess,
  fetchEditSurgeonTokenError,
  fetchUpdateTokensError,
  fetchEditSalesRepTokenSuccess,
  fetchEditSalesRepTokenError,
} from './actions';
import {
  requestFetchRewards,
  requestUpdateAutoComplete,
  requestEditSurgeonTokens,
  requestEditSalesRepTokens,
} from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchRewards() {
  yield takeEvery(rewardsActionTypes.FETCH_REWARDS, doFetchRewards);
}

export function* watchFetchUpdateTokens() {
  yield takeEvery(rewardsActionTypes.FETCH_UPDATE_TOKENS, doFetchUpdateTokens);
}

export function* watchFetchEditSurgeonTokens() {
  yield takeEvery(rewardsActionTypes.FETCH_EDIT_SURGEON_TOKENS, doFetchEditSurgeonTokens);
}

export function* watchFetchEditSalesRepTokens() {
  yield takeEvery(rewardsActionTypes.FETCH_EDIT_SALES_REPS_TOKENS, doFetchEditSalesRepTokens);
}

function* doFetchRewards() {
  try {
    const response = yield call(requestFetchRewards);
    yield put(fetchRewardsSuccess(response));
  } catch (error) {
    yield put(fetchRewardsError(error));
  }
}

export function* doFetchUpdateTokens(action) {
  try {
    const { payload } = action;
    const { id, ...restProps } = payload;

    yield call(requestUpdateAutoComplete, { id, data: { ...restProps } });
    yield put(fetchUpdateTokensSuccess({ id, data: { ...restProps } }));
    yield put(showAlert({ message: 'Beer Tokens settings were successfully updated' }));
  } catch (error) {
    yield put(fetchUpdateTokensError());
  }
}

export function* doFetchEditSurgeonTokens(action) {
  try {
    yield call(requestEditSurgeonTokens, action.payload);
    yield put(fetchEditSurgeonTokenSuccess(action.payload));
  } catch (error) {
    yield put(fetchEditSurgeonTokenError());
  }
}

export function* doFetchEditSalesRepTokens(action) {
  try {
    yield call(requestEditSalesRepTokens, action.payload);
    yield put(fetchEditSalesRepTokenSuccess(action.payload));
  } catch (error) {
    yield put(fetchEditSalesRepTokenError());
  }
}
