import Firebase from '../../../firebase';
import { CASES_COLLECTION } from '../../../firebase/collections';
import { APP_ROLES } from '../../../constants';
import { receiveCases } from '../cases/requests';

const collection = Firebase.db.collection(CASES_COLLECTION);

export const requestFetchHistory = async (user) => {
  const { role } = user;
  const activeCases = collection.where('status', '!=', 'OPEN');

  switch (role) {
    case APP_ROLES.REGULATORY:
    case APP_ROLES.ADMIN: {
      return await receiveCases(activeCases);
    }
    case APP_ROLES.SURGEON: {
      return await receiveCases(activeCases.where('surgeonId', '==', user.assignedSurgeon.id));
    }
    case APP_ROLES.RESEARCHER:
      const result = [];
      const surgeonCases = await Promise.all(
        user.assignedSurgeons?.map((item) => {
          return receiveCases(activeCases.where('surgeonId', '==', item.id));
        })
      );
      const hospitalCases = await Promise.all(
        user.hospitals?.map((item) => {
          return receiveCases(activeCases.where('caseData.hospitalId', '==', item.id));
        })
      );

      surgeonCases?.forEach((arr) => {
        arr?.forEach((item) => {
          result.push(item);
        })
      })
      hospitalCases?.forEach((arr) => {
        arr?.forEach((item) => {
          if (result.findIndex((i) => i.id === item.id) === -1) {
            result.push(item)
          }
        })
      })

      return result;
    case APP_ROLES.SALES_REP: {
      const surgeonCases = await Promise.all(
        user.assignedSurgeons.map((item) => {
          return receiveCases(activeCases.where('surgeonId', '==', item.id));
        })
      );

      return surgeonCases.reduce((acc, item) => [...acc, ...item], []);
    }
    case APP_ROLES.LOGISTIC: {
      return await receiveCases(activeCases.where('country', '==', user.country));
    }
    default:
      return [];
  }
};
