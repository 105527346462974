//users
export const USER_DATA_COLLECTION = 'userData';
export const USER_AUTHORIZATION_COLLECTION = 'userAuthorization';
//products
export const CATEGORY_COLLECTION = 'categories';
export const PRODUCT_COLLECTION = 'products';
export const PROCEDURE_COLLECTION = 'procedures';
//constants
export const CONSTANTS_COLLECTION = 'constants';
//constant documents
export const COUNTRIES_DOCUMENT = 'countries';
//hospitals
export const HOSPITALS_COLLECTION = 'hospitals';
//surgeons
export const SURGEONS_COLLECTION = 'surgeons';
//news
export const NEWS_COLLECTION = 'news';
//rewards
export const REWARDS_COLLECTION = 'rewards';
//cases
export const CASES_COLLECTION = 'cases';
export const REWARD_LIST = 'rewardList';
//errors
export const ERRORS_COLLECTION = 'errors';
//notification emails
export const EMAILS_COLLECTION = 'notificationEmails';
export const PREFERENCES_COLLECTION = 'preferences';
