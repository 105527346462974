import React, { memo } from 'react';

import Modal from '../../modal-wrapper/Modal';
import AppLogo from '../../app-logo/AppLogo';

import { userSignOut } from '../../../store/actions/userActions';
import { RESET_STATE } from '../../../store/reducers';
import { useDispatch } from 'react-redux';
import { withFirebase } from '../../../firebase';
import useUser from '../../../hooks/useUser';
import RoleAbsent from './RoleAbsent';
import VerificationAbsent from './VerificationAbsent';

const VerificationModal = ({ firebase }) => {
  const dispatch = useDispatch();
  const { emailVerified, role } = useUser();

  const handleSignOut = () => {
    firebase.doSignOut().then(() => {
      dispatch(userSignOut());
      dispatch({ type: RESET_STATE });
    });
  };

  return (
    <Modal
      hasDialogActions={false}
      onClose={handleSignOut}
      title={<AppLogo />}
      open={!emailVerified || !role}
    >
      {!emailVerified ? <VerificationAbsent /> : <RoleAbsent />}
    </Modal>
  );
};

export default memo(withFirebase(VerificationModal));
