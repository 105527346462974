import React, { memo } from 'react';
import { Paper } from '@material-ui/core';

import { useStyles } from './style';

const RoleAbsent = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <h5>
        <b>Configuring your account!</b>
      </h5>
      <span>
        Please allow up to 48hrs to configure your account with the correct permissions and access.
      </span>
      <span>
        We will notify you via email when your account is ready, or if we need further information
        from you.
      </span>
    </Paper>
  );
};

export default memo(RoleAbsent);
