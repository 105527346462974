import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { firebaseConfig } from '../config';

import {
  USER_DATA_COLLECTION,
  USER_AUTHORIZATION_COLLECTION,
  PREFERENCES_COLLECTION,
} from './collections';
import { sendEmailToAdmin } from '../store/sagas/users/requests';

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.db.settings({ cacheSizeBytes: 20000000000 });
  }

  doCreateUserWithEmailAndPassword = (email, password, userData) => {
    return this.auth.createUserWithEmailAndPassword(email, password).then(({ user }) => {
      this.doSendEmailVerification();
      this.saveUserData({ ...userData, email });
      this.setUserRole(null);
      this.doSendEmailToAdmin(email, userData.firstName, userData.lastName);

      if (user) {
        return this.doGetCurrentUser();
      }
    });
  };

  doSendEmailToAdmin = (email, firstName, lastName) => sendEmailToAdmin(email, firstName, lastName);

  doSignInWithEmailAndPassword = async (email, password) => {
    return await this.auth.signInWithEmailAndPassword(email, password);
  };

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  saveUserData = (userData) =>
    this.db
      .collection(USER_DATA_COLLECTION)
      .doc(this.auth.currentUser.uid)
      .set(userData)
      .then(() =>
        this.auth.currentUser.updateProfile({
          displayName: `${userData.firstName} ${userData.lastName}`,
        })
      );

  getNextCaseId = async () => {
    return this.db
      .collection(PREFERENCES_COLLECTION)
      .doc('cases')
      .get()
      .then((doc) => doc.data().count);
  };

  generateNextCaseId = (count) => {
    return '0'.repeat(6 - count.toString().length) + count.toString();
  };

  updateNextCaseId = async (count) => {
    return this.db
      .collection(PREFERENCES_COLLECTION)
      .doc('cases')
      .update({ count: count + 1 });
  };

  setUserRole = (role, uid) => {
    const id = uid || this.auth.currentUser.uid;

    return this.db.collection(USER_AUTHORIZATION_COLLECTION).doc(id).set({ role });
  };

  doGetCurrentUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged((user) => {
        if (user) {
          Promise.all([this.getCurrentUserData(), this.getCurrentUserRoles()]).then((responses) =>
            resolve({ ...user, ...responses[0], ...responses[1] })
          );
        } else {
          resolve(user);
        }
        unsubscribe();
      }, reject);
    }).catch(() => null);

  getCurrentUserData = () => {
    return this.db
      .collection(USER_DATA_COLLECTION)
      .doc(this.auth.currentUser.uid)
      .get()
      .then((doc) => doc.data())
      .catch((error) => console.log(error));
  };

  getCurrentUserRoles = () =>
    this.db
      .collection(USER_AUTHORIZATION_COLLECTION)
      .doc(this.auth.currentUser.uid)
      .get()
      .then((doc) => doc.data())
      .catch(() => {});

  getUserData = (uid) =>
    this.db
      .collection(USER_DATA_COLLECTION)
      .doc(uid)
      .get()
      .then((doc) => doc.data())
      .catch(() => {});

  getAllUsers = () =>
    this.db
      .collection(USER_DATA_COLLECTION)
      .get()
      .then((res) => {
        return res;
      });

  getDownloadLink = (fileId) =>
    new Promise((resolve, reject) => {
      const storageRef = this.storage.ref();

      storageRef
        .child(fileId)
        .getDownloadURL()
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const instance = new Firebase();

export default instance;
