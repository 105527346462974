import React from 'react';
import { APP_ROLES, routes } from '../constants';

const CaseInfoPage = React.lazy(() => import('../pages/case-info/CaseInfoPage'));
const SettingsPage = React.lazy(() => import('../pages/settings/SettingsPage'));
const ProductsPage = React.lazy(() => import('../pages/products/ProductsPage'));
const HistoryPage = React.lazy(() => import('../pages/history/HistoryPage'));
const HomePage = React.lazy(() => import('../pages/home/HomePage'));
const CasesPage = React.lazy(() => import('../pages/cases/CasesPage'));
const AnalyticsPage = React.lazy(() => import('../pages/analytics/AnalyticsPage'));
const PMCFPage = React.lazy(() => import('../pages/pmcf/PMCFPage'));

export const Pages = [
  {
    exact: true,
    path: routes.PRODUCTS,
    component: ProductsPage,
    forbidden: [],
  },
  {
    exact: true,
    path: routes.CASE_INFO,
    component: CaseInfoPage,
    forbidden: [],
  },
  {
    exact: true,
    path: routes.HISTORY,
    component: HistoryPage,
    forbidden: [],
  },
  {
    exact: true,
    path: routes.HOME,
    component: HomePage,
    forbidden: [],
  },
  {
    exact: true,
    path: routes.CASES,
    component: CasesPage,
    forbidden: [],
  },
  {
    exact: true,
    path: routes.ANALYTICS,
    component: AnalyticsPage,
    forbidden: [],
  },
  {
    exact: true,
    path: routes.PMCF,
    component: PMCFPage,
    forbidden: [],
  },
  {
    exact: false,
    path: routes.SETTINGS,
    component: SettingsPage,
    forbidden: [APP_ROLES.SURGEON, APP_ROLES.SALES_REP, APP_ROLES.RESEARCHER, APP_ROLES.LOGISTIC],
  },
];
