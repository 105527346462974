// Development Firebase configuration
const firebaseDevConfig = {
  apiKey: 'AIzaSyAszJC3cLxn33nMRPOiVDjTpFglre-6D2g',
  authDomain: 'signature-development-7f98a.firebaseapp.com',
  databaseURL: 'https://signature-development-7f98a.firebaseio.com',
  projectId: 'signature-development-7f98a',
  storageBucket: 'signature-development-7f98a.appspot.com',
  messagingSenderId: '712242199025',
  appId: '1:712242199025:web:10725dc19a5b45bbc36c50',
  measurementId: 'G-VEWF1QM3YM',
};
// Production Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAqIVCLRVWBRWleVJtbmtjye_S6GdwcNh0',
  authDomain: 'signature-pwa.firebaseapp.com',
  databaseURL: 'https://signature-pwa.firebaseio.com',
  projectId: 'signature-pwa',
  storageBucket: 'signature-pwa.appspot.com',
  messagingSenderId: '604975355952',
  appId: '1:604975355952:web:046d5d2dae31096ea0f5f5',
  measurementId: 'G-9E8P4YHSZN',
};

const cloudFunctionsDev = 'https://us-central1-signature-development-7f98a.cloudfunctions.net';

const cloudFunctionsProd = 'https://us-central1-signature-pwa.cloudfunctions.net';

function getFirebaseConfig() {
  console.log('APP_STAGE: ', process.env.REACT_APP_STAGE);
  console.log('NODE_ENV: ', process.env.NODE_ENV);
  if (process.env.REACT_APP_STAGE === 'prod') {
    return firebaseConfig;
  } else if (process.env.REACT_APP_STAGE === 'dev') {
    return firebaseDevConfig;
  }
  if (process.env.NODE_ENV === 'development') {
    return firebaseDevConfig;
  } else {
    return firebaseConfig;
  }
}

function getCloudFunctionConfig() {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return cloudFunctionsProd;
  } else if (process.env.REACT_APP_STAGE === 'dev') {
    return cloudFunctionsDev;
  }
  if (process.env.NODE_ENV === 'development') {
    return cloudFunctionsDev;
  } else {
    return cloudFunctionsProd;
  }
}

const config = {
  firebaseConfig: getFirebaseConfig(),
  cloudFunctionsConfig: getCloudFunctionConfig(),
};

export default config;
