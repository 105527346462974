export const pmcfActionTypes = {
  SET_PMCF_FILTER: 'app/pmcf/SET_PMCF_FILTER',
  SET_PMCF_FILTER_SUCCESS: 'app/pmcf/SET_PMCF_SUCCESS',
  SET_PMCF_STAGES: 'app/pmcf/SET_PMCF_STAGES',
  SET_PMCF_STAGES_SUCCESS: 'app/pmcf/SET_PMCF_STAGES_SUCCESS',
};

export const setPMCFFilter = (payload, hasDelay = true) => ({
  type: pmcfActionTypes.SET_PMCF_FILTER,
  payload,
  hasDelay,
});

export const setPMCFFilterSuccess = (payload) => ({
  type: pmcfActionTypes.SET_PMCF_FILTER_SUCCESS,
  payload,
});

export const setPMCFStages = (payload) => ({
  type: pmcfActionTypes.SET_PMCF_STAGES,
  payload,
});
