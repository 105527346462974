import React from 'react';
import useUserRole from '../hooks/useUserRole';
import { Redirect, Route } from 'react-router-dom';

function PageResolver({ forbidden, ...restProps }) {
  const currentRole = useUserRole();

  if (forbidden.includes(currentRole)) {
    return <Redirect to={'/'} />;
  }

  return <Route {...restProps} />;
}

export default PageResolver;
