import Firebase from '../../../firebase';
import { CATEGORY_COLLECTION } from '../../../firebase/collections';

const collection = Firebase.db.collection(CATEGORY_COLLECTION);

export const requestAddCategory = async ({ ...restProps }) => {
  return await collection.add({ ...restProps }).then((docRef) => docRef.id);
};

export const requestEditCategory = async ({ id, ...restProps }) => {
  return await collection.doc(id).update({ ...restProps });
};

export const requestDeleteCategory = async (id) => {
  return await collection.doc(id).delete();
};

export const requestUpdateCategorySequence = async (list) =>
  Promise.all(list.map((item, index) => collection.doc(item.id).update({ index: index + 1 })));

export const requestFetchCategory = async () => {
  return await collection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
};
