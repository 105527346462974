import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import withUser from '../../hocs/withUser';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { IconButton, Hidden } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userSignOut } from '../../store/actions/userActions';
import { withFirebase } from '../../firebase';
import { RESET_STATE } from '../../store/reducers';
import AppLanguage from './AppLanguage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    justifyContent: 'flex-end',
  },
  username: {
    fontSize: 22,
  },
  contrastUsername: {
    fontSize: 22,
    color: '#ffffff',
  },
  role: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#A7A7A7',
    textTransform: 'capitalize',
    padding: '0 14px',
    position: 'relative',
    top: 2,
  },
  contrastRole: {
    color: '#ffffff',
  },
  accountIcon: {
    width: 40,
    height: 40,
    color: 'rgb(167, 167, 167)',
  },
  contrastLogoutButton: {
    maxHeight: 23,
    color: '#a7a7a7',
    cursor: 'pointer',
  },
  logoutButton: {
    color: '#ffffff',
    cursor: 'pointer',
  },
}));

const CurrentUser = ({ user, firebase }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSignOut = () => {
    firebase.doSignOut().then(() => {
      dispatch(userSignOut());
      dispatch({ type: RESET_STATE });
    });
  };

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        {user && user.role && (
          <>
            <span
              className={clsx(classes.username, pathname === '/' && classes.contrastUsername)}
            >{`${user.firstName} ${user.lastName}`}</span>
            <span className={clsx(classes.role, pathname === '/' && classes.contrastRole)}>
              {user?.role.toLowerCase().split('_').join(' ')}
            </span>
            <AppLanguage />
            <PowerSettingsNewIcon
              className={clsx(
                classes.contrastLogoutButton,
                pathname === '/' && classes.logoutButton
              )}
              onClick={handleSignOut}
            />
          </>
        )}
      </Hidden>
      <Hidden smUp>
        <AppLanguage />
        <IconButton size='small' className={classes.logoutButton} onClick={handleSignOut}>
          <AccountCircleIcon
            className={clsx(classes.accountIcon, pathname === '/' && classes.logoutButton)}
          />
        </IconButton>
      </Hidden>
    </div>
  );
};

export default withFirebase(withUser(CurrentUser));
