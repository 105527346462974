import Firebase from '../../../firebase';
import {
  REWARDS_COLLECTION,
  REWARD_LIST,
  SURGEONS_COLLECTION,
  USER_AUTHORIZATION_COLLECTION,
} from '../../../firebase/collections';

const collection = Firebase.db.collection(REWARDS_COLLECTION);

export const requestFetchRewards = async () => {
  const result = [];

  const rewards = await collection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

  for (const item of rewards) {
    const beerTokens = await collection
      .doc(item.id)
      .collection(REWARD_LIST)
      .doc('beerTokens')
      .get()
      .then((response) => response.data());

    result.push({
      beerTokens,
      rewardListInfo: item,
    });
  }

  return result;
};

export const requestUpdateAutoComplete = async ({ id, data }) => {
  const { caseAutoCompleted, tokensPerDays } = data;
  await collection.doc(id).collection('rewardList').doc('beerTokens').update({
    caseAutoCompleted: caseAutoCompleted,
    tokensPerDays: tokensPerDays,
  });
};

export const requestEditSurgeonTokens = async ({ id, newTokens }) => {
  return await Firebase.db.collection(SURGEONS_COLLECTION).doc(id).update({
    beerTokens: newTokens,
  });
};

export const requestEditSalesRepTokens = async ({ id, newTokens }) => {
  return await Firebase.db.collection(USER_AUTHORIZATION_COLLECTION).doc(id).update({
    beerTokens: newTokens,
  });
};
