import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { toggleSidebar } from '../../store/actions/sidebarActions';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as HomeIcon } from '../../assets/home_menu_icon.svg';
import { ReactComponent as CasesIcon } from '../../assets/case_menu_icon.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/analytics_menu_icon.svg';
import { ReactComponent as HistoryIcon } from '../../assets/history_menu_icon.svg';
import { ReactComponent as ProductsIcon } from '../../assets/products_menu_icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings_menu_icon.svg';
import { ReactComponent as PMCFIcon } from '../../assets/contact_page_black_24dp.svg';

import { makeStyles } from '@material-ui/core/styles';
import { routes } from '../../constants';
import CurrentUser from './CurrentUser';
import { Hidden, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0',
    '& > *': {
      flex: 1,
    },
    [theme.breakpoints.down(600)]: {
      padding: '12px 0',
    },
  },
  path: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: 20,
    },
  },
  icon: {
    maxHeight: 20,
    fill: '#343434',
  },
  contrastIcon: {
    fill: '#ffffff',
  },
  pageLabel: {
    textTransform: 'capitalize',
    fontSize: 22,
    fontWeight: 'bold',
  },
  contrastLabel: {
    color: '#ffffff',
  },
  nestedLabel: {
    color: theme.palette.primary.main,
  },
  divider: {
    padding: '0 14px',
  },
  menu: {
    flex: 0,
    '& > * > *.MuiSvgIcon-colorPrimary': {
      color: '#7a7a7a !important',
    },
  },
  contrastMenu: {
    '& > * > *.MuiSvgIcon-colorPrimary': {
      color: '#ffffff !important',
    },
  },
}));

const AppHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [route, setRoute] = useState({
    path: routes.HOME,
    Icon: HomeIcon,
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const list = useMemo(
    () => [
      {
        path: routes.HOME,
        Icon: HomeIcon,
      },
      {
        path: routes.CASES,
        Icon: CasesIcon,
      },
      {
        path: routes.ANALYTICS,
        Icon: AnalyticsIcon,
      },
      {
        path: routes.HISTORY,
        Icon: HistoryIcon,
      },
      {
        path: routes.PRODUCTS,
        Icon: ProductsIcon,
      },
      {
        path: routes.SETTINGS,
        Icon: SettingsIcon,
      },
      {
        path: routes.CASE_INFO,
        Icon: CasesIcon,
      },
      {
        path: routes.ADD_CASE,
        Icon: CasesIcon,
      },
      {
        path: routes.PMCF,
        Icon: CasesIcon,
      },
    ],
    []
  );

  useEffect(() => {
    const getRoute = (location) => {
      const route = list.slice(1).find((item) => {
        return location.pathname.includes(item.path.split('/').slice(0, 2).join('/'));
      });

      if (!route) {
        setRoute(list[0]);
      } else {
        if (location.pathname.includes('settings')) {
          setRoute({ ...route, path: location.pathname });
        } else {
          setRoute(route);
        }
      }
    };
    const unlisten = history.listen(getRoute);
    getRoute(history.location);

    return unlisten;
  }, [list]);

  const getPathLabel = () => {
    const { path } = route;

    if (path === '/') {
      return (
        <span className={clsx(classes.pageLabel, classes.contrastLabel)}>{t('sidebar.home')}</span>
      );
    }

    const nestedPath = path
      .split('/')
      .filter((item) => Boolean(item) && item !== ':id')
      .map((item) => item.replace('-', ' '));

    return nestedPath.reduce((acc, item, index, arr) => {
      if (index === arr.length - 1) {
        return [
          ...acc,
          <span
            key={index}
            className={`${classes.pageLabel} ${arr.length > 1 ? classes.nestedLabel : ''}`}
          >
            {t(`sidebar.${item}`)}
          </span>,
        ];
      }
      return [
        ...acc,
        <span key={index} className={classes.pageLabel}>
          {t(`sidebar.${item}`)}
        </span>,
        <span key={`${index}-divider`} className={`${classes.pageLabel} ${classes.divider}`}>
          /
        </span>,
      ];
    }, []);
  };

  const { Icon } = route;

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <div className={classes.path}>
          <Icon className={clsx(classes.icon, pathname === '/' && classes.contrastIcon)} />
          {getPathLabel()}
        </div>
      </Hidden>
      <Hidden smUp>
        <IconButton
          className={clsx(classes.menu, pathname === '/' && classes.contrastMenu)}
          onClick={() => dispatch(toggleSidebar())}
        >
          <MenuIcon color='primary' />
        </IconButton>
      </Hidden>
      <CurrentUser />
    </div>
  );
};

export default AppHeader;
