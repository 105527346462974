import { getIn } from 'formik';
import {
  extraParams,
  followUpExtraParams,
  followUpLabels,
  getPreOpDataAssessmentType,
  patientDataLabels,
  procedureLabels,
  PSI,
  tkrRequiredFields,
  thrRequiredFields,
  ibfRequiredFields,
  tkrRequiredFollowUpFields,
  thrRequiredFollowUpFields,
  ibfRequiredFollowUpFields,
  ibfThoracolumbarRequiredFollowUpFields,
  ibfCervicalRequiredFollowUpFields,
} from '../components/modals/add-case/utils';
import { ASSESSMENT_TYPES, MEASUREMENT_TYPE, OPERATED_LEVEL, PRE_OP_DATA_TYPES } from '../constants';

export const findModelIndexById = (id, list) => {
  return list.findIndex((item) => item.id === id);
};

export const updateModelField = (model, value) => {
  return { ...model, ...value };
};

export const sortListByIndex = (list) => {
  return list.sort((a, b) => a.index - b.index);
};

export const deleteFromListById = (state, id) => {
  const newList = [...state.list];
  const index = findModelIndexById(id, newList);

  if (index === -1) {
    return state;
  }

  newList.splice(index, 1);

  return { ...state, list: newList };
};

export const getRandomNumber = (min, max) => {
  return Math.ceil(Math.random() * (max - min) + min);
};

export const detectBrowser = () => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } else {
    return 'Unknown';
  }
};

export const isSafari = () => {
  return (
    navigator.userAgent.toLowerCase().indexOf('safari') !== -1 &&
    navigator.userAgent.toLowerCase().indexOf('chrome') > -1
  );
};

export const memoizedFormikField =
  (values, formikFields = []) =>
  (prevProps, nextProps) => {
    const prevFormikState = prevProps.formik;
    const nextFormikState = nextProps.formik;

    return (
      values.every((name) => {
        return getIn(prevFormikState.values, name) === getIn(nextFormikState.values, name);
      }) && formikFields.every((name) => prevFormikState[name] === nextFormikState[name])
    );
  };

export const getBMI = (measurementType = 'METRIC', params) => {
  const { weight, height, feet, inches } = params;

  if (measurementType === MEASUREMENT_TYPE.IMPERIAL) {
    return feet && +weight ? ((+weight * 703) / Math.pow(feet * 12 + inches, 2)).toFixed(2) : null;
  }

  return height && weight ? (weight / Math.pow(height * 0.01, 2)).toFixed(2) : null;
};

export const calculatePatientHeight = (value, toValue) => {
  switch (toValue) {
    case 'FEET_INCH': {
      const inch = value / 2.54;
      const feet = Math.floor(inch / 12);
      const modulo = Math.round(inch % 12);

      return { feet: feet, inches: modulo };
    }
    case 'SM': {
      const { inch, feet } = value;

      const resultInch = inch * 2.54;
      const resultFeet = feet * 30.48;
      return Math.round(resultFeet + resultInch);
    }
    default:
      return 0;
  }
};

export const calculatePatientWeight = (value, toValue) => {
  switch (toValue) {
    case 'KG':
      return Math.round(value * 0.453592);
    case 'IBS':
      return Math.round(value * 2.20462);
    default:
      return 0;
  }
};

export const getCurrentMeasure = (measurementType, height, weight, feet, inches) => {
  if (measurementType === MEASUREMENT_TYPE.IMPERIAL) {
    return { feet, inches, weight, height, measurementType };
  }

  return { height, weight, measurementType };
};

export const getDateFromString = (date) => {
  const [day, month, year] = date.split('/');

  return new Date(year, month - 1, day);
};

const checkValue = (value, isPatientData) => {
  if (isPatientData) {
    if (value === 0 || value === '0') {
      return true;
    }
  }
  if (value === null) {
    return true;
  }
  if (typeof value === 'string') {
    return value === '';
  }
  if (typeof value === 'boolean') {
    return false;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};

export const checkPatientData = (patientData) => {
  let isPatientDataFilled = true;
  let requiredFields = {};

  Object.entries(patientData).forEach(([key, value]) => {
    if (checkValue(value, true)) {
      if (patientDataLabels[key]) {
        requiredFields[key] = patientDataLabels[key];
        isPatientDataFilled = false;
      }
    }
  });

  return { isPatientDataFilled, requiredFields };
};

export const checkPreOpData = (preOpData) => {
  let isPreOpDataFilled = true;
  let requiredFields = {};

  if (!preOpData.xrays?.length) {
    preOpData.xrays = [];
    requiredFields.xrays = procedureLabels.xrays;
    isPreOpDataFilled = false;
  }

  Object.entries(preOpData).forEach(([key, value]) => {
    if (checkValue(value)) {
      let arr = Object.keys(procedureLabels);

      if (preOpData.type === PRE_OP_DATA_TYPES.THR) {
        arr = thrRequiredFields;
      } else if (preOpData.type === PRE_OP_DATA_TYPES.TKR) {
        arr = tkrRequiredFields;
      } else if (preOpData.type === PRE_OP_DATA_TYPES.IBF) {
        arr = ibfRequiredFields;
      } else if (preOpData.type === PRE_OP_DATA_TYPES.HTO) {
        arr = [];
      }

      if (!(key in extraParams) && procedureLabels[key] && arr?.includes(key)) {
        requiredFields[key] = procedureLabels[key];
        isPreOpDataFilled = false;
      }
    }
  });

  if (preOpData.type === PRE_OP_DATA_TYPES.THR || preOpData.type === PRE_OP_DATA_TYPES.TKR) {
    const assessmentType = getAssessmentTypeValue(preOpData.selectAssessmentType);

    if (assessmentType) {
      if (preOpData[assessmentType] === '' || preOpData[assessmentType] === null) {
        requiredFields[assessmentType] = procedureLabels[assessmentType];
        isPreOpDataFilled = false;
      }
    } else {
      requiredFields['assessmentType'] = 'Assessment Type';
      isPreOpDataFilled = false;
    }
  }

  return { isPreOpDataFilled, requiredFields };
};

export const checkFollowUpFields = (followUp, type) => {
  let isFollowUpFilled = true;
  let requiredFields = {};

  if (followUp.adverseEvent === '0' || followUp.adverseEvent === 0) {  // Temporary. Adverse event 0 === "YES", 1 === "NO". Needs to be changed on the mobile first.
    Object.entries(followUp).forEach(([key, value]) => {
      if (checkValue(value)) {
        if (!(key in followUpExtraParams) && followUpLabels[key]) {
          requiredFields[key] = followUpLabels[key];
          isFollowUpFilled = false;
        }
      }
    });
  }

  if (!followUp.xrays?.length) {
    requiredFields['xrays'] = followUpLabels['xrays'];
    isFollowUpFilled = false;
  }

  const { PSI } = followUp;
  if (PSI.assessmentType && !PSI[PSI.assessmentType]) { // Add assessment type key to PSI obj if missing
    if (typeof PSI[PSI.assessmentType] === 'number') {
      PSI[PSI.assessmentType] = 0;
    } else {
      PSI[PSI.assessmentType] = '';
    }
  }

  Object.entries(PSI).forEach(([key, value]) => {
    if (checkValue(value)) {
      let arr = Object.keys(followUpLabels.PSI[type]);

      if (type === PRE_OP_DATA_TYPES.THR) {
        arr = thrRequiredFollowUpFields;
      } else if (type === PRE_OP_DATA_TYPES.TKR) {
        arr = tkrRequiredFollowUpFields;
      } else if (type === PRE_OP_DATA_TYPES.IBF) {
        if (PSI?.operatedLevel === OPERATED_LEVEL.THORACOLUMBAR) {
          arr = ibfThoracolumbarRequiredFollowUpFields;
        }
        if (PSI?.operatedLevel === OPERATED_LEVEL.CERVICAL) {
          arr = ibfCervicalRequiredFollowUpFields;
        }
      }

      if (!(key in followUpExtraParams) && followUpLabels.PSI[type][key] && arr?.includes(key)) {
        requiredFields[key] = followUpLabels.PSI[type][key];
        isFollowUpFilled = false;
      }
    }
  });

  return { requiredFields, isFollowUpFilled };
};

export const getAssessmentTypeKey = (assessmentType) => {
  if (assessmentType) {
    switch (assessmentType) {
      case ASSESSMENT_TYPES.PREOPOHS:
        return 'pre_op_ohs';
      case ASSESSMENT_TYPES.HOOSJR:
        return 'HOOSJR';
      case ASSESSMENT_TYPES.KOOSJR:
        return 'KOOSJR';
      case ASSESSMENT_TYPES.AKSS:
        return 'pre_op_akss';
      case ASSESSMENT_TYPES.OKS:
        return 'pre_op_oks';
    }
  }

  return null;
};

export const getAssessmentTypeValue = (assessmentType) => {
  if (assessmentType) {
    switch (assessmentType) {
      case 'pre_op_ohs':
        return ASSESSMENT_TYPES.PREOPOHS;
      case 'HOOS-JR':
        return ASSESSMENT_TYPES.HOOSJR;
      case 'KOOS-JR':
        return ASSESSMENT_TYPES.KOOSJR;
      case 'HOOSJR':
        return ASSESSMENT_TYPES.HOOSJR;
      case 'KOOSJR':
        return ASSESSMENT_TYPES.KOOSJR;
      case 'pre_op_akss':
        return ASSESSMENT_TYPES.AKSS;
      case 'pre_op_oks':
        return ASSESSMENT_TYPES.OKS;
    }
  }

  return null;
};

export const setYAxisWidth = (data) => {
  if (data.length === 0) {
    return;
  }

  const max = data.reduce((acc, curr) => (acc.count > curr.count ? acc : curr)).count;

  switch (true) {
    case max >= 900:
      return 38;
    case max >= 100:
      return 30;
    default:
      return 30;
  }
};

export const hasAdverseEvent = (adverseEvent) => {
  return adverseEvent === '0' || adverseEvent === 0;  // Temporary. Adverse event 0 === "YES", 1 === "NO". Needs to be changed on the mobile first.
};
