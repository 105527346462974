import React, { useMemo, useState } from 'react';

import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
  },
  formControlLabel: {
    color: '#7a7a7a',
  },
}));

const ProcedureShared = ({ promis, title, onChange, index, defaultValue }) => {
  const classes = useStyles();
  const [radioValue, setRadioValue] = useState(defaultValue);
  const { t } = useTranslation();

  const radioButtonsAnswer = useMemo(
    () => [
      { label: t('promis.none'), value: 0 },
      { label: t('promis.mild'), value: 1 },
      { label: t('promis.moderate'), value: 2 },
      { label: t('promis.severe'), value: 3 },
      { label: t('promis.extreme'), value: 4 },
    ],
    []
  );

  const healthAnswers = useMemo(
    () => [
      { label: t('promis.excellent'), value: 0 },
      { label: t('promis.veryGood'), value: 1 },
      { label: t('promis.good'), value: 2 },
      { label: t('promis.fair'), value: 3 },
      { label: t('promis.poor'), value: 4 },
    ],
    []
  );

  const onChangeRadio = (event) => {
    setRadioValue(+event.target.value);
    onChange(index, +event.target.value);
  };

  return (
    <RadioGroup>
      <label className={classes.label}>{title}</label>
      {promis
        ? healthAnswers.map((option) => (
            <FormControlLabel
              key={option.label}
              value={option.value}
              onChange={onChangeRadio}
              label={<span className={classes.formControlLabel}>{option.label}</span>}
              control={<Radio color='primary' checked={radioValue === option.value} />}
            />
          ))
        : radioButtonsAnswer.map((option) => (
            <FormControlLabel
              key={option.label}
              value={option.value}
              onChange={onChangeRadio}
              label={<span className={classes.formControlLabel}>{option.label}</span>}
              control={<Radio color='primary' checked={radioValue === option.value} />}
            />
          ))}
    </RadioGroup>
  );
};

export default ProcedureShared;
