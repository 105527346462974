import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import { closeSidebar } from '../../store/actions/sidebarActions';
import { useDispatch } from 'react-redux';

const SidebarItem = ({ item, index, list }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { label, children, path, Icon } = item;
  const isActive = pathname === path || children.find((item) => pathname.includes(item.path));
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (isActive) {
      setShowChildren(true);
    } else {
      setShowChildren(false);
    }
  }, [isActive]);

  const handleCloseSideBar = () => {
    dispatch(closeSidebar());
  };

  const handleChangeShowChildren = () => {
    setShowChildren((prev) => !prev);
  };

  const LabelListItem = (
    <ListItem className={`list-item ${isActive ? 'list-item-active' : ''}`}>
      <div>
        <Icon
          className={`sidebar-item-icon icon ${isActive ? 'active' : ''} ${
            index === list.length - 1 ? 'last-sidebar-item-icon' : ''
          }`}
        />
      </div>
      <span className={isActive ? 'active' : ''}>{label}</span>
    </ListItem>
  );

  return (
    <React.Fragment>
      {children.length > 0 ? (
        <div className='link' onClick={handleChangeShowChildren}>
          {LabelListItem}
        </div>
      ) : (
        <Link to={item.path} className='link' onClick={handleCloseSideBar}>
          {LabelListItem}
        </Link>
      )}

      {showChildren && children.length > 0 && (
        <div className='expanded-list'>
          {children.map((item, index) => {
            const { label, path } = item;
            const isActive = pathname === path;

            return (
              <Link key={index} to={path} onClick={handleCloseSideBar}>
                <span className={isActive ? 'active' : ''}>{label}</span>
              </Link>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
    Icon: PropTypes.any,
    children: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, path: PropTypes.string })
    ),
  }),
  index: PropTypes.number,
  list: PropTypes.any,
};

export default memo(SidebarItem);
