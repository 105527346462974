export const countriesActionTypes = {
  FETCH_COUNTRIES: 'app/countries/FETCH_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS: 'app/countries/FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_ERROR: 'app/countries/FETCH_COUNTRIES_ERROR',
  FETCH_CHANGE_COUNTRY: 'app/countries/FETCH_CHANGE_COUNTRY',
  FETCH_CHANGE_COUNTRY_SUCCESS: 'app/countries/FETCH_CHANGE_COUNTRY_SUCCESS',
  FETCH_CHANGE_COUNTRY_ERROR: 'app/countries/FETCH_CHANGE_COUNTRY_ERROR',
};

export const fetchCountries = (payload) => ({
  type: countriesActionTypes.FETCH_COUNTRIES,
  payload,
});

export const fetchCountriesSuccess = (payload) => ({
  type: countriesActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload,
});

export const fetchCountriesError = (payload) => ({
  type: countriesActionTypes.FETCH_COUNTRIES_ERROR,
  payload,
});

export const fetchChangeCountry = (payload) => ({
  type: countriesActionTypes.FETCH_CHANGE_COUNTRY,
  payload,
});

export const fetchChangeCountrySuccess = (payload) => ({
  type: countriesActionTypes.FETCH_CHANGE_COUNTRY_SUCCESS,
  payload,
});

export const fetchChangeCountryError = (payload) => ({
  type: countriesActionTypes.FETCH_CHANGE_COUNTRY_ERROR,
  payload,
});
