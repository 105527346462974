import Firebase from '../../../firebase/Firebase';
import { CONSTANTS_COLLECTION, COUNTRIES_DOCUMENT } from '../../../firebase/collections';

const collection = Firebase.db.collection(CONSTANTS_COLLECTION);

export const requestFetchCountries = async () => {
  return await collection
    .doc(COUNTRIES_DOCUMENT)
    .get()
    .then((doc) => doc.data());
};
