import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-inputMultiline {
    padding: 0 !important;
  }
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  margin-bottom: 8px;
  font-weight: 600;
  opacity: 0.8;
`;

export const ErrorLabel = styled.span`
  color: #f44336;
`;
