import { CHANGE_COUNTRY } from '../actions/actionTypes';
import { countriesActionTypes } from '../sagas/countries/actions';

const initialState = {
  list: [],
  loading: false,
  selected: null,
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case countriesActionTypes.FETCH_COUNTRIES:
      return { ...state, loading: true };
    case countriesActionTypes.FETCH_COUNTRIES_SUCCESS:
      const { countryList } = action.payload;

      return {
        ...state,
        loading: false,
        list: countryList,
        selected: countryList[0],
      };
    case countriesActionTypes.FETCH_CHANGE_COUNTRY_SUCCESS: {
      return { ...state, selected: action.payload };
    }
    default:
      return state;
  }
};

export default countriesReducer;
