import { LOAD_NEWS, RECEIVE_NEWS } from '../actions/actionTypes';
import { newsActionTypes } from '../sagas/news/actions';
import { deleteFromListById } from '../../util/functions';

const initialState = {
  list: [],
  loading: false,
  previousList: [],
  updating: false,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsActionTypes.FETCH_NEWS: {
      return { ...state, loading: true };
    }
    case newsActionTypes.FETCH_NEWS_SUCCESS: {
      return { ...state, loading: false, list: action.payload };
    }
    case newsActionTypes.FETCH_NEWS_ADD_SUCCESS: {
      return { ...state, list: [action.payload, ...state.list] };
    }
    case newsActionTypes.FETCH_NEWS_EDIT_SUCCESS: {
      const {
        payload: { id, title, subTitle, text },
      } = action;
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.id === id);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], title, subTitle, text };

      return { ...state, list: newList };
    }
    case newsActionTypes.FETCH_NEWS_DELETE_SUCCESS: {
      return deleteFromListById(state, action.payload);
    }
    case newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE: {
      const newsList = [...state.list];
      const passedList = action.payload;

      passedList.forEach((item) => {
        const index = newsList.findIndex((_item) => _item.id === item.id);

        if (index !== -1) {
          newsList.splice(index, 1);
        }
      });

      return {
        ...state,
        previousList: [...state.list],
        list: [...newsList, ...passedList],
        updating: true,
      };
    }
    case newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE_SUCCESS: {
      return { ...state, updating: false };
    }
    case newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE_ERROR: {
      return { ...state, updating: false, list: [...state.previousList] };
    }
    default: {
      return state;
    }
  }
};

export default newsReducer;
