import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  fetchHospitalAddError,
  fetchHospitalAddSuccess,
  fetchHospitalDeleteError,
  fetchHospitalDeleteSuccess,
  fetchHospitalEditError,
  fetchHospitalEditSuccess,
  fetchHospitalError,
  fetchHospitalSuccess,
  hospitalsActionTypes,
} from './actions';
import {
  requestAddHospital,
  requestEditHospital,
  requestDeleteHospital,
  requestFetchHospital,
} from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchHospital() {
  yield takeEvery(hospitalsActionTypes.FETCH_HOSPITAL, deFetchHospital);
}

export function* watchFetchHospitalAdd() {
  yield takeEvery(hospitalsActionTypes.FETCH_HOSPITAL_ADD, doFetchHospitalAdd);
}

export function* watchFetchHospitalEdit() {
  yield takeEvery(hospitalsActionTypes.FETCH_HOSPITAL_EDIT, doFetchHospitalEdit);
}

export function* watchFetchHospitalDelete() {
  yield takeEvery(hospitalsActionTypes.FETCH_HOSPITAL_DELETE, doFetchHospitalDelete);
}

function* deFetchHospital() {
  try {
    const response = yield call(requestFetchHospital);
    yield put(fetchHospitalSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(fetchHospitalError());
  }
}

function* doFetchHospitalAdd(action) {
  try {
    const { payload } = action;
    const response = yield call(requestAddHospital, { ...payload });

    yield put(fetchHospitalAddSuccess({ id: response, ...payload }));
    yield put(showAlert({ message: 'Hospital was successfully added' }));
  } catch (error) {
    yield put(fetchHospitalAddError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchHospitalEdit(action) {
  try {
    const { payload } = action;
    yield call(requestEditHospital, { ...payload });

    yield put(fetchHospitalEditSuccess({ ...payload }));
    yield put(showAlert({ message: 'Hospital was successfully edited' }));
  } catch (error) {
    yield put(fetchHospitalEditError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchHospitalDelete(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield call(requestDeleteHospital, id);

    yield put(fetchHospitalDeleteSuccess(id));
    yield put(showAlert({ message: 'Hospital was successfully deleted', type: 'success' }));
  } catch (error) {
    yield put(fetchHospitalDeleteError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}
