import { rewardsActionTypes } from '../rewards/actions';

export const usersActionTypes = {
  FETCH_SET_USER_ROLE: 'app/users/FETCH_SET_USER_ROLE',
  FETCH_SET_USER_ROLE_SUCCESS: 'app/users/FETCH_SET_USER_ROLE_SUCCESS',
  FETCH_SET_USER_ROLE_ERROR: 'app/users/FETCH_SET_USER_ROLE_ERROR',
  FETCH_ASSIGN_SALES_REP_SURGEONS: 'app/users/FETCH_ASSIGN_SALES_REP_SURGEONS',
  FETCH_ASSIGN_SALES_REP_SURGEONS_SUCCESS: 'app/users/FETCH_ASSIGN_SALES_REP_SURGEONS_SUCCESS',
  FETCH_ASSIGN_SALES_REP_SURGEONS_ERROR: 'app/users/FETCH_ASSIGN_SALES_REP_SURGEONS_ERROR',
  FETCH_USER_RESET: 'app/users/FETCH_USER_RESET',
  FETCH_USER_RESET_SUCCESS: 'app/users/FETCH_USER_RESET_SUCCESS',
  FETCH_USER_RESET_ERROR: 'app/users/FETCH_USER_RESET_ERROR',
  FETCH_USER_CHANGE_COUNTRY: 'app/users/FETCH_USER_CHANGE_COUNTRY',
  FETCH_USER_CHANGE_COUNTRY_SUCCESS: 'app/users/FETCH_USER_CHANGE_COUNTRY_SUCCESS',
  FETCH_USER_CHANGE_COUNTRY_ERROR: 'app/users/FETCH_USER_CHANGE_COUNTRY_ERROR',

  FETCH_ASSIGN_RESEARCHER_HOSPITALS: 'app/users/FETCH_ASSIGN_RESEARCHER_HOSPITALS',
  FETCH_ASSIGN_RESEARCHER_HOSPITALS_SUCCESS: 'app/users/FETCH_ASSIGN_RESEARCHER_HOSPITALS_SUCCESS',
  FETCH_ASSIGN_RESEARCHER_HOSPITALS_ERROR: 'app/users/FETCH_ASSIGN_RESEARCHER_HOSPITALS_ERROR',

  FETCH_LOAD_USERS: 'app/users/FETCH_LOAD_USERS/',

  FETCH_USERS: 'app/users/FETCH_USER',
  FETCH_USERS_SUCCESS: 'app/users/FETCH_USER_SUCCESS',
  FETCH_USERS_ERROR: 'app/users/FETCH_USER_ERROR',

  FETCH_SAVE_USERS: 'app/users/SAVE_USER',
  FETCH_SAVE_USERS_SUCCESS: 'app/users/SAVE_USER_SUCCESS',
  FETCH_SAVE_USERS_ERROR: 'app/users/SAVE_USER_ERROR',
};

export const fetchSetUserRole = (payload) => ({
  type: usersActionTypes.FETCH_SET_USER_ROLE,
  payload,
});

export const fetchSetUserRoleSuccess = (payload) => ({
  type: usersActionTypes.FETCH_SET_USER_ROLE_SUCCESS,
  payload,
});

export const fetchSetUserRoleError = (payload) => ({
  type: usersActionTypes.FETCH_SET_USER_ROLE_ERROR,
  payload,
});

export const fetchAssignSalesRepSurgeons = (payload) => ({
  type: usersActionTypes.FETCH_ASSIGN_SALES_REP_SURGEONS,
  payload,
});

export const fetchAssignSalesRepSurgeonsSuccess = (payload) => ({
  type: usersActionTypes.FETCH_ASSIGN_SALES_REP_SURGEONS_SUCCESS,
  payload,
});

export const fetchAssignSalesRepSurgeonsError = (payload) => ({
  type: usersActionTypes.FETCH_ASSIGN_SALES_REP_SURGEONS_ERROR,
  payload,
});

export const fetchAssignResearcherHospitals = (payload) => ({
  type: usersActionTypes.FETCH_ASSIGN_RESEARCHER_HOSPITALS,
  payload,
});

export const fetchAssignResearcherHospitalsSuccess = (payload) => ({
  type: usersActionTypes.FETCH_ASSIGN_RESEARCHER_HOSPITALS_SUCCESS,
  payload,
});

export const fetchAssignResearcherHospitalsError = (payload) => ({
  type: usersActionTypes.FETCH_ASSIGN_RESEARCHER_HOSPITALS_ERROR,
  payload,
});

export const fetchUserReset = (payload) => ({
  type: usersActionTypes.FETCH_USER_RESET,
  payload,
});

export const fetchUserResetSuccess = (payload) => ({
  type: usersActionTypes.FETCH_USER_RESET_SUCCESS,
  payload,
});

export const fetchUserResetError = (payload) => ({
  type: usersActionTypes.FETCH_USER_RESET_ERROR,
  payload,
});

export const fetchUserChangeCountry = (payload) => ({
  type: usersActionTypes.FETCH_USER_CHANGE_COUNTRY,
  payload,
});

export const fetchUserChangeCountrySuccess = (payload) => ({
  type: usersActionTypes.FETCH_USER_CHANGE_COUNTRY_SUCCESS,
  payload,
});

export const fetchUserChangeCountryError = (payload) => ({
  type: usersActionTypes.FETCH_USER_CHANGE_COUNTRY_ERROR,
  payload,
});

export const fetchUsers = (payload) => ({
  type: usersActionTypes.FETCH_USERS,
  payload,
});

export const fetchUsersSuccess = (payload) => ({
  type: usersActionTypes.FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersError = (payload) => ({
  type: usersActionTypes.FETCH_USERS_ERROR,
  payload,
});
