import Firebase from '../../../firebase';
import { USER_AUTHORIZATION_COLLECTION, USER_DATA_COLLECTION } from '../../../firebase/collections';
import { cloudFunctionConfig } from '../../../config';

const collection = Firebase.db.collection(USER_AUTHORIZATION_COLLECTION);
const dataCollection = Firebase.db.collection(USER_DATA_COLLECTION);

export const sendUserEmail = (email) => {
  fetch(`${cloudFunctionConfig}/sendEmail/send-user-email`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then(() => console.log('sent'))
    .catch((e) => console.log(e));
};

export const sendEmailToAdmin = (email, firstName, lastName) => {
  fetch(`${cloudFunctionConfig}/sendEmail/send-email`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
    }),
  })
    .then(() => console.log('sent'))
    .catch((e) => console.log(e));
};

export const updateUserRole = async ({ uid, ...restProps }) => {
  return await collection.doc(uid).update({ ...restProps });
};

export const assignSalesRepSurgeons = async ({ uid, ...restProps }) => {
  return await collection.doc(uid).update({ ...restProps });
};

export const assignResearcherHospitals = async ({ uid, ...restProps }) => {
  return await collection.doc(uid).update({ ...restProps });
};

export const requestResetUserData = async (uid) => {
  return await collection.doc(uid).set({ role: null });
};

export const updateProductList = async () => {
  // const products = await Firebase.db
  //   .collection(PRODUCT_COLLECTION)
  //   .get()
  //   .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
  //   .then((response) => response);
  //
  // const newCountries = countries.map((item) => ({
  //   subTitle: '',
  //   description: '',
  //   country: item,
  // }));
  //
  // const fileNames = countries.map((item) => ({
  //   names: [],
  //   country: item,
  // }));
  //
  // const links = countries.map((item) => ({
  //   data: [],
  //   country: item,
  // }));
  //
  // for (let i = 0; i < products.length; i++) {
  //   await Firebase.db
  //     .collection(PRODUCT_COLLECTION)
  //     .doc(products[i].id)
  //     .update({
  //       description: [...products[i].description, ...newCountries],
  //       links: [...products[i].links, ...links],
  //       fileNames: [...products[i].fileNames, ...fileNames],
  //     });
  // }
};

export const requestChangeUserCountry = async ({ id, country }) => {
  return await collection.doc(id).update({ country });
};

export const requestFetchUsers = () => {
  return Promise.all([requestFetchUserData(), requestFetchUserRoles()]);
};

export const requestFetchUserRoles = async () => {
  return await collection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() })));
};

export const requestFetchUserData = async () => {
  return await dataCollection
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() })));
};
