import { modalActionTypes } from '../actions/modalActions';
import { APP_MODALS } from '../../constants';

const defaultModalData = {
  modalName: '',
  title: '',
  submitTitle: '',
  value: null,
  onSubmit: null,
  Component: null,
  reduxActions: {
    start: '',
    resolve: '',
    reject: '',
  },
};

const initModals = (modalNames) => {
  return modalNames.reduce((acc, next) => {
    acc[next] = {
      isVisible: false,
      data: { ...defaultModalData },
    };

    return acc;
  }, {});
};

const initialState = {
  ...initModals(Object.values(APP_MODALS)),
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalActionTypes.SHOW_MODAL: {
      const { modalName, ...restProps } = action.payload;
      const modal = { ...state[modalName] };
      modal.isVisible = true;
      modal.data = { ...modal.data, ...restProps };

      return { ...state, [modalName]: modal };
    }
    case modalActionTypes.HIDE_MODAL: {
      const { modalName } = action.payload;
      const modal = { ...state[modalName] };
      modal.isVisible = false;

      return { ...state, [modalName]: modal };
    }
    default:
      return state;
  }
};

export default modalReducer;
