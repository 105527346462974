import {
  ADD_PROCEDURES,
  RECEIVE_PROCEDURES,
  LOAD_PROCEDURES,
  DELETE_PROCEDURE,
  UPDATE_PROCEDURE_LIST,
  CANCEL_PROCEDURE_UPDATING,
  SWAP_PROCEDURES,
} from '../actions/actionTypes';
import { proceduresActionTypes } from '../sagas/procedures/actions';
import {
  deleteFromListById,
  findModelIndexById,
  sortListByIndex,
  updateModelField,
} from '../../util/functions';

const initialState = {
  list: [],
  loading: false,
  updating: false,
  previousList: [],
};

const proceduresReducer = (state = initialState, action) => {
  switch (action.type) {
    case proceduresActionTypes.FETCH_PROCEDURE:
      return { ...state, loading: true };
    case proceduresActionTypes.FETCH_PROCEDURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: sortListByIndex(action.payload),
      };
    }
    case proceduresActionTypes.FETCH_PROCEDURE_ADD_SUCCESS: {
      return { ...state, list: [action.payload, ...state.list] };
    }
    case proceduresActionTypes.FETCH_PROCEDURE_EDIT_SUCCESS: {
      const { payload } = action;
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.id === payload.id);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], name: payload.name, categoryId: payload.categoryId };

      return { ...state, list: newList };
    }
    case proceduresActionTypes.FETCH_PROCEDURE_DELETE_SUCCESS: {
      return deleteFromListById(state, action.payload);
    }
    case proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE: {
      const { source, destination } = action.payload;
      const newList = [...state.list];
      const temp = { ...newList[source] };
      newList.splice(source, 1);
      newList.splice(destination, 0, temp);

      return { ...state, list: newList, previousList: [...state.list], updating: true };
    }
    case proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE_SUCCESS: {
      return { ...state, updating: false };
    }
    case proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE_ERROR: {
      return { ...state, list: [...state.previousList], updating: false };
    }
    default:
      return { ...state };
  }
};

export default proceduresReducer;
