// Current user actions
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
// Users actions
export const LOAD_USERS = 'LOAD_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const UPDATE_ROLE_TO_ADMIN = 'UPDATE_ROLE_TO_ADMIN';
export const UPDATE_ROLE_TO_SALES_REP = 'UPDATE_ROLE_TO_SALES_REP';
export const UPDATE_ROLE_TO_SURGEON = 'UPDATE_ROLE_TO_SURGEON';
export const UPDATE_ROLE_TO_USER = 'UPDATE_ROLE_TO_USER';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const REMOVE_SALES_REP_ROLE = 'REMOVE_SALES_REP_ROLE';
export const REMOVE_SURGEON_ROLE = 'REMOVE_SURGEON_ROLE';
export const UPDATE_SALES_REP_TOKENS = 'UPDATE_SALES_REP_TOKENS';
export const UPDATE_ASSIGN_SURGEONS = 'UPDATE_ASSIGN_SURGEONS';
// Category actions;
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SWAP_CATEGORIES = 'SWAP_CATEGORIES';
export const UPDATE_CATEGORIES_LIST = 'UPDATE_CATEGORIES_LIST';
export const CANCEL_CATEGORY_UPDATING = 'CANCEL_CATEGORY_UPDATING';
// Products actions
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT_PERMISSION = 'UPDATE_PRODUCT_PERMISSION';
export const UPDATE_PRODUCT_IMAGE = 'UPDATE_PRODUCT_IMAGE';
export const SELECT_PRODUCTS = 'SELECT_PRODUCTS';
export const SWAP_PRODUCTS = 'SWAP_PRODUCTS';
export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST';
export const CANCEL_DELETED_PRODUCT = 'CANCEL_DELETED_PRODUCT';
export const DELETE_PRODUCT_LIST = 'DELETE_PRODUCT_LIST';
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA';
// Procedures actions
export const LOAD_PROCEDURES = 'LOAD_PROCEDURES';
export const RECEIVE_PROCEDURES = 'RECEIVE_PROCEDURES';
export const ADD_PROCEDURES = 'ADD_PROCEDURES';
export const DELETE_PROCEDURE = 'DELETE_PROCEDURE';
export const UPDATE_PROCEDURE_LIST = 'UPDATE_PROCEDURE_LIST';
export const SWAP_PROCEDURES = 'SWAP_PROCEDURES';
export const CANCEL_PROCEDURE_UPDATING = 'CANCEL_PROCEDURE_UPDATING';
// Countries
export const LOAD_COUNTRY_LIST = 'LOAD_COUNTRY_LIST';
export const RECEIVE_COUNTRY_LIST = 'RECEIVE_COUNTRY_LIST';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
// Hospitals
export const LOAD_HOSPITALS = 'LOAD_HOSPITALS';
export const RECEIVE_HOSPITALS = 'RECEIVE_HOSPITALS';
export const ADD_HOSPITALS = 'ADD_HOSPITALS';
export const DELETE_HOSPITAL = 'DELETE_HOSPITAL';
export const EDIT_HOSPITAL = 'EDIT_HOSPITAL';
// Surgeons
export const UPDATE_SURGEON_TOKENS = 'UPDATE_SURGEON_TOKENS';
// News
export const LOAD_NEWS = 'LOAD_NEWS';
export const RECEIVE_NEWS = 'RECEIVE_NEWS';
export const ADD_NEWS = 'ADD_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';
export const EDIT_NEWS = 'EDIT_NEWS';
// Rewards
export const LOAD_REWARDS = 'LOAD_REWARDS';
export const RECEIVE_REWARDS = 'RECEIVE_REWARDS';
export const EDIT_BEER_TOKEN_REWARDS = 'EDIT_BEER_TOKEN_REWARDS';
export const UPDATE_USERS_TOKENS = 'UPDATE_USERS_TOKENS';
