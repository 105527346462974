import {
  ADD_PRODUCT,
  RECEIVE_PRODUCTS,
  LOAD_PRODUCTS,
  DELETE_PRODUCT,
  UPDATE_PRODUCT_PERMISSION,
  UPDATE_PRODUCT_IMAGE,
  SELECT_PRODUCTS,
  SWAP_PRODUCTS,
  UPDATE_PRODUCT_LIST,
  CANCEL_DELETED_PRODUCT,
  DELETE_PRODUCT_LIST,
  UPDATE_PRODUCT_DATA,
} from '../actions/actionTypes';
import { productsActionTypes } from '../sagas/products/actions';
import { findModelIndexById } from '../../util/functions';

const initialState = {
  list: [],
  loading: false,
  selectedProducts: [],
  updating: false,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case productsActionTypes.FETCH_PRODUCT:
      return { ...state, loading: true };
    case productsActionTypes.FETCH_PRODUCT_SUCCESS:
      return { ...state, loading: false, list: action.payload };
    case productsActionTypes.FETCH_PRODUCT_ADD_SUCCESS: {
      return { ...state, list: [...state.list, action.payload] };
    }
    case productsActionTypes.FETCH_PRODUCT_EDIT_SUCCESS: {
      const newList = [...state.list];
      const { payload } = action;

      const index = findModelIndexById(payload.id, newList);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], ...payload };

      return { ...state, list: newList };
    }
    case productsActionTypes.FETCH_PRODUCT_DELETE_SUCCESS: {
      const newList = [...state.list];
      const { payload } = action;

      const index = findModelIndexById(payload, newList);

      if (index === -1) {
        return state;
      }

      newList.splice(index, 1);

      return { ...state, list: newList };
    }
    case productsActionTypes.FETCH_FORBIDDEN_PRODUCT: {
      return { ...state, updating: true };
    }
    case productsActionTypes.FETCH_FORBIDDEN_PRODUCT_SUCCESS: {
      const { id, selected } = action.payload;
      const newList = [...state.list];
      const itemProductIndex = newList.findIndex((item) => item.id === id);
      const index = newList[itemProductIndex].forbidden.findIndex((item) => item === selected);

      if (newList[itemProductIndex].forbidden.includes(selected)) {
        newList[itemProductIndex].forbidden.splice(index, 1);
      } else {
        newList[itemProductIndex].forbidden = [...newList[itemProductIndex].forbidden, selected];
      }
      return { ...state, list: newList, updating: false };
    }
    default:
      return { ...state };
  }
};

export default productsReducer;
