import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxWidth: 460,
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    marginBottom: theme.spacing(2),
  },
  confirm: {
    marginTop: theme.spacing(1),
    alignSelf: 'center',
  },
}));
