import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Container, Label } from '../input/style';

const FormikRadioGroup = ({ field, name, options, label, onChange, ...props }) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <RadioGroup {...field} {...props} name={field.name}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            onChange={field.onChange}
            label={option.label}
            control={<Radio color='primary' checked={field.value === option.value} />}
          />
        ))}
      </RadioGroup>
    </Container>
  );
};

export default FormikRadioGroup;
