export const rewardsActionTypes = {
  FETCH_REWARDS: 'app/rewards/FETCH_REWARDS',
  FETCH_REWARDS_SUCCESS: 'app/rewards/FETCH_REWARDS_SUCCESS',
  FETCH_REWARDS_ERROR: 'app/rewards/FETCH_REWARDS_ERROR',
  FETCH_UPDATE_TOKENS: 'app/rewards/FETCH_UPDATE_TOKENS',
  FETCH_UPDATE_TOKENS_SUCCESS: 'app/rewards/FETCH_UPDATE_TOKENS_SUCCESS',
  FETCH_UPDATE_TOKENS_ERROR: 'app/rewards/FETCH_UPDATE_TOKENS_ERROR',

  FETCH_EDIT_SURGEON_TOKENS_ERROR: 'app/rewards/FETCH_EDIT_SURGEON_TOKENS',
  FETCH_EDIT_SURGEON_TOKENS_SUCCESS: 'app/rewards/FETCH_EDIT_SURGEON_TOKENS_SUCCESS',
  FETCH_EDIT_SURGEON_TOKENS: 'app/rewards/FETCH_EDIT_SURGEON_TOKENS_ERROR',

  FETCH_EDIT_SALES_REPS_TOKENS: 'app/rewards/FETCH_EDIT_SALES_REPS_TOKENS',
  FETCH_EDIT_SALES_REPS_TOKENS_SUCCESS: 'app/rewards/FETCH_EDIT_SALES_REPS_TOKENS_SUCCESS',
  FETCH_EDIT_SALES_REPS_TOKENS_ERROR: 'app/rewards/FETCH_EDIT_SALES_REPS_TOKENS_ERROR',
};

export const fetchRewards = () => ({
  type: rewardsActionTypes.FETCH_REWARDS,
});

export const fetchRewardsSuccess = (payload) => ({
  type: rewardsActionTypes.FETCH_REWARDS_SUCCESS,
  payload,
});

export const fetchRewardsError = (payload) => ({
  type: rewardsActionTypes.FETCH_REWARDS_ERROR,
  payload,
});

export const fetchUpdateTokens = (payload) => ({
  type: rewardsActionTypes.FETCH_UPDATE_TOKENS,
  payload,
});

export const fetchUpdateTokensError = (payload) => ({
  type: rewardsActionTypes.FETCH_UPDATE_TOKENS_ERROR,
  payload,
});

export const fetchUpdateTokensSuccess = (payload) => ({
  type: rewardsActionTypes.FETCH_UPDATE_TOKENS_SUCCESS,
  payload,
});
// edit surgeons tokens
export const fetchEditSurgeonToken = (payload) => ({
  type: rewardsActionTypes.FETCH_EDIT_SURGEON_TOKENS,
  payload,
});

export const fetchEditSurgeonTokenSuccess = (payload) => ({
  type: rewardsActionTypes.FETCH_EDIT_SURGEON_TOKENS_SUCCESS,
  payload,
});

export const fetchEditSurgeonTokenError = (payload) => ({
  type: rewardsActionTypes.FETCH_EDIT_SURGEON_TOKENS_ERROR,
  payload,
});

export const fetchEditSalesRepsToken = (payload) => ({
  type: rewardsActionTypes.FETCH_EDIT_SALES_REPS_TOKENS,
  payload,
});

export const fetchEditSalesRepTokenSuccess = (payload) => ({
  type: rewardsActionTypes.FETCH_EDIT_SALES_REPS_TOKENS_SUCCESS,
  payload,
});

export const fetchEditSalesRepTokenError = (payload) => ({
  type: rewardsActionTypes.FETCH_EDIT_SALES_REPS_TOKENS_ERROR,
  payload,
});
