import React, { memo, useEffect, useMemo, useState } from 'react';

import Modal from '../../modal-wrapper/Modal';
import ProcedureShared from '../../procedure-shared/ProcedureShared';

import { makeStyles } from '@material-ui/core';
import { connect } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  description: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  button: {
    height: 50,
    marginLeft: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
  intervalScore: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  scoreLabel: {
    fontSize: 20,
    fontWeight: 700,
    color: '#01a157',
  },
  score: {
    fontSize: 40,
    fontWeight: 800,
    color: '#01a157',
  },
  textContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const HoosJRModal = ({
  isFollowUp = false,
  open,
  onClose,
  name,
  formik: {
    values,
    setFieldValue,
    values: { preOpData },
  },
}) => {
  const [points, setPoints] = useState([0, 0, 0, 0, 0, 0]);
  const [score, setScore] = useState(100);

  const scores = useMemo(
    () => [
      100.0, 92.34, 85.257, 80.55, 76.776, 73.472, 70.426, 67.516, 64.664, 61.815, 58.93, 55.985,
      52.965, 49.858, 46.652, 43.335, 39.902, 36.363, 32.735, 29.009, 25.103, 20.805, 15.633, 8.104,
      0.0,
    ],
    []
  );

  const changeScore = (index, value) => {
    const newPoints = [...points];
    newPoints[index] = value;
    const sum = newPoints.reduce((sum, value) => sum + value, 0);
    setPoints([...newPoints]);
    setScore(scores[sum]);
  };

  const onSubmit = () => {
    setFieldValue(name, score);
    isFollowUp
      ? setFieldValue('followUp.PSI.hoosJRScore', points)
      : setFieldValue('preOpData.hoosJRScore', points);

    onClose();
  };

  useEffect(() => {
    if (!isFollowUp && preOpData) {
      setPoints(preOpData.hoosJRScore || [0, 0, 0, 0, 0, 0]);
      setScore(preOpData.HOOSJR || 100);
    } else {
      setPoints(values.followUp.PSI.hoosJRScore || [0, 0, 0, 0, 0, 0]);
      setScore(values.followUp.PSI.HOOSJR || 100);
    }
  }, [open]);

  const classes = useStyles();
  return (
    <Modal
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      title={'HOOS-JR'}
      submitTitle={'Complete'}
    >
      <div className={classes.root}>
        <div className={classes.description}>
          Hip dysfunction and Osteoarthritis Outcome Score for Joint Replacement
        </div>
        <div className={classes.textContainer}>
          <div className={classes.title}>Pain</div>
          <span>
            What amount of hip pain have you experienced the last week during the following
            activities?
          </span>
        </div>
        <ProcedureShared
          defaultValue={points[0]}
          onChange={changeScore}
          index={0}
          title='1. Going up or down stairs'
        />
        <ProcedureShared
          defaultValue={points[1]}
          onChange={changeScore}
          index={1}
          title='2. Walking on an uneven surface'
        />
        <div className={classes.textContainer}>
          <div className={classes.title}>Function, daily living</div>
          <span>
            The following questions concern your physical function. By this we mean your ability to
            move around and to look after yourself. For each of the following activities please
            indicate the degree of difficulty you have experienced in the last week due to your
            knee.
          </span>
        </div>
        <ProcedureShared
          defaultValue={points[2]}
          onChange={changeScore}
          index={2}
          title='3. Rising from sitting'
        />
        <ProcedureShared
          defaultValue={points[3]}
          onChange={changeScore}
          index={3}
          title='4. Bending to floor/pick uo an object'
        />
        <ProcedureShared
          defaultValue={points[4]}
          onChange={changeScore}
          index={4}
          title='5. Lying in bed (turning over, maintaining hip position)'
        />
        <ProcedureShared
          defaultValue={points[5]}
          onChange={changeScore}
          index={5}
          title='6. Sitting'
        />
        <div className={classes.intervalScore}>
          <div className={classes.score}>{score}</div>
          <div className={classes.scoreLabel}>Interval Score</div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(memo(HoosJRModal));
