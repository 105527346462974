import Firebase from '../../../firebase';
import { SURGEONS_COLLECTION, USER_AUTHORIZATION_COLLECTION } from '../../../firebase/collections';
import { APP_ROLES } from '../../../constants';

const collection = Firebase.db.collection(SURGEONS_COLLECTION);
const collectionUsers = Firebase.db.collection(USER_AUTHORIZATION_COLLECTION);

export const requestFetchSurgeons = async (user) => {
  const { role } = user;

  switch (role) {
    case APP_ROLES.REGULATORY:
    case APP_ROLES.ADMIN: {
      return await collection
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
        .then((response) => response);
    }
    case APP_ROLES.RESEARCHER:
      const surgeons = await collection
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      .then((response) => response);

      return surgeons.filter((item) => user?.assignedSurgeons?.find((surgeon) => surgeon.id === item.id) || item?.hospitals?.find((hospital) => user?.hospitals?.map((h) => h.id)?.includes(hospital.id)));
    case APP_ROLES.SALES_REP: {
      return await Promise.all(
        user.assignedSurgeons.map((item) => {
          return collection
            .doc(item.id)
            .get()
            .then((docRef) => ({ id: docRef.id, ...docRef.data() }));
        })
      );
    }
    case APP_ROLES.LOGISTIC: {
      return await collection
        .where('country', '==', user.country)
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
        .then((response) => response);
    }
    default:
      return [];
  }
};

export const requestAssignHospitals = async ({ id, ...restProps }) => {
  return await collection.doc(id).update({ ...restProps });
};

export const requestAddSurgeon = async (restProps) => {
  return await collection.add({ ...restProps }).then((docRef) => docRef.id);
};

export const requestEditSurgeon = async ({ id, ...restProps }) => {
  return await collection.doc(id).update({ ...restProps });
};

export const requestDeleteSurgeon = async (id) => {
  return await collection.doc(id).delete();
};

export const requestFindUserBySurgeonId = async (id) => {
  return await collectionUsers
    .where('surgeonId', '==', id)
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() })))
    .then((response) => response[0]);
};
