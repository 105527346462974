import React from 'react';
import { useSelector } from 'react-redux';

const withUser = (Component) => (props) => {
  const user = useSelector((state) => state.user.currentUser);

  return <Component {...props} user={user} />;
};

export default withUser;
