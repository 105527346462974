import {
  ADD_SURGEON,
  LOAD_SURGEONS,
  UPDATE_SURGEON_HOSPITALS,
  RECEIVE_SURGEONS,
  DELETE_SURGEON,
  EDIT_SURGEON,
  UPDATE_SURGEON_TOKENS,
} from '../actions/actionTypes';
import { surgeonsActionTypes } from '../sagas/surgeons/actions';
import { rewardsActionTypes } from '../sagas/rewards/actions';
import { deleteFromListById, findModelIndexById, updateModelField } from '../../util/functions';
import { RESET_STATE } from './index';

const initialState = {
  list: [],
  loading: false,
};

const surgeonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case surgeonsActionTypes.FETCH_SURGEONS_SUCCESS: {
      return { ...state, list: action.payload };
    }
    case rewardsActionTypes.FETCH_EDIT_SURGEON_TOKENS_SUCCESS: {
      const list = [...state.list];
      const { id, newTokens } = action.payload;

      const index = list.findIndex((item) => item.id === id);

      if (index === -1) {
        return state;
      }

      list[index].beerTokens = newTokens;

      return {
        ...state,
        list,
      };
    }
    case surgeonsActionTypes.FETCH_ASSIGN_HOSPITALS_SUCCESS: {
      const { id, hospitals } = action.payload;
      const newList = [...state.list];
      const index = findModelIndexById(id, newList);

      if (index === -1) {
        return state;
      }

      newList[index] = updateModelField(newList[index], { hospitals });

      return updateModelField(state, { list: newList });
    }
    case surgeonsActionTypes.FETCH_EDIT_SURGEON_SUCCESS: {
      const { id, firstName, lastName, title } = action.payload;
      const newList = [...state.list];
      const index = findModelIndexById(id, newList);

      if (index === -1) {
        return state;
      }

      newList[index] = updateModelField(newList[index], { title, firstName, lastName });

      return updateModelField(state, { list: newList });
    }
    case surgeonsActionTypes.FETCH_DELETE_SURGEON_SUCCESS: {
      return deleteFromListById(state, action.payload);
    }
    case surgeonsActionTypes.FETCH_SURGEON_ADD_SUCCESS: {
      return { ...state, list: [action.payload, ...state.list] };
    }
    case UPDATE_SURGEON_TOKENS: {
      const surgeons = action.value;
      const list = [...state.list];

      surgeons.forEach((surgeon) => {
        const index = list.findIndex((item) => item.id === surgeon.id);

        if (index !== -1) {
          list[index].beerTokens = surgeon.beerTokens;
        }
      });
      return { ...state, list };
    }
    case RESET_STATE: {
      return initialState;
    }
    default:
      return { ...state };
  }
};

export default surgeonsReducer;
