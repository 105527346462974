import { call, put, takeEvery } from 'redux-saga/effects';
import {
  countriesActionTypes,
  fetchChangeCountry,
  fetchChangeCountryError,
  fetchChangeCountrySuccess,
  fetchCountriesError,
  fetchCountriesSuccess,
} from './actions';
import { requestFetchCountries } from './requests';

export function* watchFetchCountries() {
  yield takeEvery(countriesActionTypes.FETCH_COUNTRIES, doFetchCountries);
}

export function* watchFetchChangeCountry() {
  yield takeEvery(countriesActionTypes.FETCH_CHANGE_COUNTRY, doFetchChangeCountry);
}

export function* doFetchCountries() {
  try {
    const response = yield call(requestFetchCountries);
    yield put(fetchCountriesSuccess(response));
  } catch (error) {
    fetchCountriesError(error);
  }
}

export function* doFetchChangeCountry(action) {
  const { payload } = action;
  try {
    yield put(fetchChangeCountrySuccess(payload));
  } catch (error) {
    fetchChangeCountryError(error);
  }
}
