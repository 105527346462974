export const newsActionTypes = {
  FETCH_NEWS: 'app/news/FETCH_NEWS',
  FETCH_NEWS_SUCCESS: 'app/news/FETCH_NEWS_SUCCESS',
  FETCH_NEWS_ERROR: 'app/news/FETCH_NEWS_ERROR',
  FETCH_NEWS_ADD: 'app/news/FETCH_NEWS_ADD',
  FETCH_NEWS_ADD_SUCCESS: 'app/news/FETCH_NEWS_ADD_SUCCESS',
  FETCH_NEWS_ADD_ERROR: 'app/news/FETCH_NEWS_ADD_ERROR',
  FETCH_NEWS_EDIT: 'app/news/FETCH_NEWS_EDIT',
  FETCH_NEWS_EDIT_SUCCESS: 'app/news/FETCH_NEWS_EDIT_SUCCESS',
  FETCH_NEWS_EDIT_ERROR: 'app/news/FETCH_NEWS_EDIT_ERROR',
  FETCH_NEWS_DELETE: 'app/news/FETCH_NEWS_DELETE',
  FETCH_NEWS_DELETE_SUCCESS: 'app/news/FETCH_NEWS_DELETE_SUCCESS',
  FETCH_NEWS_DELETE_ERROR: 'app/news/FETCH_NEWS_DELETE_ERROR',
  FETCH_NEWS_UPDATE_SEQUENCE: 'app/procedures/FETCH_NEWS_UPDATE_SEQUENCE',
  FETCH_NEWS_UPDATE_SEQUENCE_SUCCESS: 'app/procedures/FETCH_NEWS_UPDATE_SEQUENCE_SUCCESS',
  FETCH_NEWS_UPDATE_SEQUENCE_ERROR: 'app/procedures/FETCH_NEWS_UPDATE_SEQUENCE_ERROR',
};

export const fetchNews = (payload) => ({
  type: newsActionTypes.FETCH_NEWS,
  payload,
});

export const fetchNewsSuccess = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_SUCCESS,
  payload,
});

export const fetchNewsError = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_ERROR,
  payload,
});

export const fetchNewsAddSuccess = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_ADD_SUCCESS,
  payload,
});

export const fetchNewsAddError = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_ADD_ERROR,
  payload,
});

export const fetchNewsEditSuccess = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_EDIT_SUCCESS,
  payload,
});

export const fetchNewsEditError = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_EDIT_ERROR,
  payload,
});

export const fetchNewsDeleteSuccess = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_DELETE_SUCCESS,
  payload,
});

export const fetchNewsDeleteError = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_DELETE_ERROR,
  payload,
});

export const fetchNewsUpdateSequence = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE,
  payload,
});

export const fetchNewsUpdateSequenceSuccess = () => ({
  type: newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE_SUCCESS,
});

export const fetchNewsUpdateSequenceError = (payload) => ({
  type: newsActionTypes.FETCH_NEWS_UPDATE_SEQUENCE_ERROR,
  payload,
});
