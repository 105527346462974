import routes from './routes';

export const APP_MODALS = {
  ROLE_MODAL: 'ROLE_MODAL',
  CATEGORY_MODAL: 'CATEGORY_MODAL',
  PRODUCT_MODAL: 'PRODUCT_MODAL',
  PROCEDURE_MODAL: 'PROCEDURE_MODAL',
  HOSPITAL_MODAL: 'HOSPITAL_MODAL',
  SURGEON_MODAL: 'SURGEON_MODAL',
  NEWS_MODAL: 'NEWS_MODAL',
  ASSIGN_HOSPITALS_MODAL: 'ASSIGN_HOSPITALS_MODAL',
  RESEARCHER_ASSIGN_HOSPITALS_MODAL: 'RESEARCHER_ASSIGN_HOSPITALS_MODAL',
  ASSIGN_SURGEONS_MODAL: 'ASSIGN_SURGEONS_MODAL',
  DELETE_MODAL: 'DELETE_MODAL',
  PRODUCT_PREVIEW: 'PRODUCT_PREVIEW',
  CASE_FILTER_MODAL: 'CASE_FILTER_MODAL',
  EDIT_USER_TOKENS_MODAL: 'EDIT_USER_TOKENS_MODAL',
  ADD_CASE_USAGE_MODAL: 'ADD_CASE_USAGE_MODAL',
  ADD_CASE_MODAL: 'ADD_CASE_MODAL',
  CHANGE_USER_COUNTRY: 'CHANGE_USER_COUNTRY',
  EDIT_GENERAL_CASE_INFO: 'EDIT_GENERAL_CASE_INFO',
  SUCCESS_SUBMIT_MODAL: 'SUCCESS_SUBMIT_MODAL',
  EMAIL_MODAL: 'EMAIL_MODAL',
};

export const APP_ROLES = {
  USER: null,
  ADMIN: 'ADMIN',
  SURGEON: 'SURGEON',
  SALES_REP: 'SALES_REP',
  RESEARCHER: 'RESEARCHER',
  REGULATORY: 'REGULATORY',
  LOGISTIC: 'LOGISTIC',
};

export const CASE_STATUSES = {
  OPEN: 'OPEN',
  OVERDUE: 'OVERDUE',
  PENDING_PO: 'PENDING_PO',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const AVAILABLE_LANGUAGES = ['en', 'fr'];

export const APP_COUNTRIES = {
  AUSTRALIA: 'Australia',
  US: 'United States',
  FRANCE: 'France',
  IRELAND: 'Ireland',
};

export const CASE_FILTER_TYPE = {
  HISTORY: 'HISTORY',
  CASES: 'CASES',
  PMCF: 'PMCF',
};

export const PRE_OP_DATA_TYPES = {
  TKR: 'TKR',
  THR: 'THR',
  IBF: 'IBF',
  HTO: 'HTO'
};

export const BREAKPOINTS = {
  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,
};

export const COLORS = [
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
];

export const ASSESSMENT_TYPES = {
  HOOSJR: 'HOOSJR',
  KOOSJR: 'KOOSJR',
  AKSS: 'pre_op_akss',
  PREOPOHS: 'pre_op_ohs',
  PREOPOKS: 'pre_op_oks',
  OKS: 'pre_op_oks',
};

export const OPERATED_LEVEL = {
  CERVICAL: 'cervical',
  THORACOLUMBAR: 'thoracolumbar',
};

export const MEASUREMENT_TYPE = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
};

export const EMAIL_TYPE = {
  UPDATE_CASE: 'UPDATE_CASE',
  NEW_CASE: 'NEW_CASE',
};

export { routes };
