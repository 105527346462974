import React from 'react';

import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '300px',
    position: 'fixed',
    backgroundColor: '#00a157',
    zIndex: '0',
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  if (pathname !== '/') {
    return null;
  }

  return <div className={classes.root} />;
};

export default DashboardLayout;
