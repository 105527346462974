import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  fetchProcedureAddError,
  fetchProcedureAddSuccess,
  fetchProcedureDeleteError,
  fetchProcedureDeleteSuccess,
  fetchProcedureEditError,
  fetchProcedureEditSuccess,
  fetchProcedureError,
  fetchProcedureSuccess,
  fetchProcedureUpdateSequenceError,
  fetchProcedureUpdateSequenceSuccess,
  proceduresActionTypes,
} from './actions';
import {
  requestAddProcedure,
  requestDeleteProcedure,
  requestEditProcedure,
  requestFetchProcedure,
  requestUpdateProcedureSequence,
} from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchProcedure() {
  yield takeEvery(proceduresActionTypes.FETCH_PROCEDURE, doFetchProcedure);
}

export function* watchFetchProcedureAdd() {
  yield takeEvery(proceduresActionTypes.FETCH_PROCEDURE_ADD, doFetchProcedureAdd);
}

export function* watchFetchProcedureEdit() {
  yield takeEvery(proceduresActionTypes.FETCH_PROCEDURE_EDIT, doFetchProcedureEdit);
}

export function* watchFetchProcedureDelete() {
  yield takeEvery(proceduresActionTypes.FETCH_PROCEDURE_DELETE, doFetchProcedureDelete);
}

export function* watchFetchProcedureUpdateSequence() {
  yield takeEvery(
    proceduresActionTypes.FETCH_PROCEDURE_UPDATE_SEQUENCE,
    doFetchProcedureUpdateSequence
  );
}

function* doFetchProcedure() {
  try {
    const response = yield call(requestFetchProcedure);
    yield put(fetchProcedureSuccess(response));
  } catch (error) {
    yield put(fetchProcedureError());
  }
}

function* doFetchProcedureAdd(action) {
  try {
    const { payload } = action;
    const {
      procedures: { list },
    } = yield select();
    const response = yield call(requestAddProcedure, {
      ...payload,
      index: list.length > 0 ? list[list.length - 1].index + 1 : 1,
    });

    yield put(fetchProcedureAddSuccess({ id: response, ...payload }));
    yield put(showAlert({ message: 'Procedure was successfully added' }));
  } catch (error) {
    yield put(fetchProcedureAddError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchProcedureEdit(action) {
  try {
    const { payload } = action;
    yield call(requestEditProcedure, { ...payload });

    yield put(fetchProcedureEditSuccess({ ...payload }));
    yield put(showAlert({ message: 'Procedure was successfully edited' }));
  } catch (error) {
    yield put(fetchProcedureEditError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchProcedureDelete(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield call(requestDeleteProcedure, id);

    yield put(fetchProcedureDeleteSuccess(id));
    yield put(showAlert({ message: 'Procedure was successfully deleted', type: 'success' }));
  } catch (error) {
    yield put(fetchProcedureDeleteError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchProcedureUpdateSequence(action) {
  try {
    const {
      procedures: { list },
    } = yield select();
    yield call(requestUpdateProcedureSequence, list);
    yield put(fetchProcedureUpdateSequenceSuccess());
  } catch (error) {
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
    yield put(fetchProcedureUpdateSequenceError(error));
  }
}
