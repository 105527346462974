import React, { useState, memo } from 'react';

import FormikFastInput from '../../formik/FormikFastInput';
import Button from '../../button';
import HoosJRModal from './HoosJRModal';

import { makeStyles } from '@material-ui/core';
import { connect } from 'formik';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  button: {
    height: 50,
    marginLeft: theme.spacing(1),
  },
  buttonWithError: {
    position: 'relative',
    bottom: 24,
  },
}));

const HoosJRActions = ({ isFollowUp, name, formik: { touched, errors } }) => {
  const classes = useStyles();
  const [activeModal, setActiveModal] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => {
    setActiveModal((prevValue) => !prevValue);
  };

  const isErrorShowed = touched?.preOpData?.HOOSJR && errors?.preOpData?.HOOSJR;

  return (
    <div className={classes.root}>
      <FormikFastInput type='number' label={t('THR.HOOSJR')} name={name} />
      <Button
        onClick={toggleModal}
        className={clsx(classes.button, isErrorShowed && classes.buttonWithError)}
      >
        Survey
      </Button>
      <HoosJRModal isFollowUp={isFollowUp} name={name} open={activeModal} onClose={toggleModal} />
    </div>
  );
};

export default connect(HoosJRActions);
