import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Spinner = ({ ...restProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color='primary' {...restProps} />
    </div>
  );
};

export default Spinner;
