export const modalActionTypes = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const showModal = (payload) => ({
  type: modalActionTypes.SHOW_MODAL,
  payload,
});

export const hideModal = (payload) => ({
  type: modalActionTypes.HIDE_MODAL,
  payload,
});
