const mergeArrayObjectsByField = (field, arrays) => {
  let result = arrays[0];
  for (let i = 1; i < arrays.length; i++) {
    result = result.map((item1) => ({
      ...item1,
      ...arrays[i].find((item2) => item2[field] === item1[field]),
    }));
  }

  return result;
};

export { mergeArrayObjectsByField };
