import { RECEIVE_CATEGORIES, LOAD_CATEGORIES } from '../actions/actionTypes';
import { categoryActionTypes } from '../sagas/categories/actions';
import { findModelIndexById } from '../../util/functions';

const initialState = {
  list: [],
  loading: false,
  selectedCategories: [],
  previousList: [],
  updating: false,
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryActionTypes.FETCH_CATEGORY:
      return { ...state, loading: true };
    case categoryActionTypes.FETCH_CATEGORY_SUCCESS: {
      const sortedCategories = action.payload.sort((a, b) => a.index - b.index);

      return {
        ...state,
        loading: false,
        list: sortedCategories,
        selectedCategories: sortedCategories,
      };
    }
    case categoryActionTypes.FETCH_CATEGORY_ADD_SUCCESS: {
      return { ...state, list: [action.payload, ...state.list] };
    }
    case categoryActionTypes.FETCH_CATEGORY_EDIT_SUCCESS: {
      const { payload } = action;
      const newList = [...state.list];
      const index = newList.findIndex((item) => item.id === payload.id);

      if (index === -1) {
        return state;
      }

      newList[index] = { ...newList[index], name: payload.name };

      return { ...state, list: newList };
    }
    case categoryActionTypes.FETCH_CATEGORY_DELETE_SUCCESS: {
      const newList = [...state.list];
      const index = findModelIndexById(action.payload, newList);

      if (index === -1) {
        return state;
      }

      newList.splice(index, 1);

      return { ...state, list: newList };
    }
    case categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE: {
      const { source, destination } = action.payload;
      const newList = [...state.list];
      const temp = { ...newList[source] };
      newList.splice(source, 1);
      newList.splice(destination, 0, temp);

      return { ...state, list: newList, previousList: [...state.list], updating: true };
    }
    case categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE_SUCCESS: {
      return { ...state, updating: false };
    }
    case categoryActionTypes.FETCH_CATEGORY_UPDATE_SEQUENCE_ERROR: {
      return { ...state, list: [...state.previousList], updating: false };
    }
    default:
      return { ...state };
  }
};

export default categoriesReducer;
