import React, { memo, Suspense, useEffect } from 'react';

import AppHeader from '../components/app-header/AppHeader';
import AppSideBar from '../components/sidebar';
import { Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BuildNumber from '../components/shared/BuildNumber';
import DashboardLayout from '../pages/home/components/DashboardLayout';
import useAsyncRedux from '../hooks/useAsyncRedux';
import { fetchSurgeons } from '../store/sagas/surgeons/actions';
import GlobalModals from './GlobalModals';
import ErrorBoundary from '../components/error-boundary';
import { fetchProcedure } from '../store/sagas/procedures/actions';
import { fetchHospital } from '../store/sagas/hospitals/actions';
import { fetchCountries } from '../store/sagas/countries/actions';
import { Pages } from './Pages';
import PageResolver from './PageResolver';
import Spinner from '../components/loaders/spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '0',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  content: {
    overflowX: 'auto',
    zIndex: 1,
    flexGrow: 1,
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    position: 'relative',
    padding: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 32,
    [theme.breakpoints.down(600)]: {
      paddingTop: 0,
      padding: theme.spacing(2),
    },
  },
  contentPage: {
    [theme.breakpoints.up('md')]: {
      borderRadius: '8 !important',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 !important',
    },
    minHeight: '0',
  },
}));

const PrivateRoutes = () => {
  const classes = useStyles();

  useAsyncRedux([fetchSurgeons, fetchProcedure, fetchHospital, fetchCountries]);

  return (
    <div className={classes.root}>
      <AppSideBar />
      <main className={classes.content}>
        <AppHeader />
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {Pages.map((item, index) => (
                <PageResolver key={index} {...item} />
              ))}
            </Switch>
          </Suspense>
          <GlobalModals />
        </ErrorBoundary>
        <BuildNumber />
      </main>
      <DashboardLayout />
    </div>
  );
};

export default memo(PrivateRoutes);
