import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useAsyncRedux = (actions, canceled = false) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!canceled) {
      if (Array.isArray(actions)) {
        actions.forEach((action) => dispatch(action()));
      } else {
        dispatch(actions());
      }
    }
  }, [dispatch]);

  return null;
};

export default useAsyncRedux;
