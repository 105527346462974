import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00a157',
      light: '#00a157',
      dark: '#00a157',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    secondary: {
      main: '#b1b1b1',
      light: '#f2f2f2',
      dark: '#333333',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    dark: {
      main: '#333333',
      light: '#333333',
      dark: '#333333',
      contrastText: '#333333',
      danger: '#333333',
    },
    warning: {
      main: '#EDB248',
    },
    red: {
      main: '#d32f2f',
    },
  },
  typography: {
    fontFamily: `"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiFormControlRoot: {
      root: {
        '&focus': {
          borderColor: 'red',
        },
      },
    },
    MuiInputBaseRoot: {
      root: {
        '&:focus': {
          border: '0px',
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px !important',
        },
        '&:hover': {
          border: 0,
        },
      },
    },
  },
});

export default theme;
