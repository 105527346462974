import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  fetchForbiddenProduct,
  fetchForbiddenProductError,
  fetchForbiddenProductSuccess,
  fetchProductAddError,
  fetchProductAddSuccess,
  fetchProductDeleteError,
  fetchProductDeleteSuccess,
  fetchProductEditError,
  fetchProductEditSuccess,
  fetchProductError,
  fetchProductSuccess,
  productsActionTypes,
} from './actions';
import {
  requestAddProduct,
  requestDeleteFile,
  requestDeleteFileGroup,
  requestDeleteProduct,
  requestEditProduct,
  requestFetchProduct,
  requestForbiddenProduct,
  requestUploadFile,
  requestUploadFileGroup,
} from './requests';
import { showAlert } from '../alerts/actions';

export function* watchFetchProduct() {
  yield takeEvery(productsActionTypes.FETCH_PRODUCT, doFetchProduct);
}

export function* watchFetchProductAdd() {
  yield takeEvery(productsActionTypes.FETCH_PRODUCT_ADD, doFetchProductAdd);
}

export function* watchFetchProductEdit() {
  yield takeEvery(productsActionTypes.FETCH_PRODUCT_EDIT, doFetchProductEdit);
}

export function* watchFetchProductDelete() {
  yield takeEvery(productsActionTypes.FETCH_PRODUCT_DELETE, doFetchProductDelete);
}

export function* watchFetchForbiddenProduct() {
  yield takeEvery(productsActionTypes.FETCH_FORBIDDEN_PRODUCT, doFetchForbiddenProduct);
}

function* doFetchProduct() {
  try {
    const response = yield call(requestFetchProduct);
    yield put(fetchProductSuccess(response));
  } catch (error) {
    yield put(fetchProductError());
  }
}
function* doFetchProductAdd(action) {
  try {
    const state = yield select();
    const {
      products: { list },
    } = state;
    const { payload } = action;
    const { name, normalText, boldText, description, links, logo, background, parent, files } =
      payload;

    const productData = {
      name,
      normalText,
      boldText,
      parent,
      description,
      links,
      logoName: logo.name,
      backgroundName: background.name,
      fileNames: files.map((item) => ({
        country: item.country,
        names: item.files.map((item) => item.name),
      })),
      forbidden: [],
      index: list.length + 1,
    };

    const productId = yield call(requestAddProduct, productData);

    yield call(requestUploadFile, { path: `Product_images/${productId}_${logo.name}`, file: logo });
    yield call(requestUploadFile, {
      path: `Product_images/${productId}_${background.name}`,
      file: background,
    });
    yield call(requestUploadFileGroup, { id: productId, files });
    yield put(fetchProductAddSuccess({ id: productId, ...productData }));
    yield put(showAlert({ message: 'Product was successfully added' }));
  } catch (error) {
    yield put(fetchProductAddError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchProductEdit(action) {
  try {
    const { payload } = action;
    const {
      name,
      normalText,
      boldText,
      description,
      links,
      logo,
      background,
      files,
      removedFiles,
      oldProduct,
    } = payload;

    const newProps = {};

    if (logo) {
      newProps['logoName'] = logo.name;
    }

    if (background) {
      newProps['backgroundName'] = background.name;
    }

    const oldFileNames = [...oldProduct.fileNames].map((item) => {
      if (removedFiles.hasOwnProperty(item.country)) {
        return {
          country: item.country,
          names: item.names.filter((name) => !removedFiles[item.country].includes(name)),
        };
      }

      return item;
    });

    const productId = oldProduct.id;

    const productData = {
      id: productId,
      name,
      normalText,
      boldText,
      description,
      links,
      fileNames: files.map((item) => {
        const oldNames = oldFileNames.find((_item) => _item.country === item.country);

        return {
          country: item.country,
          names: [...item.files.map((item) => item.name), ...(oldNames ? oldNames.names : [])],
        };
      }),
      ...newProps,
    };

    yield call(requestEditProduct, productData);

    if (logo) {
      yield call(requestDeleteFile, { path: `Product_images/${productId}_${oldProduct.logoName}` });
      yield call(requestUploadFile, {
        path: `Product_images/${productId}_${logo.name}`,
        file: logo,
      });
    }

    if (background) {
      yield call(requestDeleteFile, {
        path: `Product_images/${productId}_${oldProduct.backgroundName}`,
      });
      yield call(requestUploadFile, {
        path: `Product_images/${productId}_${background.name}`,
        file: background,
      });
    }

    yield call(requestDeleteFileGroup, { id: productId, removedFiles });

    yield call(requestUploadFileGroup, { id: productId, files });
    yield put(fetchProductEditSuccess(productData));
    yield put(showAlert({ message: 'Product was successfully edited' }));
  } catch (error) {
    yield put(fetchProductEditError(error));
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchProductDelete(action) {
  try {
    const {
      payload: { product },
    } = action;
    const { id, logoName, backgroundName, fileNames } = product;

    const removedFiles = {};

    fileNames.forEach((item) => {
      if (item.names.length > 0) {
        removedFiles[item.country] = item.names;
      }
    });

    yield call(requestDeleteProduct, id);

    if (logoName) {
      yield call(requestDeleteFile, {
        path: `Product_images/${id}_${logoName}`,
      });
    }

    if (backgroundName) {
      yield call(requestDeleteFile, {
        path: `Product_images/${id}_${backgroundName}`,
      });
    }

    yield call(requestDeleteFileGroup, { id, removedFiles });
    yield put(fetchProductDeleteSuccess(id));
    yield put(showAlert({ message: 'Product was successfully deleted' }));
  } catch (error) {
    fetchProductDeleteError(error);
    yield put(showAlert({ message: 'Something went wrong', type: 'error' }));
  }
}

function* doFetchForbiddenProduct(action) {
  try {
    const {
      payload: { product, selected },
    } = action;
    const { id } = product;

    yield call(requestForbiddenProduct, { product, selected, id });
    yield put(fetchForbiddenProductSuccess({ selected, id }));
  } catch (error) {
    fetchForbiddenProductError(error);
  }
}
