export const productsActionTypes = {
  FETCH_PRODUCT: 'app/products/FETCH_PRODUCT',
  FETCH_PRODUCT_SUCCESS: 'app/products/FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_ERROR: 'app/products/FETCH_PRODUCT_ERROR',

  FETCH_PRODUCT_ADD: 'app/products/FETCH_PRODUCT_ADD',
  FETCH_PRODUCT_ADD_SUCCESS: 'app/products/FETCH_PRODUCT_ADD_SUCCESS',
  FETCH_PRODUCT_ADD_ERROR: 'app/products/FETCH_PRODUCT_ADD_ERROR',
  FETCH_PRODUCT_EDIT: 'app/products/FETCH_PRODUCT_EDIT',
  FETCH_PRODUCT_EDIT_SUCCESS: 'app/products/FETCH_PRODUCT_EDIT_SUCCESS',
  FETCH_PRODUCT_EDIT_ERROR: 'app/products/FETCH_PRODUCT_EDIT_ERROR',
  FETCH_PRODUCT_DELETE: 'app/products/FETCH_PRODUCT_DELETE',
  FETCH_PRODUCT_DELETE_SUCCESS: 'app/products/FETCH_PRODUCT_DELETE_SUCCESS',
  FETCH_PRODUCT_DELETE_ERROR: 'app/products/FETCH_PRODUCT_DELETE_ERROR',
  FETCH_FORBIDDEN_PRODUCT: 'app/products/FETCH_FORBIDDEN_PRODUCT',
  FETCH_FORBIDDEN_PRODUCT_SUCCESS: 'app/products/FETCH_FORBIDDEN_SUCCESS',
  FETCH_FORBIDDEN_PRODUCT_ERROR: 'app/products/FETCH_FORBIDDEN_ERROR',
};

export const fetchProductAddSuccess = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_ADD_SUCCESS,
  payload,
});

export const fetchProductAddError = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_ADD_ERROR,
  payload,
});

export const fetchProductEditSuccess = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_EDIT_SUCCESS,
  payload,
});

export const fetchProductEditError = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_EDIT_ERROR,
  payload,
});

export const fetchProductDelete = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_DELETE,
  payload,
});

export const fetchProductDeleteSuccess = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_DELETE_SUCCESS,
  payload,
});

export const fetchProductDeleteError = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_DELETE_ERROR,
  payload,
});

export const fetchForbiddenProduct = (payload) => ({
  type: productsActionTypes.FETCH_FORBIDDEN_PRODUCT,
  payload,
});

export const fetchForbiddenProductSuccess = (payload) => ({
  type: productsActionTypes.FETCH_FORBIDDEN_PRODUCT_SUCCESS,
  payload,
});

export const fetchForbiddenProductError = (payload) => ({
  type: productsActionTypes.FETCH_FORBIDDEN_PRODUCT_ERROR,
  payload,
});

export const fetchProduct = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT,
  payload,
});

export const fetchProductSuccess = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_SUCCESS,
  payload,
});

export const fetchProductError = (payload) => ({
  type: productsActionTypes.FETCH_PRODUCT_ERROR,
  payload,
});
