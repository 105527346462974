import { STORAGE_PREFIX } from './configuration';

export const changeLanguage = (newLanguage) => {
  localStorage.setItem(`${STORAGE_PREFIX}:language`, newLanguage);

  return newLanguage;
};

export const getCachedLanguage = () => {
  return localStorage.getItem(`${STORAGE_PREFIX}:language`);
};
