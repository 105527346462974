import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Sidebar from './Sidebar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../store/actions/sidebarActions';

const drawerWidth = 300;
const drawerHeight = '100%';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      height: drawerHeight,
      flexShrink: 0,
    },
    [theme.breakpoints.down(1050)]: {
      width: 90,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down(1050)]: {
      width: 90,
    },
  },
}));

const AppSideBar = () => {
  const { mobileOpen } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleToggleSideBar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      <Hidden xsDown>
        <nav className={classes.drawer}></nav>
      </Hidden>
      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleToggleSideBar}
          className='drawer-mobile'
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Sidebar />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='css'>
        <Drawer
          elevation={2}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          <Sidebar />
        </Drawer>
      </Hidden>
    </>
  );
};

export default AppSideBar;
