export const casesActionTypes = {
  FETCH_CASES: 'app/cases/FETCH_CASES',
  FETCH_CASES_SUCCESS: 'app/cases/FETCH_CASES_SUCCESS',
  FETCH_CASES_ERROR: 'app/cases/FETCH_CASES_ERROR',
  SET_CASES_FILTER: 'app/cases/SET_CASES_FILTER',
  SET_CASES_FILTER_SUCCESS: 'app/cases/SET_CASES_FILTER_SUCCESS',
  FETCH_ADD_CASE: 'app/cases/FETCH_ADD_CASE',
  FETCH_ADD_CASE_SUCCESS: 'app/cases/FETCH_ADD_CASE_SUCCESS',
  FETCH_ADD_CASE_ERROR: 'app/cases/FETCH_ADD_CASE_ERROR',
  FETCH_DELETE_CASE: 'app/cases/FETCH_DELETE_CASE',
  FETCH_DELETE_CASE_SUCCESS: 'app/cases/FETCH_DELETE_CASE_SUCCESS',
  FETCH_DELETE_CASE_ERROR: 'app/cases/FETCH_DELETE_CASE_ERROR',
  FETCH_EDIT_USER_CASE: 'app/cases/FETCH_EDIT_USER_CASE',
  FETCH_EDIT_USER_CASE_SUCCESS: 'app/cases/FETCH_EDIT_USER_CASE_SUCCESS',
  FETCH_EDIT_USER_CASE_ERROR: 'app/cases/FETCH_EDIT_USER_CASE_ERROR',
  DELETE_CASE: 'app/cases/DELETE_CASE',
};

export const fetchCases = () => ({
  type: casesActionTypes.FETCH_CASES,
});

export const fetchCasesSuccess = (payload) => ({
  type: casesActionTypes.FETCH_CASES_SUCCESS,
  payload,
});

export const fetchCasesError = (payload) => ({
  type: casesActionTypes.FETCH_CASES_ERROR,
  payload,
});

export const setCasesFilter = (payload, hasDelay = true) => ({
  type: casesActionTypes.SET_CASES_FILTER,
  payload,
  hasDelay,
});

export const setCasesFilterSuccess = (payload) => ({
  type: casesActionTypes.SET_CASES_FILTER_SUCCESS,
  payload,
});

export const fetchAddCase = () => ({
  type: casesActionTypes.FETCH_ADD_CASE,
});

export const fetchAddCaseSuccess = (payload) => ({
  type: casesActionTypes.FETCH_ADD_CASE_SUCCESS,
  payload,
});

export const fetchAddCaseError = (payload) => ({
  type: casesActionTypes.FETCH_ADD_CASE_ERROR,
  payload,
});

export const fetchDeleteCase = (payload) => ({
  type: casesActionTypes.FETCH_DELETE_CASE,
  payload,
});

export const fetchDeleteCaseSuccess = (payload) => ({
  type: casesActionTypes.FETCH_DELETE_CASE_SUCCESS,
  payload,
});

export const fetchDeleteCaseError = (payload) => ({
  type: casesActionTypes.FETCH_DELETE_CASE_ERROR,
  payload,
});

export const fetchEditUserCases = (payload) => ({
  type: casesActionTypes.FETCH_EDIT_USER_CASE,
  payload,
});

export const fetchEditUserCasesSuccess = (payload) => ({
  type: casesActionTypes.FETCH_EDIT_USER_CASE_SUCCESS,
  payload,
});

export const fetchEditUserCasesError = (payload) => ({
  type: casesActionTypes.FETCH_EDIT_USER_CASE_ERROR,
  payload,
});

export const deleteCase = (payload) => ({
  type: casesActionTypes.DELETE_CASE,
  payload,
});
